import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import ChangePasswordComponent from "../../components/Global/Settings/ChangePassword";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Divider as MuiDivider,
  Grid,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);

function ChangePassword() {
  return (
    <React.Fragment>
      <Helmet title="ChangePassword" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Change Password
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <NavLink to="/private">Spaces</NavLink>
            <MuiLink component="span" color="text.secondary">
              Settings
            </MuiLink>
            <Typography>Change password</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/private"
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ChangePasswordComponent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ChangePassword;
