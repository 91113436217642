import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { NavLink, Link } from "react-router-dom";
import dayjs from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { axiosInstance } from "../../../utils/axios";
import { DataGrid } from "@mui/x-data-grid";
import { alpha } from "@mui/material/styles";
import SearchBar from "../../components/SearchBar";

import {
  Box,
  Grid,
  CardContent,
  Card as MuiCard,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  TextField as MuiTextField,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Checkbox,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  Select,
  IconButton,
  TablePagination,
  Toolbar,
  Tooltip,
  Paper as MuiPaper,
  Snackbar,
  Alert,
  Button,
  Typography,
} from "@mui/material";

import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Remove,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from "@mui/icons-material";

import { spacing } from "@mui/system";

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 50vh;
  height: 100%;
`;

const ChatSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Availability Card Component for Card View
const AvailabilityCard = ({
  availability,
  isSelected,
  onSelect,
  serialNumber,
}) => {
  return (
    <Paper>
      <Card>
        <div style={{ position: "relative" }}>
          <div
            style={{
              backgroundColor: "#f0f0f0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "140px",
            }}
          >
            <Typography variant="h2" color="textSecondary">
              {availability.name.charAt(0).toUpperCase()}
            </Typography>
          </div>
          <Checkbox
            checked={isSelected}
            onChange={(e) => onSelect(e, availability.id)}
            style={{ position: "absolute", top: "8px", left: "8px" }}
          />
        </div>
        <CardContent>
          <Box>
            <Typography variant="h5" gutterBottom>
              {availability.name}
            </Typography>
          </Box>
          <Typography mb={2} color="textSecondary" component="p">
            S.No: {serialNumber}
            <br />
            Timezone: {availability.timezone}
            <br />
            Days: {availability.days.join(", ")}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Time Slots: {availability.timeSlots.length} configured
            <br />
            Overrides: {availability.overrides.length} scheduled
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
};

// In your EventEntryForm component, update the MyTable component to handle existing time slots

const MyTable = ({ label, isAvailable, setIsAvailable, dayTimeSlots = [] }) => {
  const [checked, setChecked] = useState(isAvailable);
  // Initialize slots with the existing time slots for this day, or a default empty slot
  const [slots, setSlots] = useState(
    dayTimeSlots.length > 0 ? dayTimeSlots : [{ timeStart: "", timeEnd: "" }]
  );

  const handleSwitchChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (isChecked) {
      // If the checkbox is checked, pass the slots array to the parent
      setIsAvailable(
        slots.filter((slot) => slot.timeStart && slot.timeEnd),
        true
      );
    } else {
      // If the checkbox is unchecked, pass an empty array to the parent
      setIsAvailable([], false);
    }
  };

  const handleSlotChange = (index, field, value) => {
    const newSlots = [...slots];
    newSlots[index][field] = value;
    setSlots(newSlots);
    if (checked) {
      setIsAvailable(
        newSlots.filter((slot) => slot.timeStart && slot.timeEnd),
        true
      );
    }
  };

  const addSlot = () => {
    setSlots([...slots, { timeStart: "", timeEnd: "" }]);
  };

  const removeSlot = (index) => {
    const newSlots = slots.filter((_, i) => i !== index);
    setSlots(newSlots);
    if (checked) {
      setIsAvailable(
        newSlots.filter((slot) => slot.timeStart && slot.timeEnd),
        true
      );
    }
  };

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 1 }}>
      <Grid item md={2}>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleSwitchChange} />}
          label={label}
        />
      </Grid>
      {checked &&
        slots.map((slot, index) => (
          <React.Fragment key={index}>
            <Grid item md={4}>
              <Select
                value={slot.timeStart}
                onChange={(e) =>
                  handleSlotChange(index, "timeStart", e.target.value)
                }
                fullWidth
                size="small"
                sx={{
                  height: "40px",
                  "& .MuiSelect-select": {
                    padding: "8px 14px",
                  },
                }}
              >
                <MenuItem value="09:00 am">09:00 am</MenuItem>
                <MenuItem value="10:00 am">10:00 am</MenuItem>
                <MenuItem value="11:00 am">11:00 am</MenuItem>
                <MenuItem value="12:00 pm">12:00 pm</MenuItem>
                <MenuItem value="01:00 pm">01:00 pm</MenuItem>
                <MenuItem value="02:00 pm">02:00 pm</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Grid>
            <Grid item md={1} style={{ textAlign: "center" }}>
              <IconButton
                onClick={() => removeSlot(index)}
                size="small"
                sx={{ padding: "4px" }}
              >
                <Remove fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item md={4}>
              <Select
                value={slot.timeEnd}
                onChange={(e) =>
                  handleSlotChange(index, "timeEnd", e.target.value)
                }
                fullWidth
                size="small"
                sx={{
                  height: "40px",
                  "& .MuiSelect-select": {
                    padding: "8px 14px",
                  },
                }}
              >
                <MenuItem value="05:00 pm">05:00 pm</MenuItem>
                <MenuItem value="06:00 pm">06:00 pm</MenuItem>
                <MenuItem value="07:00 pm">07:00 pm</MenuItem>
                <MenuItem value="08:00 pm">08:00 pm</MenuItem>
                <MenuItem value="09:00 pm">09:00 pm</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Grid>
            {index === slots.length - 1 && (
              <Grid item md={1} style={{ textAlign: "center" }}>
                <IconButton
                  onClick={addSlot}
                  size="small"
                  color="primary"
                  sx={{ padding: "4px" }}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              </Grid>
            )}
          </React.Fragment>
        ))}
    </Grid>
  );
};

const MonthView = ({ onAddOverride }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [timeSlots, setTimeSlots] = useState([{ timeStart: "", timeEnd: "" }]);
  const [isUnavailable, setIsUnavailable] = useState(false);

  const times = [
    "12:00 am",
    "12:15 am",
    "12:30 am",
    "12:45 am",
    "01:00 am",
    "01:15 am",
    "01:30 am",
    "01:45 am",
    "02:00 am",
    "02:15 am",
    "02:30 am",
    "02:45 am",
    "03:00 am",
    "03:15 am",
    "03:30 am",
    "03:45 am",
    "04:00 am",
    "04:15 am",
    "04:30 am",
    "04:45 am",
    "05:00 am",
    "05:15 am",
    "05:30 am",
    "05:45 am",
    "06:00 am",
    "06:15 am",
    "06:30 am",
    "06:45 am",
    "07:00 am",
    "07:15 am",
    "07:30 am",
    "07:45 am",
    "08:00 am",
    "08:15 am",
    "08:30 am",
    "08:45 am",
    "09:00 am",
    "09:15 am",
    "09:30 am",
    "09:45 am",
    "10:00 am",
    "10:15 am",
    "10:30 am",
    "10:45 am",
    "11:00 am",
    "11:15 am",
    "11:30 am",
    "11:45 am",
    "12:00 pm",
    "12:15 pm",
    "12:30 pm",
    "12:45 pm",
    "01:00 pm",
    "01:15 pm",
    "01:30 pm",
    "01:45 pm",
    "02:00 pm",
    "02:15 pm",
    "02:30 pm",
    "02:45 pm",
    "03:00 pm",
    "03:15 pm",
    "03:30 pm",
    "03:45 pm",
    "04:00 pm",
    "04:15 pm",
    "04:30 pm",
    "04:45 pm",
    "05:00 pm",
    "05:15 pm",
    "05:30 pm",
    "05:45 pm",
    "06:00 pm",
    "06:15 pm",
    "06:30 pm",
    "06:45 pm",
    "07:00 pm",
    "07:15 pm",
    "07:30 pm",
    "07:45 pm",
    "08:00 pm",
    "08:15 pm",
    "08:30 pm",
    "08:45 pm",
    "09:00 pm",
    "09:15 pm",
    "09:30 pm",
    "09:45 pm",
    "10:00 pm",
    "10:15 pm",
    "10:30 pm",
    "10:45 pm",
    "11:00 pm",
    "11:15 pm",
    "11:30 pm",
    "11:45 pm",
  ];

  const addTimeSlot = () => {
    setTimeSlots([...timeSlots, { timeStart: "", timeEnd: "" }]);
  };

  const removeTimeSlot = (index) => {
    const updatedTimeSlots = [...timeSlots];
    updatedTimeSlots.splice(index, 1);
    setTimeSlots(updatedTimeSlots);
  };

  const handleAddOverride = () => {
    if (selectedDate && (timeSlots.length > 0 || isUnavailable)) {
      onAddOverride({
        date: selectedDate,
        timeSlots: isUnavailable
          ? [{ timeStart: "Unavailable all day", timeEnd: "" }]
          : timeSlots,
      });
    }
  };

  return (
    <ChatContainer container component={Card}>
      <ChatSidebar item xs={12} md={6} lg={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateCalendar", "DateCalendar"]}>
            <DemoItem label="">
              <DateCalendar
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue || dayjs())}
                style={{ width: "100%" }}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </ChatSidebar>
      <ChatSidebar item xs={12} md={6} lg={6} style={{ border: "0" }}>
        <Typography variant="body2" gutterBottom>
          Which hours are you free?
        </Typography>
        {timeSlots.map((slot, index) => (
          <Grid
            container
            spacing={2}
            alignItems="center"
            key={index}
            style={{ marginBottom: "10px" }}
          >
            <Grid item md={4}>
              <Select
                fullWidth
                value={slot.timeStart}
                onChange={(e) => {
                  const updatedTimeSlots = [...timeSlots];
                  updatedTimeSlots[index].timeStart = e.target.value;
                  setTimeSlots(updatedTimeSlots);
                }}
                disabled={isUnavailable}
                size="small"
                sx={{
                  height: "40px",
                  "& .MuiSelect-select": {
                    padding: "8px 14px",
                  },
                }}
              >
                {times.map((time) => (
                  <MenuItem key={time} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={1} style={{ textAlign: "center" }}>
              <IconButton
                style={{
                  padding: "4px",
                  color: "gray",
                  visibility: timeSlots.length > 1 ? "visible" : "hidden",
                }}
                onClick={() => removeTimeSlot(index)}
                size="small"
                disabled={isUnavailable}
              >
                <Remove fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item md={4}>
              <Select
                fullWidth
                value={slot.timeEnd}
                onChange={(e) => {
                  const updatedTimeSlots = [...timeSlots];
                  updatedTimeSlots[index].timeEnd = e.target.value;
                  setTimeSlots(updatedTimeSlots);
                }}
                disabled={isUnavailable}
                size="small"
                sx={{
                  height: "40px",
                  "& .MuiSelect-select": {
                    padding: "8px 14px",
                  },
                }}
              >
                {times.map((time) => (
                  <MenuItem key={time} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={1}>
              <IconButton
                onClick={addTimeSlot}
                style={{
                  padding: "4px",
                  cursor: "pointer",
                  color: "green",
                }}
                size="small"
                disabled={isUnavailable}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <FormGroup style={{ marginTop: "10px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isUnavailable}
                onChange={(e) => setIsUnavailable(e.target.checked)}
              />
            }
            label="Mark unavailable (All day)"
          />
        </FormGroup>
      </ChatSidebar>
      <Grid container justifyContent="flex-end" style={{ marginTop: "20px" }}>
        <Button onClick={handleAddOverride} variant="contained" color="primary">
          Add Override
        </Button>
      </Grid>
    </ChatContainer>
  );
};

function EnhancedTableToolbar(props) {
  const { numSelected, onBulkDelete, onBulkEdit, viewStyle, setViewStyle } =
    props;

  const handleViewClick = () => {
    setViewStyle(viewStyle === "List" ? "Card" : "List");
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "70px",
        borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
      }}
    >
      <div>
        {numSelected > 0 && (
          <Typography
            color="inherit"
            variant="subtitle1"
            sx={{ fontWeight: 500 }}
          >
            {numSelected} selected
          </Typography>
        )}
      </div>

      <Box>
        {numSelected > 0 ? (
          <Box display="flex">
            {numSelected === 1 && (
              <Tooltip title="Edit">
                <IconButton
                  aria-label="Edit"
                  size="large"
                  onClick={onBulkEdit}
                  sx={{ mr: 1 }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                size="large"
                onClick={onBulkDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Tooltip
            title={
              viewStyle === "List"
                ? "Change to Card View"
                : "Change to List View"
            }
          >
            <IconButton
              color="primary"
              onClick={handleViewClick}
              size="medium"
              sx={{
                borderRadius: "8px",
                p: 1,
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.08),
                "&:hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.12),
                },
              }}
            >
              {viewStyle === "List" ? <ViewModuleIcon /> : <ViewListIcon />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Toolbar>
  );
}

const CREATE_AVAILABILITY_MUTATION = `
  mutation CreateAvailability($input: AvailabilityInput!) {
    createAvailability(input: $input) {
      id
      name
      timezone
      days
      timeSlots {
        timeStart
        timeEnd
      }
      overrides {
        date
        timeSlots {
          timeStart
          timeEnd
        }
        isUnavailable
      }
    }
  }
`;

const UPDATE_AVAILABILITY_MUTATION = `
  mutation UpdateAvailability($id: ID!, $input: AvailabilityInput!) {
    updateAvailability(id: $id, input: $input) {
      id
      name
      timezone
      days
      timeSlots {
        timeStart
        timeEnd
      }
      overrides {
        date
        timeSlots {
          timeStart
          timeEnd
        }
        isUnavailable
      }
    }
  }
`;

const DELETE_AVAILABILITY_MUTATION = `
  mutation DeleteAvailability($id: ID!) {
    deleteAvailability(id: $id)
  }
`;

const GET_ALL_AVAILABILITIES = `
  query GetAllAvailabilities {
    getAllAvailabilities {
      id
      name
      timezone
      days
      timeSlots {
        timeStart
        timeEnd
      }
      overrides {
        date
        timeSlots {
          timeStart
          timeEnd
        }
        isUnavailable
      }
    }
  }
`;

const EventEntryForm = ({ editingAvailability, handleClose, onSuccess }) => {
  const [availabilityName, setAvailabilityName] = useState(
    editingAvailability ? editingAvailability.name : ""
  );
  const [timezoneValue, setTimezoneValue] = useState(
    editingAvailability ? editingAvailability.timezone : ""
  );
  const [selectedDays, setSelectedDays] = useState(
    editingAvailability ? editingAvailability.days : []
  );
  const [availabilityTimeSlots, setAvailabilityTimeSlots] = useState(
    editingAvailability ? editingAvailability.timeSlots : []
  );
  const [availabilityOverrides, setAvailabilityOverrides] = useState(
    editingAvailability ? editingAvailability.overrides : []
  );

  // New state to track time slots per day
  const [dayTimeSlots, setDayTimeSlots] = useState(() => {
    // Initialize with existing time slots if editing
    if (editingAvailability) {
      // For simplicity, we're assuming one time slot per day in this example
      // You may need to adapt this if your data structure allows multiple slots per day
      const slotsMap = {};
      selectedDays.forEach((day, index) => {
        if (index < editingAvailability.timeSlots.length) {
          slotsMap[day] = [editingAvailability.timeSlots[index]];
        } else {
          slotsMap[day] = [];
        }
      });
      return slotsMap;
    }
    return {};
  });

  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const input = {
        name: availabilityName,
        timezone: timezoneValue,
        days: selectedDays,
        timeSlots: availabilityTimeSlots,
        overrides: availabilityOverrides,
      };

      let response;
      if (editingAvailability) {
        response = await axiosInstance.post("/graphql", {
          query: UPDATE_AVAILABILITY_MUTATION,
          variables: {
            id: editingAvailability.id,
            input,
          },
        });

        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        onSuccess(response.data.updateAvailability);
        setAlert({
          open: true,
          message: "Availability updated successfully!",
          severity: "success",
        });
      } else {
        response = await axiosInstance.post("/graphql", {
          query: CREATE_AVAILABILITY_MUTATION,
          variables: {
            input,
          },
        });

        if (response.data.errors) {
          throw new Error(response.errors[0].message);
        }

        onSuccess(response.data.createAvailability);
        setAlert({
          open: true,
          message: "Availability created successfully!",
          severity: "success",
        });
      }

      handleClose();
    } catch (error) {
      console.error("Error creating/updating availability:", error.message);
      setAlert({
        open: true,
        message: `Error: ${error.message}`,
        severity: "error",
      });
    }
  };

  const handleDaySelection = (day, slots, isChecked) => {
    if (isChecked) {
      // Add day to selectedDays if not already present
      setSelectedDays((prevDays) => {
        if (!prevDays.includes(day)) {
          return [...prevDays, day];
        }
        return prevDays;
      });

      // Store the time slots for this specific day
      setDayTimeSlots((prev) => ({
        ...prev,
        [day]: slots.filter((slot) => slot.timeStart && slot.timeEnd),
      }));

      // Add time slots without the `day` field
      setAvailabilityTimeSlots((prevSlots) => [
        ...prevSlots,
        ...slots
          .filter((slot) => slot.timeStart && slot.timeEnd)
          .map((slot) => ({
            timeStart: slot.timeStart,
            timeEnd: slot.timeEnd,
          })),
      ]);
    } else {
      // Remove day from selectedDays
      setSelectedDays((prevDays) => prevDays.filter((d) => d !== day));

      // Remove time slots for this day
      setDayTimeSlots((prev) => {
        const updated = { ...prev };
        delete updated[day];
        return updated;
      });

      // Update overall time slots
      // This is simplistic and assumes one slot per day - you may need to adjust
      setAvailabilityTimeSlots((prevSlots) =>
        prevSlots.filter((_, index) => {
          const dayIndex = selectedDays.indexOf(day);
          return dayIndex === -1 || index !== dayIndex;
        })
      );
    }
  };

  const handleAddOverride = (override) => {
    const formattedOverride = {
      ...override,
      date: override.date.format("YYYY-MM-DD"),
      isUnavailable: Boolean(
        override.timeSlots[0]?.timeStart === "Unavailable all day"
      ),
    };
    setAvailabilityOverrides([...availabilityOverrides, formattedOverride]);
    setOpen(false); // Close the dialog after adding
  };

  return (
    <form onSubmit={handleSubmit}>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <TextField
              id="availabilityName"
              label="Availability Name"
              value={availabilityName}
              onChange={(e) => setAvailabilityName(e.target.value)}
              variant="outlined"
              fullWidth
              my={4}
            />
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth variant="outlined" my={4}>
              <InputLabel id="timezone-label">Timezone</InputLabel>
              <Select
                id="timezone"
                label="Timezone"
                value={timezoneValue}
                onChange={(e) => setTimezoneValue(e.target.value)}
                fullWidth
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Timezone
                </MenuItem>
                <MenuItem value="America/New_York">
                  America/New York GMT -4:00
                </MenuItem>
                <MenuItem value="Europe/London">
                  Europe/London GMT +1:00
                </MenuItem>
                <MenuItem value="Asia/Kolkata">Asia/Kolkata GMT +5:30</MenuItem>
                {/* Add more timezones as needed */}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Typography variant="subtitle1" mt={2} mb={1}>
          Set Availability
          <Typography
            color="textSecondary"
            component="span"
            variant="body2"
            sx={{ ml: 1 }}
          >
            Set when you are available so that you can be booked
          </Typography>
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            {[
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ].map((day) => (
              <MyTable
                key={day}
                label={day}
                isAvailable={selectedDays.includes(day)}
                setIsAvailable={(slots, isChecked) =>
                  handleDaySelection(day, slots, isChecked)
                }
                dayTimeSlots={dayTimeSlots[day] || []}
              />
            ))}
          </Grid>
        </Grid>

        {/* Rest of the component remains the same */}
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mt={3}
          mb={2}
        >
          <Typography variant="subtitle1">Date Overrides</Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}
            startIcon={<AddIcon />}
          >
            Add an override
          </Button>
        </Grid>

        {/* List of Date Overrides */}
        <Grid container spacing={2}>
          {availabilityOverrides.map((override, index) => (
            <Grid
              key={index}
              item
              xs={12}
              md={6}
              sx={{
                p: 2,
                border: 1,
                borderColor: "divider",
                borderRadius: 1,
                mb: 2,
              }}
            >
              <Typography variant="subtitle1">
                {typeof override.date === "string"
                  ? dayjs(override.date).format("dddd, MMMM D")
                  : override.date.format("dddd, MMMM D")}
                <Typography
                  color="textSecondary"
                  variant="body2"
                  sx={{ mt: 1 }}
                >
                  {override.isUnavailable
                    ? "Unavailable all day"
                    : override.timeSlots
                        .filter((slot) => slot.timeStart && slot.timeEnd)
                        .map((slot) => `${slot.timeStart} - ${slot.timeEnd}`)
                        .join(", ")}
                </Typography>
              </Typography>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
          {editingAvailability ? "Update Availability" : "Create Availability"}
        </Button>
      </DialogActions>

      {/* Dialog for Adding Override */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Select the dates to override</DialogTitle>
        <DialogContent>
          <MonthView onAddOverride={handleAddOverride} />
        </DialogContent>
      </Dialog>
    </form>
  );
};

function MyAvailability() {
  const [availabilities, setAvailabilities] = useState([]);
  const [selected, setSelected] = useState([]);
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [editingAvailability, setEditingAvailability] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    availability: null,
  });
  const [bulkDeleteDialog, setBulkDeleteDialog] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // States for enhanced UI
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [viewStyle, setViewStyle] = useState("List");
  const [searchQuery, setSearchQuery] = useState("");

  // Reset page when search query changes
  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter availabilities based on search query
  const filteredAvailabilities = availabilities.filter((availability) => {
    if (!searchQuery) return true;

    const query = searchQuery.toLowerCase();
    return (
      availability.name.toLowerCase().includes(query) ||
      availability.timezone.toLowerCase().includes(query) ||
      availability.id.toString().toLowerCase().includes(query) ||
      availability.days.some((day) => day.toLowerCase().includes(query))
    );
  });

  useEffect(() => {
    fetchAvailabilities();
  }, []);

  const fetchAvailabilities = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: GET_ALL_AVAILABILITIES,
      });

      if (response.errors) {
        console.error(response.errors);
        return;
      }

      setAvailabilities(response.data.getAllAvailabilities || []);
    } catch (error) {
      console.error("Error fetching availabilities:", error);
      setAlert({
        open: true,
        message: "Error fetching availabilities",
        severity: "error",
      });
    }
  };

  const handleAddClick = () => {
    setEditingAvailability(null);
    setFormDialogOpen(true);
  };

  const handleEditClick = (availability) => {
    setEditingAvailability(availability);
    setFormDialogOpen(true);
  };

  const handleFormSuccess = (updatedAvailability) => {
    if (editingAvailability) {
      // Update existing availability in the list
      setAvailabilities((prevAvailabilities) =>
        prevAvailabilities.map((item) =>
          item.id === updatedAvailability.id ? updatedAvailability : item
        )
      );
    } else {
      // Add new availability to the list
      setAvailabilities((prevAvailabilities) => [
        ...prevAvailabilities,
        updatedAvailability,
      ]);
    }
  };

  const handleFormClose = () => {
    setFormDialogOpen(false);
    setEditingAvailability(null);
  };

  const handleDeleteClick = (availability) => {
    setDeleteDialog({ open: true, availability });
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: DELETE_AVAILABILITY_MUTATION,
        variables: { id: deleteDialog.availability.id },
      });

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      // Remove from the list
      setAvailabilities((prevAvailabilities) =>
        prevAvailabilities.filter(
          (item) => item.id !== deleteDialog.availability.id
        )
      );

      setAlert({
        open: true,
        message: "Availability deleted successfully",
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: error.message || "Error deleting availability",
        severity: "error",
      });
    } finally {
      setDeleteDialog({ open: false, availability: null });
    }
  };

  const handleBulkDelete = () => {
    setBulkDeleteDialog(true);
  };

  const handleBulkEdit = () => {
    if (selected.length !== 1) {
      setAlert({
        open: true,
        message: "Please select exactly one availability to edit",
        severity: "info",
      });
      return;
    }

    // Find the selected availability
    const availabilityToEdit = availabilities.find(
      (availability) => availability.id === selected[0]
    );
    handleEditClick(availabilityToEdit);
  };

  const handleBulkDeleteConfirm = async () => {
    try {
      // Execute delete mutations for all selected availabilities
      const deletePromises = selected.map((id) =>
        axiosInstance.post("/graphql", {
          query: DELETE_AVAILABILITY_MUTATION,
          variables: { id },
        })
      );

      await Promise.all(deletePromises);

      // Remove deleted items from the list
      setAvailabilities((prevAvailabilities) =>
        prevAvailabilities.filter((item) => !selected.includes(item.id))
      );

      setAlert({
        open: true,
        message: `Successfully deleted ${selected.length} availabilities`,
        severity: "success",
      });

      // Clear selections
      setSelected([]);
    } catch (error) {
      setAlert({
        open: true,
        message: `Error deleting availabilities: ${error.message}`,
        severity: "error",
      });
    } finally {
      setBulkDeleteDialog(false);
    }
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = availabilities.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Render card view
  const renderCardView = () => {
    const paginatedAvailabilities = stableSort(
      filteredAvailabilities,
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
      <>
        <Box px={2} py={2} display="flex" justifyContent="flex-end">
          <Checkbox
            indeterminate={
              selected.length > 0 &&
              selected.length < filteredAvailabilities.length
            }
            checked={
              filteredAvailabilities.length > 0 &&
              selected.length === filteredAvailabilities.length
            }
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all availabilities" }}
          />
          <Typography
            variant="body2"
            style={{ marginLeft: "8px", alignSelf: "center" }}
          >
            Select All
          </Typography>
        </Box>
        <Box m={1} pt={1}>
          <Grid container spacing={4}>
            {paginatedAvailabilities.map((availability, index) => (
              <Grid item key={availability.id} xs={12} sm={6} md={4} lg={3}>
                <AvailabilityCard
                  availability={availability}
                  isSelected={isSelected(availability.id)}
                  onSelect={handleClick}
                  serialNumber={page * rowsPerPage + index + 1}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px 0",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredAvailabilities.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  marginBottom: "0",
                  display: "flex",
                  alignItems: "center",
                },
              ".MuiTablePagination-select": {
                marginRight: "32px",
                marginLeft: "8px",
              },
            }}
          />
        </Box>
      </>
    );
  };

  // Render table view with DataGrid
  const renderTableView = () => {
    // Get paginated data with pre-calculated serial numbers
    const paginatedAvailabilities = stableSort(
      filteredAvailabilities,
      getComparator(order, orderBy)
    )
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((availability, index) => ({
        ...availability,
        serialNumber: page * rowsPerPage + index + 1,
        daysText: availability.days.join(", "),
        timeSlotsCount: availability.timeSlots.length,
        overridesCount: availability.overrides.length,
      }));

    // Define columns for DataGrid
    const columns = [
      {
        field: "select",
        headerName: "",
        width: 50,
        sortable: false,
        filterable: false,
        headerClassName: "select-all-header",
        renderHeader: () => (
          <Checkbox
            indeterminate={
              selected.length > 0 &&
              selected.length < filteredAvailabilities.length
            }
            checked={
              filteredAvailabilities.length > 0 &&
              selected.length === filteredAvailabilities.length
            }
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all availabilities" }}
          />
        ),
        renderCell: (params) => (
          <Checkbox
            checked={isSelected(params.row.id)}
            onClick={(event) => handleClick(event, params.row.id)}
            inputProps={{ "aria-labelledby": `select-${params.row.id}` }}
          />
        ),
      },
      {
        field: "serialNumber",
        headerName: "S.No",
        width: 70,
        sortable: false,
        filterable: false,
      },
      {
        field: "id",
        headerName: "ID",
        width: 80,
        renderCell: (params) => (
          <Typography variant="body2">#{params.row.id}</Typography>
        ),
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "timezone",
        headerName: "Timezone",
        flex: 1,
      },
      {
        field: "daysText",
        headerName: "Days",
        flex: 1.5,
      },
      {
        field: "details",
        headerName: "Details",
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Box>
            <Typography variant="body2">
              Time Slots: {params.row.timeSlotsCount}
            </Typography>
            <Typography variant="body2">
              Overrides: {params.row.overridesCount}
            </Typography>
          </Box>
        ),
      },
    ];

    return (
      <>
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={paginatedAvailabilities}
            columns={columns}
            hideFooter={true}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            checkboxSelection={false}
            rowHeight={70}
            headerHeight={56}
            getRowId={(row) => row.id}
            components={{
              Toolbar: () => null,
            }}
            sortingMode="server"
            sortModel={[
              {
                field: orderBy,
                sort: order.toLowerCase(),
              },
            ]}
            onSortModelChange={(model) => {
              if (model.length > 0) {
                setOrderBy(model[0].field);
                setOrder(model[0].sort === "asc" ? "asc" : "desc");
              }
            }}
            sx={{
              boxShadow: 0,
              border: 0,
              "& .MuiDataGrid-cell": {
                padding: "8px 16px",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "8px 16px",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredAvailabilities.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-toolbar": {
                alignItems: "center",
                minHeight: "40px",
                padding: "0 8px",
              },
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                },
              ".MuiTablePagination-select": {
                marginRight: "8px",
                marginLeft: "8px",
              },
              ".MuiTablePagination-actions": {
                display: "flex",
                alignItems: "center",
              },
            }}
          />
        </Box>
      </>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="My Availability" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            My Availability
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/private">
              Spaces
            </Link>
            <Link component={NavLink} to="/calendar/dashboard">
              Calendar
            </Link>
            <Typography color="text.primary">Availability</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddClick}
            startIcon={<AddIcon />}
          >
            Add Availability
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <SearchBar
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Search availabilities..."
        containerSx={{
          borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
        }}
      />

      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onBulkDelete={handleBulkDelete}
          onBulkEdit={handleBulkEdit}
          viewStyle={viewStyle}
          setViewStyle={setViewStyle}
        />

        {/* Toggle between view styles */}
        {viewStyle === "List" ? renderTableView() : renderCardView()}
      </Paper>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>

      {/* Add/Edit Availability Dialog */}
      <Dialog
        open={formDialogOpen}
        onClose={handleFormClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {editingAvailability ? "Edit Availability" : "Add New Availability"}
        </DialogTitle>
        <EventEntryForm
          editingAvailability={editingAvailability}
          handleClose={handleFormClose}
          onSuccess={handleFormSuccess}
        />
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({ open: false, availability: null })}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the availability "
            {deleteDialog.availability?.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteDialog({ open: false, availability: null })}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Bulk Delete Dialog */}
      <Dialog
        open={bulkDeleteDialog}
        onClose={() => setBulkDeleteDialog(false)}
      >
        <DialogTitle>Confirm Bulk Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {selected.length} selected
            availabilities? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBulkDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={handleBulkDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete {selected.length} Availabilities
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default MyAvailability;
