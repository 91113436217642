import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  Box,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary.main};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

function ResetPassword() {
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const [status, setStatus] = useState(null);

  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
        try {
          await resetPassword(values.email);
          setStatus({ success: true });
          setSubmitting(false);
          // Clear the email field on success
          resetForm({ values: { email: "", submit: false } });
          // Don't redirect immediately to allow the user to see the success message
        } catch (error) {
          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        resetForm,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {status && status.success && (
            <Alert mt={2} mb={1} severity="success">
              Password reset instructions have been sent to your email address.
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={(e) => {
              // Clear the success message when user starts typing again
              if (status && status.success) {
                setStatus(null);
              }
              handleChange(e);
            }}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={() => {
              // Clear the success message when clicking reset password again
              if (status && status.success) {
                setStatus(null);
              }
            }}
          >
            Reset password
          </Button>
          <Box mt={3} textAlign="center">
            <Typography variant="body2" color="textSecondary">
              Remember your password?{" "}
              <StyledLink to="/auth/sign-in">Sign in here</StyledLink>
            </Typography>
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
