import React from "react";

import { AppProvider } from "./AppContext";
import AppBar from "./AppBar";
import Introduction from "./Introduction";
import Demos from "./Demos";
import Testimonial from "./Testimonial";
import Integrations from "./Integrations";
import Features from "./Features";
import FAQ from "./FAQ";
import JoinUs from "./JoinUs";

function Presentation() {
  return (
    <AppProvider>
      <React.Fragment>
        <AppBar />
        <Introduction />
        <Demos />
        <Testimonial />
        <Integrations />
        <Features />
        <FAQ />
        <JoinUs />
      </React.Fragment>
    </AppProvider>
  );
}

export default Presentation;
