// src/utils/permissionConstants.js
export const PERMISSIONS = {
  SETTINGS: {
    name: "SETTINGS",
    description: "Main settings permissions",
    childrens: [
      {
        name: "BUSINESS_SETTINGS",
        description: "Business settings section",
        childrens: ["ACTIVITY", "SUBSCRIPTION", "USAGE", "BILLING"],
      },
      {
        name: "PROFILE",
        description: "Profile settings",
        childrens: [],
      },
      {
        name: "CHANGE_PASSWORD",
        description: "Password change section",
        childrens: [],
      },
      {
        name: "INTEGRATIONS",
        description: "Integration settings",
        childrens: [
          "CALENDARS",
          "CONFERENCES",
          "AUTOMATION",
          "PAYMENTS",
          "ANALYTICS",
        ],
      },
      "VIEW_SETTINGS",
      "UPDATE_SETTINGS",
    ],
  },
  USERS: {
    name: "USERS",
    description: "Permissions for user management",
    childrens: ["VIEW_USERS", "ADD_USERS", "UPDATE_USERS", "DELETE_USERS"],
  },
  GROUPS: {
    name: "GROUPS",
    description: "Permissions for groups management",
    childrens: ["VIEW_GROUPS", "ADD_GROUPS", "UPDATE_GROUPS", "DELETE_GROUPS"],
  },
  ROLES: {
    name: "ROLES",
    description: "Permissions for roles and permissions",
    childrens: [
      "VIEW_ROLES_PERMISSIONS",
      "ADD_ROLES_PERMISSIONS",
      "UPDATE_ROLES_PERMISSIONS",
      "DELETE_ROLES_PERMISSIONS",
    ],
  },
  SPACES: {
    name: "SPACES",
    description: "Permissions for space management",
    childrens: [
      "VIEW_SPACE_MANAGEMENT",
      "ADD_SPACE_MANAGEMENT",
      "UPDATE_SPACE_MANAGEMENT",
      "DELETE_SPACE_MANAGEMENT",
    ],
  },
};
