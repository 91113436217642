import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { axiosInstance } from "../../utils/axios";
import useAuth from "../../hooks/useAuth";
import { useSavedPermissions } from "../../utils/permissionUtils";
import { PERMISSIONS } from "../../utils/permissionConstants";
import { DataGrid } from "@mui/x-data-grid";
import { alpha } from "@mui/material/styles";
import { Search as SearchIcon } from "@mui/icons-material";
import { InputAdornment, CircularProgress } from "@mui/material";
import SearchBar from "../components/SearchBar";
import {
  Alert,
  TextField as MuiTextField,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Snackbar,
  FormControlLabel,
  FormHelperText,
  Box,
  Card as MuiCard,
  CardContent,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Card = styled(MuiCard)(spacing);

const CREATE_GROUP_MUTATION = `
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      group {
        id
        name
      }
      roles {
        id
        name
      }
    }
  }
`;

const UPDATE_GROUP = `
  mutation UpdateGroup($id: ID!, $input: UpdateGroupInput!) {
    updateGroup(id: $id, input: $input) {
      group {
        id
        name
      }
      roles {
        id
        name
      }
    }
  }
`;

const DELETE_GROUP = `
  mutation DeleteGroup($id: ID!) {
    deleteGroup(id: $id)
  }
`;

// Group Card Component
const GroupCard = ({ group, isSelected, onSelect, serialNumber }) => {
  return (
    <Paper>
      <Card>
        <div style={{ position: "relative" }}>
          <div
            style={{
              backgroundColor: "#f0f0f0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "140px",
            }}
          >
            <Typography variant="h2" color="textSecondary">
              {group.group.name.charAt(0).toUpperCase()}
            </Typography>
          </div>
          <Checkbox
            checked={isSelected}
            onChange={(e) => onSelect(e, group.group.id)}
            style={{ position: "absolute", top: "8px", left: "8px" }}
          />
        </div>
        <CardContent>
          <Box>
            <Typography variant="h5" gutterBottom>
              {group.group.name}
            </Typography>
          </Box>
          <Typography mb={2} color="textSecondary" component="p">
            S.No: {serialNumber}
            <br />
            Roles: {group.roles.map((role) => role.name).join(", ")}
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "name", alignment: "left", label: "Name" },
  { id: "roles", alignment: "left", label: "Roles" },
  { id: "actions", alignment: "right", label: "Actions" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all groups" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    onBulkDelete,
    onBulkEdit,
    canDelete,
    canUpdate,
    viewStyle,
    setViewStyle,
    // Remove the searchQuery and setSearchQuery props
  } = props;

  const handleViewClick = () => {
    setViewStyle(viewStyle === "List" ? "Card" : "List");
  };

  // Remove the handleSearchChange function

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "70px",
        borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
      }}
    >
      {/* Replace the conditional rendering of search/selected with just selected text */}
      <div>
        {numSelected > 0 && (
          <Typography
            color="inherit"
            variant="subtitle1"
            sx={{ fontWeight: 500 }}
          >
            {numSelected} selected
          </Typography>
        )}
      </div>

      <Box>
        {numSelected > 0 ? (
          <Box display="flex">
            {numSelected === 1 && canUpdate && (
              <Tooltip title="Edit">
                <IconButton
                  aria-label="Edit"
                  size="large"
                  onClick={onBulkEdit}
                  sx={{ mr: 1 }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {canDelete && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="Delete"
                  size="large"
                  onClick={onBulkDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ) : (
          <Tooltip
            title={
              viewStyle === "List"
                ? "Change to Card View"
                : "Change to List View"
            }
          >
            <IconButton
              color="primary"
              onClick={handleViewClick}
              size="medium"
              sx={{
                borderRadius: "8px",
                p: 1,
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.08),
                "&:hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.12),
                },
              }}
            >
              {viewStyle === "List" ? <ViewModuleIcon /> : <ViewListIcon />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Toolbar>
  );
}

function Groups() {
  const { user } = useAuth();
  const savedPermissions = useSavedPermissions();
  const isPremium = user?.subscriptionLevel === "1";
  const [loading, setLoading] = useState(true);

  // Permission checks
  const canViewGroups =
    isPremium || savedPermissions.includes(PERMISSIONS.GROUPS.childrens[0]);
  const canAddGroups =
    isPremium || savedPermissions.includes(PERMISSIONS.GROUPS.childrens[1]);
  const canUpdateGroups =
    isPremium || savedPermissions.includes(PERMISSIONS.GROUPS.childrens[2]);
  const canDeleteGroups =
    isPremium || savedPermissions.includes(PERMISSIONS.GROUPS.childrens[3]);

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [open, setOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [editingGroup, setEditingGroup] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    group: null,
  });
  const [bulkDeleteDialog, setBulkDeleteDialog] = useState(false);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // Add new states for view style and search
  const [viewStyle, setViewStyle] = useState("List");
  const [searchQuery, setSearchQuery] = useState("");

  // Reset page when search query changes
  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter groups based on search query
  const filteredGroups = groups.filter((group) => {
    if (!searchQuery) return true;

    const query = searchQuery.toLowerCase();
    return (
      group.group.name.toLowerCase().includes(query) ||
      group.group.id.toString().toLowerCase().includes(query) ||
      group.roles.some((role) => role.name.toLowerCase().includes(query))
    );
  });

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  const handleClickOpen = () => {
    if (!canAddGroups) {
      setAlert({
        open: true,
        message: "You don't have permission to add groups",
        severity: "warning",
      });
      return;
    }
    setEditingGroup(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingGroup(null);
  };

  const handleEdit = (group) => {
    if (!canUpdateGroups) {
      setAlert({
        open: true,
        message: "You don't have permission to edit groups",
        severity: "warning",
      });
      return;
    }
    setEditingGroup(group);
    setOpen(true);
  };

  const handleDelete = (group) => {
    if (!canDeleteGroups) {
      setAlert({
        open: true,
        message: "You don't have permission to delete groups",
        severity: "warning",
      });
      return;
    }
    setDeleteConfirmation({ open: true, group });
  };

  const handleBulkDelete = () => {
    if (!canDeleteGroups) {
      setAlert({
        open: true,
        message: "You don't have permission to delete groups",
        severity: "warning",
      });
      return;
    }
    setBulkDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: DELETE_GROUP,
        variables: { id: deleteConfirmation.group.group.id },
      });

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      setGroups(
        groups.filter((g) => g.group.id !== deleteConfirmation.group.group.id)
      );
      setAlert({
        open: true,
        message: "Group deleted successfully",
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: error.message || "Error deleting group",
        severity: "error",
      });
    } finally {
      setDeleteConfirmation({ open: false, group: null });
    }
  };

  const handleBulkDeleteConfirm = async () => {
    try {
      const deletePromises = selected.map(async (id) => {
        const response = await axiosInstance.post("/graphql", {
          query: DELETE_GROUP,
          variables: { id },
        });

        // Check for GraphQL errors in the response
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        return response;
      });

      await Promise.all(deletePromises);
      setGroups(groups.filter((group) => !selected.includes(group.group.id)));
      setSelected([]);
      setAlert({
        open: true,
        message: `Successfully deleted ${selected.length} groups`,
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: `Error deleting groups: ${error.message}`,
        severity: "error",
      });
    } finally {
      setBulkDeleteDialog(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = groups.map((n) => n.group.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    // Debug permissions
    console.log({
      user,
      isPremium,
      savedPermissions,
      requiredViewPermission: PERMISSIONS.GROUPS.childrens[0],
      canViewGroups,
    });

    const fetchGroups = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query {
              getGroupsWithRoles {
                groupsWithRoles {
                  group {
                    id
                    name
                    createdAt
                  }
                  roles {
                    id
                    name
                  }
                }
              }
            }
          `,
        });

        if (response.data.errors) {
          throw new Error(response.data.errors[0].message);
        }

        // Sort by createdAt in descending order
        const sortedGroups =
          response.data.getGroupsWithRoles.groupsWithRoles.sort((a, b) => {
            return new Date(b.group.createdAt) - new Date(a.group.createdAt);
          });
        setGroups(sortedGroups);
      } catch (error) {
        setAlert({
          open: true,
          message: error.message || "Error fetching groups",
          severity: "error",
        });
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
          query {
            getRoles {
              id
              name
            }
          }
        `,
        });

        if (response.data.errors) {
          throw new Error(response.data.errors[0].message);
        }
        setRoles(response.data.getRoles);
      } catch (error) {
        setAlert({
          open: true,
          message: error.message || "Error fetching roles",
          severity: "error",
        });
      }
    };

    const loadData = async () => {
      setLoading(true);
      if (savedPermissions.length > 0 || isPremium) {
        if (canViewGroups) {
          await Promise.all([fetchGroups(), fetchRoles()]);
        }
      }
      setLoading(false);
    };

    loadData();
  }, [isPremium, savedPermissions, canViewGroups]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Render card view
  const renderCardView = () => {
    const paginatedGroups = stableSort(
      filteredGroups,
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
      <>
        <Box px={2} py={2} display="flex" justifyContent="flex-end">
          <Checkbox
            indeterminate={
              selected.length > 0 && selected.length < filteredGroups.length
            }
            checked={
              filteredGroups.length > 0 &&
              selected.length === filteredGroups.length
            }
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all groups" }}
          />
          <Typography
            variant="body2"
            style={{ marginLeft: "8px", alignSelf: "center" }}
          >
            Select All
          </Typography>
        </Box>
        <Box m={1} pt={1}>
          <Grid container spacing={4}>
            {paginatedGroups.map((group, index) => (
              <Grid item key={group.group.id} xs={12} sm={6} md={4} lg={3}>
                <GroupCard
                  group={group}
                  isSelected={isSelected(group.group.id)}
                  onSelect={handleClick}
                  serialNumber={page * rowsPerPage + index + 1}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px 0",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredGroups.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  marginBottom: "0",
                  display: "flex",
                  alignItems: "center",
                },
              ".MuiTablePagination-select": {
                marginRight: "32px",
                marginLeft: "8px",
              },
            }}
          />
        </Box>
      </>
    );
  };

  // Render table view with DataGrid
  const renderTableView = () => {
    // Get paginated data with pre-calculated serial numbers
    const paginatedGroups = stableSort(
      filteredGroups,
      getComparator(order, orderBy)
    )
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((group, index) => ({
        ...group,
        serialNumber: page * rowsPerPage + index + 1,
      }));

    // Define columns for DataGrid - removed action column
    const columns = [
      {
        field: "select",
        headerName: "",
        width: 50,
        sortable: false,
        filterable: false,
        headerClassName: "select-all-header",
        renderHeader: () => (
          <Checkbox
            indeterminate={
              selected.length > 0 && selected.length < filteredGroups.length
            }
            checked={
              filteredGroups.length > 0 &&
              selected.length === filteredGroups.length
            }
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all groups" }}
          />
        ),
        renderCell: (params) => (
          <Checkbox
            checked={isSelected(params.row.group.id)}
            onClick={(event) => handleClick(event, params.row.group.id)}
            inputProps={{ "aria-labelledby": `select-${params.row.group.id}` }}
          />
        ),
      },
      {
        field: "serialNumber",
        headerName: "S.No",
        width: 80,
        sortable: false,
        filterable: false,
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        valueGetter: (params) => params.row.group.name,
      },
      {
        field: "roles",
        headerName: "Roles",
        flex: 1.5,
        valueGetter: (params) =>
          params.row.roles.map((role) => role.name).join(", "),
      },
    ];

    return (
      <>
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={paginatedGroups}
            columns={columns}
            hideFooter={true}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            checkboxSelection={false}
            rowHeight={70}
            headerHeight={56}
            getRowId={(row) => row.group.id}
            components={{
              Toolbar: () => null,
            }}
            sortingMode="server"
            sortModel={[
              {
                field: orderBy,
                sort: order.toLowerCase(),
              },
            ]}
            onSortModelChange={(model) => {
              if (model.length > 0) {
                setOrderBy(model[0].field);
                setOrder(model[0].sort === "asc" ? "asc" : "desc");
              }
            }}
            sx={{
              boxShadow: 0,
              border: 0,
              "& .MuiDataGrid-cell": {
                padding: "8px 16px",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "8px 16px",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredGroups.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-toolbar": {
                alignItems: "center",
                minHeight: "40px",
                padding: "0 8px",
              },
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                },
              ".MuiTablePagination-select": {
                marginRight: "8px",
                marginLeft: "8px",
              },
              ".MuiTablePagination-actions": {
                display: "flex",
                alignItems: "center",
              },
            }}
          />
        </Box>
      </>
    );
  };

  // Show loading indicator while permissions and data are being fetched
  if (loading) {
    return (
      <React.Fragment>
        <Helmet title="Groups" />
        <Typography variant="h3" gutterBottom>
          Groups
        </Typography>
        <Box display="flex" justifyContent="center" my={5}>
          <CircularProgress />
        </Box>
      </React.Fragment>
    );
  }

  // Show permission error message after loading completes
  if (!canViewGroups) {
    return (
      <React.Fragment>
        <Helmet title="Groups" />
        <Typography variant="h3" gutterBottom>
          Groups
        </Typography>
        {/* <Alert severity="warning"> */}
        {/*   You don't have permission to view this page. */}
        {/* </Alert> */}
      </React.Fragment>
    );
  }

  const handleBulkEdit = () => {
    if (!canUpdateGroups) {
      setAlert({
        open: true,
        message: "You don't have permission to edit groups",
        severity: "warning",
      });
      return;
    }

    if (selected.length !== 1) {
      setAlert({
        open: true,
        message: "Please select exactly one group to edit",
        severity: "info",
      });
      return;
    }

    // Find the selected group
    const groupToEdit = groups.find((group) => group.group.id === selected[0]);
    handleEdit(groupToEdit);
  };

  return (
    <React.Fragment>
      <Helmet title="Groups" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Groups
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/private">
              Spaces
            </Link>
            <Typography color="text.primary">Settings</Typography>
            <Typography color="text.primary">Groups</Typography>
          </Breadcrumbs>
        </Grid>
        {canAddGroups && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              startIcon={<AddIcon />}
            >
              Add Group
            </Button>
          </Grid>
        )}
      </Grid>

      <Divider my={6} />
      <SearchBar
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Search groups..."
        containerSx={{
          borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
        }}
      />
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onBulkDelete={handleBulkDelete}
          onBulkEdit={handleBulkEdit}
          canDelete={canDeleteGroups}
          canUpdate={canUpdateGroups}
          viewStyle={viewStyle}
          setViewStyle={setViewStyle}
        />

        {/* Toggle between view styles */}
        {viewStyle === "List" ? renderTableView() : renderCardView()}
      </Paper>

      {/* Add/Edit Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {editingGroup ? "Edit Group" : "Create New Group"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editingGroup
              ? "Edit the group details below"
              : "Enter the details for the new group"}
          </DialogContentText>

          <Formik
            initialValues={{
              name: editingGroup ? editingGroup.group.name : "",
              roles: editingGroup
                ? editingGroup.roles.map((role) => role.id)
                : [],
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .required("Name is required")
                .trim()
                .min(1, "Name cannot be empty"),
              roles: Yup.array().min(1, "At least one role is required"),
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                const mutation = editingGroup
                  ? UPDATE_GROUP
                  : CREATE_GROUP_MUTATION;
                const variables = editingGroup
                  ? {
                      id: editingGroup.group.id,
                      input: {
                        name: values.name,
                        roleIds: values.roles,
                      },
                    }
                  : {
                      input: {
                        name: values.name,
                        roleIds: values.roles,
                      },
                    };

                const response = await axiosInstance.post("/graphql", {
                  query: mutation,
                  variables,
                });

                if (response.errors) {
                  const errorMessage = response.errors[0].message;
                  if (errorMessage.includes("duplicate key error")) {
                    throw new Error(`Group "${values.name}" already exists`);
                  }
                  throw new Error(errorMessage);
                }

                const newGroup = editingGroup
                  ? response.data.updateGroup
                  : response.data.createGroup;

                setGroups((prevGroups) => {
                  if (editingGroup) {
                    return prevGroups.map((group) =>
                      group.group.id === editingGroup.group.id
                        ? newGroup
                        : group
                    );
                  }
                  return [newGroup, ...prevGroups];
                });

                // Clear selected items after successful update/create
                setSelected([]);

                setAlert({
                  open: true,
                  message: `Group successfully ${
                    editingGroup ? "updated" : "created"
                  }!`,
                  severity: "success",
                });
                handleClose();
              } catch (error) {
                setAlert({
                  open: true,
                  message: error.message,
                  severity: "error",
                });
                setErrors({ submit: error.message });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                <TextField
                  type="text"
                  name="name"
                  label="Name"
                  value={values.name}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Roles
                  </Typography>
                  {touched.roles && errors.roles && (
                    <FormHelperText error>{errors.roles}</FormHelperText>
                  )}
                  {roles.map((role) => (
                    <FormControlLabel
                      key={role.id}
                      control={
                        <Checkbox
                          checked={values.roles.includes(role.id)}
                          onChange={(event) => {
                            const selectedRoles = event.target.checked
                              ? [...values.roles, role.id]
                              : values.roles.filter((r) => r !== role.id);
                            handleChange({
                              target: {
                                name: "roles",
                                value: selectedRoles,
                              },
                            });
                          }}
                          name="roles"
                        />
                      }
                      label={role.name}
                    />
                  ))}
                </div>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {editingGroup ? "Save Changes" : "Create Group"}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, group: null })}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the group "
            {deleteConfirmation.group?.group.name}"? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteConfirmation({ open: false, group: null })}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Bulk Delete Dialog */}
      <Dialog
        open={bulkDeleteDialog}
        onClose={() => setBulkDeleteDialog(false)}
      >
        <DialogTitle>Confirm Bulk Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {selected.length} selected groups?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBulkDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={handleBulkDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete {selected.length} Groups
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alert Snackbar */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default Groups;
