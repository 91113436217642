// src/components/auth/UpdatePassword.js
import React from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  Box,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary.main};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

function UpdatePassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const { updatePassword } = useAuth();
  const [success, setSuccess] = React.useState(false);

  return (
    <Formik
      initialValues={{
        newPassword: "",
        confirmPassword: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        newPassword: Yup.string()
          .min(12, "Password must be at least 12 characters")
          .max(255)
          .required("New password is required"),
        confirmPassword: Yup.string()
          .required("Confirm password is required")
          .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
      })}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        try {
          await updatePassword(token, values.newPassword);
          setSuccess(true);
          setTimeout(() => {
            navigate("/auth/sign-in", {
              state: {
                message:
                  "Password updated successfully. You can now sign in with your new password.",
              },
            });
          }, 3000);
        } catch (error) {
          const message = error.message || "Something went wrong";
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}

          {success && (
            <Alert mt={2} mb={1} severity="success">
              Password updated successfully. Redirecting to sign in page...
            </Alert>
          )}

          <TextField
            type="password"
            name="newPassword"
            label="New Password"
            value={values.newPassword}
            error={Boolean(touched.newPassword && errors.newPassword)}
            fullWidth
            helperText={touched.newPassword && errors.newPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />

          <TextField
            type="password"
            name="confirmPassword"
            label="Confirm New Password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting || success}
          >
            Update Password
          </Button>

          <Box mt={3} textAlign="center">
            <Typography variant="body2" color="textSecondary">
              Remember your password?{" "}
              <StyledLink to="/auth/sign-in">Sign in here</StyledLink>
            </Typography>
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default UpdatePassword;
