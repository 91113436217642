// src/components/common/Notification.js
import React, { useState, useEffect } from "react";
import { Alert, Snackbar, Slide } from "@mui/material";
import PropTypes from "prop-types";

function SlideTransition(props) {
  return <Slide {...props} direction="right" />;
}

const Notification = ({
  message,
  severity = "success",
  autoHideDuration = 6000,
  position = { vertical: "bottom", horizontal: "left" },
  onClose,
}) => {
  const [open, setOpen] = useState(!!message);

  useEffect(() => {
    setOpen(!!message);
  }, [message]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  if (!message) return null;

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={position}
      TransitionComponent={SlideTransition}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        variant="filled"
        elevation={6}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

Notification.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.oneOf(["success", "info", "warning", "error"]),
  autoHideDuration: PropTypes.number,
  position: PropTypes.shape({
    vertical: PropTypes.oneOf(["top", "bottom"]),
    horizontal: PropTypes.oneOf(["left", "center", "right"]),
  }),
  onClose: PropTypes.func,
};

export default Notification;
