import { useEffect, useState, useMemo } from "react";
import useAuth from "../../hooks/useAuth";
import { useSavedPermissions } from "../../utils/permissionUtils";
import { PERMISSIONS } from "../../utils/permissionConstants";
import {
  Sliders,
  Briefcase,
  User,
  Calendar,
  Key,
  Users,
  Grid,
  CheckSquare,
  Map,
  Anchor,
} from "react-feather";

const useSettingsItems = () => {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const [navItems, setNavItems] = useState([]);
  const savedPermissions = useSavedPermissions();
  const isPremium = user?.subscriptionLevel === "1";

  // Check permissions for business settings
  const canAccessBusinessSettings =
    isPremium ||
    savedPermissions.includes(PERMISSIONS.SETTINGS.name) ||
    savedPermissions.some((perm) =>
      ["ACTIVITY", "SUBSCRIPTION", "USAGE", "BILLING"].includes(perm)
    );

  // Base settings items that are always available (no nested dropdowns)
  const baseSettingsChildren = useMemo(
    () => [
      {
        href: "/settings/profile",
        icon: User,
        title: "Profile",
        permission: "PROFILE",
      },
      {
        href: "/settings/change-password",
        icon: Key,
        title: "Change Password",
        permission: "CHANGE_PASSWORD",
      },
    ],
    []
  );

  // Business settings item with permission check
  const businessSettingsItem = useMemo(() => {
    if (!canAccessBusinessSettings) return [];

    return [
      {
        href: "/settings/business-settings",
        icon: Briefcase,
        title: "Business Settings",
        permission: "BUSINESS_SETTINGS",
      },
    ];
  }, [canAccessBusinessSettings]);

  // Integration settings with permission check
  const integrationItem = useMemo(() => {
    if (!(isPremium || savedPermissions.includes("INTEGRATIONS"))) return [];

    return [
      {
        href: "/settings/integrations",
        icon: Anchor,
        title: "Integrations",
        permission: "INTEGRATIONS",
      },
    ];
  }, [isPremium, savedPermissions]);

  // Regular settings items
  const regularSettingsChildren = useMemo(() => {
    const settingsItems = [];

    // Add business settings if user has access
    if (businessSettingsItem.length > 0) {
      settingsItems.push(...businessSettingsItem);
    }

    // Add base settings with permission checks
    baseSettingsChildren.forEach((item) => {
      if (
        isPremium ||
        !item.permission ||
        savedPermissions.includes(item.permission)
      ) {
        settingsItems.push(item);
      }
    });

    // Add integration settings if user has access
    if (integrationItem.length > 0) {
      settingsItems.push(...integrationItem);
    }

    // Add availability for regular users (non-premium)

    return settingsItems;
  }, [
    baseSettingsChildren,
    businessSettingsItem,
    integrationItem,
    isPremium,
    savedPermissions,
  ]);

  useEffect(() => {
    if (!isInitialized || !isAuthenticated) {
      setNavItems([]);
      return;
    }

    const pagesSection = [
      {
        href: "settings",
        icon: Sliders,
        title: "Settings",
        children: regularSettingsChildren,
      },
    ];

    // Only add permission-based items if premium or has permission
    if (
      isPremium ||
      savedPermissions.includes(PERMISSIONS.USERS.childrens[0])
    ) {
      pagesSection.push({
        href: "/settings/users",
        icon: Users,
        title: "User Management",
      });
    }

    if (
      isPremium ||
      savedPermissions.includes(PERMISSIONS.GROUPS.childrens[0])
    ) {
      pagesSection.push({
        href: "/settings/groups",
        icon: Grid,
        title: "Groups",
      });
    }

    if (
      isPremium ||
      savedPermissions.includes(PERMISSIONS.ROLES.childrens[0])
    ) {
      pagesSection.push({
        href: "/settings/roles-permissions",
        icon: CheckSquare,
        title: "Roles & Permissions",
      });
    }

    if (
      isPremium ||
      savedPermissions.includes(PERMISSIONS.SPACES.childrens[0])
    ) {
      pagesSection.push({
        href: "/settings/space-management",
        icon: Map,
        title: "Space Management",
      });
    }

    setNavItems([
      {
        title: "Getting Started",
        pages: pagesSection,
      },
    ]);
  }, [
    isInitialized,
    isAuthenticated,
    isPremium,
    savedPermissions,
    regularSettingsChildren,
  ]);

  return navItems;
};

export default useSettingsItems;
