import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Grid,
  Button,
  Alert,
  Link as MuiLink,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";

import useAuth from "../../hooks/useAuth";
import { useSavedPermissions } from "../../utils/permissionUtils";
import { PERMISSIONS } from "../../utils/permissionConstants";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BusinessSettings() {
  const [value, setValue] = useState(0);
  const { user } = useAuth();
  const savedPermissions = useSavedPermissions();
  const isPremium = user?.subscriptionLevel === "1";

  // Permission checks for each tab using the new SETTINGS structure
  const canViewActivity = isPremium || savedPermissions.includes("ACTIVITY");
  const canViewSubscription =
    isPremium || savedPermissions.includes("SUBSCRIPTION");
  const canViewUsage = isPremium || savedPermissions.includes("USAGE");
  const canViewBilling = isPremium || savedPermissions.includes("BILLING");

  // Check if user has access to any of the business settings tabs
  const hasBusinessSettingsAccess =
    isPremium ||
    savedPermissions.includes(PERMISSIONS.SETTINGS.name) ||
    canViewActivity ||
    canViewSubscription ||
    canViewUsage ||
    canViewBilling;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Adjust selected tab if current tab is not accessible
  useEffect(() => {
    const accessibleTabs = [
      canViewActivity,
      canViewSubscription,
      canViewUsage,
      canViewBilling,
    ];

    if (!accessibleTabs[value]) {
      // Find first accessible tab
      const firstAccessibleTabIndex = accessibleTabs.findIndex(
        (access) => access
      );
      if (firstAccessibleTabIndex !== -1) {
        setValue(firstAccessibleTabIndex);
      }
    }
  }, [
    canViewActivity,
    canViewSubscription,
    canViewUsage,
    canViewBilling,
    value,
  ]);

  if (!hasBusinessSettingsAccess) {
    return (
      <React.Fragment>
        <Helmet title="Business Settings" />
        <Typography variant="h3" gutterBottom>
          Business Settings
        </Typography>
        <Alert severity="warning">
          You don't have permission to view Business Settings.
        </Alert>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Helmet title="Business Settings" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Business Settings
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <NavLink to="/private">Spaces</NavLink>
            <MuiLink component="span" color="text.secondary">
              Settings
            </MuiLink>
            <Typography>Business Settings</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/private"
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="business settings tabs"
          >
            {canViewActivity && <Tab label="Activity" {...a11yProps(0)} />}
            {canViewSubscription && (
              <Tab label="Subscription" {...a11yProps(1)} />
            )}
            {canViewUsage && <Tab label="Usage" {...a11yProps(2)} />}
            {canViewBilling && <Tab label="Billing" {...a11yProps(3)} />}
          </Tabs>
        </Box>

        {canViewActivity && (
          <CustomTabPanel value={value} index={canViewActivity ? 0 : -1}>
            Activity Content
          </CustomTabPanel>
        )}

        {canViewSubscription && (
          <CustomTabPanel value={value} index={getTabIndex(0, canViewActivity)}>
            Subscription Content
          </CustomTabPanel>
        )}

        {canViewUsage && (
          <CustomTabPanel
            value={value}
            index={getTabIndex(1, canViewActivity, canViewSubscription)}
          >
            Usage Content
          </CustomTabPanel>
        )}

        {canViewBilling && (
          <CustomTabPanel
            value={value}
            index={getTabIndex(
              2,
              canViewActivity,
              canViewSubscription,
              canViewUsage
            )}
          >
            Billing Content
          </CustomTabPanel>
        )}
      </Box>
    </React.Fragment>
  );
}

// Helper function to calculate the correct tab index based on which tabs are visible
function getTabIndex(baseIndex, ...precedingTabsVisible) {
  return precedingTabsVisible.filter(Boolean).length > 0
    ? baseIndex
    : baseIndex - precedingTabsVisible.filter((tab) => !tab).length;
}
