import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import {
  Calendar,
  Check,
  Clock,
  Video,
  Globe,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";

// Import axios instance
import { axiosInstance } from "../../../utils/axios.js";

function EventTypePreview() {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [availableSlots, setAvailableSlots] = useState([]);
  const [eventTypeData, setEventTypeData] = useState(null);
  const [availabilityData, setAvailabilityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timeFormat, setTimeFormat] = useState("12h"); // 12h or 24h
  const [selectedTime, setSelectedTime] = useState(null);
  const [viewMode, setViewMode] = useState("month"); // month, week, day
  const [eventTypeId, setEventTypeId] = useState(null);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "", notes: "" });
  const [successMessage, setSuccessMessage] = useState("");
  const [existingBookings, setExistingBookings] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bookingLockId, setBookingLockId] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(5 * 60); // 5 minutes in seconds
  const [timerActive, setTimerActive] = useState(false);
  const [lockedSlots, setLockedSlots] = useState([]);
  const timerIntervalRef = useRef(null);

  // Common styles with light theme
  const styles = {
    container: {
      minHeight: "100vh",
      backgroundColor: "#f5f5f5",
      color: "#333333",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "32px",
      fontFamily: "Arial, sans-serif",
    },
    card: {
      width: "1500px",
      maxWidth: "1300px",
      backgroundColor: "#ffffff",
      borderRadius: "12px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      overflow: "hidden",
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
      borderBottom: "1px solid #e5e7eb",
    },
    sidebar: {
      width: "25%",
      backgroundColor: "#ffffff",
      padding: "24px",
      borderRight: "1px solid #e5e7eb",
    },
    main: {
      width: "50%",
      padding: "24px",
      borderRight: "1px solid #e5e7eb",
    },
    timeline: {
      width: "25%",
      padding: "24px",
      backgroundColor: "#fafafa",
    },
    profileContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: "16px",
    },
    avatar: {
      width: "32px",
      height: "32px",
      backgroundColor: "#3b82f6",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#ffffff",
      fontSize: "14px",
      marginRight: "12px",
    },
    ownerName: {
      color: "#6b7280",
      fontSize: "14px",
    },
    eventTitle: {
      color: "#111827",
      fontSize: "20px",
      fontWeight: "bold",
    },
    eventDescription: {
      color: "#6b7280",
      fontSize: "14px",
    },
    detailsContainer: {
      marginTop: "32px",
    },
    detailItem: {
      display: "flex",
      alignItems: "center",
      marginBottom: "16px",
      fontSize: "14px",
      color: "#4b5563",
    },
    icon: {
      marginRight: "8px",
      color: "#6b7280",
    },
    headerRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "24px",
    },
    dateDisplay: {
      color: "#111827",
      fontSize: "18px",
      fontWeight: "500",
    },
    viewToggle: {
      display: "flex",
      border: "1px solid #e5e7eb",
      borderRadius: "6px",
      overflow: "hidden",
    },
    viewButton: {
      padding: "6px 12px",
      backgroundColor: "#ffffff",
      color: "#6b7280",
      border: "none",
      cursor: "pointer",
    },
    viewButtonActive: {
      backgroundColor: "#3b82f6",
      color: "#ffffff",
    },
    timeFormatToggle: {
      display: "flex",
      border: "1px solid #e5e7eb",
      borderRadius: "6px",
      overflow: "hidden",
      marginLeft: "8px",
    },
    timeFormatButton: {
      padding: "6px 12px",
      backgroundColor: "#ffffff",
      color: "#6b7280",
      border: "none",
      cursor: "pointer",
    },
    timeFormatButtonActive: {
      backgroundColor: "#f3f4f6",
      color: "#111827",
    },
    calendarContainer: {
      marginBottom: "24px",
    },
    timeSlotsContainer: {
      border: "1px solid #e5e7eb",
      borderRadius: "8px",
      padding: "16px",
      height: "400px",
      overflowY: "auto",
    },
    monthHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "16px",
    },
    monthTitle: {
      color: "#111827",
      fontSize: "16px",
      fontWeight: "500",
    },
    navButton: {
      backgroundColor: "#f9fafb",
      border: "1px solid #e5e7eb",
      cursor: "pointer",
      color: "#6b7280",
      width: "28px",
      height: "28px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "4px",
    },
    weekdaysGrid: {
      display: "grid",
      gridTemplateColumns: "repeat(7, 1fr)",
      gap: "4px",
      textAlign: "center",
    },
    weekday: {
      color: "#6b7280",
      fontSize: "12px",
      fontWeight: "500",
      padding: "8px 0",
    },
    daysGrid: {
      display: "grid",
      gridTemplateColumns: "repeat(7, 1fr)",
      gap: "4px",
    },
    dayCell: {
      height: "36px",
      position: "relative",
    },
    dayButton: {
      width: "100%",
      height: "100%",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "14px",
      border: "none",
      background: "transparent",
      color: "#111827",
      cursor: "pointer",
    },
    dayButtonSelected: {
      backgroundColor: "#3b82f6",
      color: "#ffffff",
    },
    dayButtonToday: {
      border: "1px solid #3b82f6",
      fontWeight: "bold",
    },
    dayButtonUnavailable: {
      color: "#d1d5db",
      cursor: "not-allowed",
    },
    timeSlotsList: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    timeSlotButton: {
      width: "100%",
      padding: "10px 0",
      textAlign: "center",
      borderRadius: "4px",
      backgroundColor: "#f9fafb",
      color: "#111827",
      fontSize: "14px",
      border: "1px solid #e5e7eb",
      cursor: "pointer",
    },
    timeSlotButtonSelected: {
      backgroundColor: "#3b82f6",
      color: "#ffffff",
      border: "1px solid #3b82f6",
    },
    noSlots: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      color: "#9ca3af",
    },
    timelineHeader: {
      marginBottom: "16px",
      fontSize: "16px",
      fontWeight: "500",
      color: "#111827",
    },
    timelineEvent: {
      padding: "12px",
      backgroundColor: "#f3f4f6",
      borderRadius: "6px",
      marginBottom: "12px",
    },
    timelineEventTime: {
      fontSize: "14px",
      fontWeight: "500",
      color: "#111827",
      marginBottom: "4px",
    },
    timelineEventTitle: {
      fontSize: "14px",
      color: "#4b5563",
    },
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#f5f5f5",
    },
    spinner: {
      width: "48px",
      height: "48px",
      border: "4px solid rgba(59, 130, 246, 0.1)",
      borderTopColor: "#3b82f6",
      borderRadius: "50%",
      animation: "spin 1s linear infinite",
    },
    footer: {
      padding: "16px",
      borderTop: "1px solid #e5e7eb",
      textAlign: "center",
    },
    footerLink: {
      color: "#6b7280",
      fontSize: "14px",
      textDecoration: "none",
    },
    errorContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "40px",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      maxWidth: "500px",
      margin: "100px auto",
    },
    errorTitle: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#ef4444",
      marginBottom: "16px",
    },
    errorMessage: {
      fontSize: "16px",
      color: "#4b5563",
      textAlign: "center",
      marginBottom: "20px",
    },
    errorButton: {
      padding: "8px 16px",
      backgroundColor: "#3b82f6",
      color: "#ffffff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "14px",
    },
    formInputLabel: {
      display: "block",
      marginBottom: "6px",
      fontSize: "14px",
      fontWeight: "500",
      color: "#4b5563",
    },
    formInput: {
      width: "100%",
      padding: "8px 12px",
      border: "1px solid #d1d5db",
      borderRadius: "4px",
      fontSize: "14px",
    },
    formTextarea: {
      width: "100%",
      padding: "8px 12px",
      border: "1px solid #d1d5db",
      borderRadius: "4px",
      fontSize: "14px",
      minHeight: "100px",
      resize: "vertical",
    },
    formSuccess: {
      padding: "12px",
      backgroundColor: "#ecfdf5",
      color: "#047857",
      borderRadius: "4px",
      marginBottom: "16px",
    },
    modalButtonSecondary: {
      padding: "8px 16px",
      backgroundColor: "#f9fafb",
      color: "#4b5563",
      border: "1px solid #d1d5db",
      borderRadius: "4px",
      fontSize: "14px",
      cursor: "pointer",
    },
    modalButtonPrimary: {
      padding: "8px 16px",
      backgroundColor: "#3b82f6",
      color: "#ffffff",
      border: "none",
      borderRadius: "4px",
      fontSize: "14px",
      cursor: "pointer",
    },
    responsiveWrapper: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      "@media (max-width: 768px)": {
        flexDirection: "column",
      },
    },
    // New styles for loading state
    loadingOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 10,
      borderRadius: "8px",
    },
    loadingSpinner: {
      width: "40px",
      height: "40px",
      border: "4px solid rgba(59, 130, 246, 0.1)",
      borderTopColor: "#3b82f6",
      borderRadius: "50%",
      animation: "spin 1s linear infinite",
    },
    loadingText: {
      marginLeft: "16px",
      fontSize: "16px",
      color: "#3b82f6",
      fontWeight: 500,
    },
    loadingMessage: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    disabledButton: {
      backgroundColor: "#9cb3f5",
      cursor: "not-allowed",
    },
    successIcon: {
      marginBottom: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "48px",
      height: "48px",
      backgroundColor: "#ecfdf5",
      borderRadius: "50%",
      color: "#10b981",
    },
    // New styles for timer
    timerContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "16px",
      padding: "8px 16px",
      backgroundColor:
        timerActive && timeRemaining < 60 ? "#fee2e2" : "#f3f4f6",
      borderRadius: "4px",
      border:
        timerActive && timeRemaining < 60
          ? "1px solid #ef4444"
          : "1px solid #e5e7eb",
    },
    timerIcon: {
      marginRight: "8px",
      color: timerActive && timeRemaining < 60 ? "#ef4444" : "#6b7280",
    },
    timerText: {
      fontSize: "14px",
      fontWeight: "500",
      color: timerActive && timeRemaining < 60 ? "#ef4444" : "#6b7280",
    },
    timerWarning: {
      color: "#ef4444",
      fontWeight: "bold",
    },
    timeSlotButtonLocked: {
      backgroundColor: "#f3f4f6",
      color: "#9ca3af",
      borderColor: "#e5e7eb",
      cursor: "not-allowed",
    },
  };

  // Check for locked time slots
  const fetchLockedTimeSlots = async () => {
    if (!eventTypeId || !selectedDate) return;

    try {
      const formattedDate = selectedDate.format("YYYY-MM-DD");

      const response = await axiosInstance.post("/graphql", {
        query: `
        query GetLockedTimeSlots($eventTypeId: ID!, $date: String!) {
          getLockedTimeSlots(eventTypeId: $eventTypeId, date: $date) {
            timeStart
            lockId
            expiresAt
          }
        }
      `,
        variables: {
          eventTypeId,
          date: formattedDate,
        },
      });

      if (response?.data?.data?.getLockedTimeSlots) {
        setLockedSlots(response.data.data.getLockedTimeSlots);
      } else if (response?.data?.getLockedTimeSlots) {
        setLockedSlots(response.data.getLockedTimeSlots);
      }
    } catch (error) {
      console.error("Error fetching locked time slots:", error);
    }
  };

  // Lock a time slot when selected
  const lockTimeSlot = async (timeSlot) => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: `
        mutation LockTimeSlot($input: LockTimeSlotInput!) {
          lockTimeSlot(input: $input) {
            lockId
            timeStart
            expiresAt
          }
        }
      `,
        variables: {
          input: {
            eventTypeId,
            date: selectedDate.format("YYYY-MM-DD"),
            timeStart: timeSlot,
          },
        },
      });

      const lockData =
        response?.data?.data?.lockTimeSlot || response?.data?.lockTimeSlot;

      if (lockData) {
        setBookingLockId(lockData.lockId);

        // Calculate time remaining from expiresAt
        const expiresAt = new Date(lockData.expiresAt).getTime();
        const now = new Date().getTime();
        const remainingTime = Math.floor((expiresAt - now) / 1000);

        setTimeRemaining(remainingTime > 0 ? remainingTime : 5 * 60);
        setTimerActive(true);

        // Refresh locked slots
        fetchLockedTimeSlots();
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error locking time slot:", error);
      return false;
    }
  };

  // Release a time slot lock
  const releaseTimeSlotLock = async () => {
    if (!bookingLockId) return;

    try {
      await axiosInstance.post("/graphql", {
        query: `
        mutation ReleaseTimeSlotLock($lockId: ID!) {
          releaseTimeSlotLock(lockId: $lockId)
        }
      `,
        variables: {
          lockId: bookingLockId,
        },
      });

      setBookingLockId(null);
      setTimerActive(false);

      // Refresh locked slots
      fetchLockedTimeSlots();
    } catch (error) {
      console.error("Error releasing time slot lock:", error);
    }
  };

  // Format time for display
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  // Start countdown timer
  const startTimer = () => {
    // Clear any existing timer
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
    }

    setTimerActive(true);

    timerIntervalRef.current = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1) {
          // Time's up - clear interval and close modal
          clearInterval(timerIntervalRef.current);
          releaseTimeSlotLock();
          setOpenModal(false);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  // Render the timer component
  const renderTimer = () => {
    if (!timerActive) return null;

    return (
      <div style={styles.timerContainer}>
        <br />
        <Clock size={16} style={styles.timerIcon} />
        <span style={styles.timerText}>
          Time remaining: {formatTime(timeRemaining)}
          {timeRemaining < 60 && (
            <span style={styles.timerWarning}> (Almost expired!)</span>
          )}
        </span>
      </div>
    );
  };

  // Clean up timer on unmount
  useEffect(() => {
    return () => {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
    };
  }, []);

  // Generate time slots based on availability
  function generateTimeSlots(timeStart, timeEnd, interval = 30) {
    const slots = [];
    let startTime;
    let endTime;

    // Handle 12-hour format strings like "09:00 am" or "06:00 pm"
    if (
      typeof timeStart === "string" &&
      (timeStart.includes("am") || timeStart.includes("pm"))
    ) {
      const [timePart, meridiem] = timeStart.split(" ");
      const [hours, minutes] = timePart.split(":").map(Number);
      let hour24 = hours;

      if (meridiem.toLowerCase() === "pm" && hours < 12) {
        hour24 = hours + 12;
      } else if (meridiem.toLowerCase() === "am" && hours === 12) {
        hour24 = 0;
      }

      startTime = dayjs().hour(hour24).minute(minutes).second(0);
    } else {
      startTime = dayjs(`2000-01-01 ${timeStart}`);
    }

    if (
      typeof timeEnd === "string" &&
      (timeEnd.includes("am") || timeEnd.includes("pm"))
    ) {
      const [timePart, meridiem] = timeEnd.split(" ");
      const [hours, minutes] = timePart.split(":").map(Number);
      let hour24 = hours;

      if (meridiem.toLowerCase() === "pm" && hours < 12) {
        hour24 = hours + 12;
      } else if (meridiem.toLowerCase() === "am" && hours === 12) {
        hour24 = 0;
      }

      endTime = dayjs().hour(hour24).minute(minutes).second(0);
    } else {
      endTime = dayjs(`2000-01-01 ${timeEnd}`);
    }

    let currentTime = startTime.clone();
    while (currentTime.isBefore(endTime)) {
      slots.push({
        timeStart: currentTime.format(timeFormat === "12h" ? "h:mma" : "HH:mm"),
        timeEnd: currentTime
          .add(interval, "minute")
          .format(timeFormat === "12h" ? "h:mma" : "HH:mm"),
      });
      // Move to the next slot
      currentTime = startTime.add(slots.length * interval, "minute");
    }

    return slots;
  }

  const fetchExistingBookings = async (date) => {
    if (!eventTypeId) return;

    try {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");

      const response = await axiosInstance.post("/graphql", {
        query: `
        query GetExistingBookings($eventTypeId: ID!, $date: String!) {
          getBookingsForEventAndDate(eventTypeId: $eventTypeId, date: $date) {
            id
            date
            timeStart
            timeEnd
          }
        }
      `,
        variables: {
          eventTypeId,
          date: formattedDate,
        },
      });

      if (response?.data?.data?.getBookingsForEventAndDate) {
        console.log(
          "Fetched bookings:",
          response.data.data.getBookingsForEventAndDate
        );
        setExistingBookings(response.data.data.getBookingsForEventAndDate);
      } else if (response?.data?.getBookingsForEventAndDate) {
        console.log(
          "Fetched bookings:",
          response.data.getBookingsForEventAndDate
        );
        setExistingBookings(response.data.getBookingsForEventAndDate);
      }
    } catch (error) {
      console.error("Error fetching existing bookings:", error);
    }
  };

  function getAvailableSlots(availabilityData, selectedDate, eventDuration) {
    if (!availabilityData || !availabilityData.days) return [];

    const dayOfWeek = selectedDate.format("dddd");
    const selectedDateStr = selectedDate.format("YYYY-MM-DD");
    const interval = parseInt(eventDuration || 30);

    // Check for an override
    const override = availabilityData.overrides?.find(
      (o) => dayjs(o.date).format("YYYY-MM-DD") === selectedDateStr
    );

    let timeSlots = [];

    if (override && !override.isUnavailable && override.timeSlots?.length > 0) {
      // Generate time slots for each entry in override.timeSlots
      override.timeSlots.forEach((slot) => {
        const slots = generateTimeSlots(slot.timeStart, slot.timeEnd, interval);
        timeSlots = [...timeSlots, ...slots];
      });
    } else if (
      availabilityData.days?.includes(dayOfWeek) &&
      availabilityData.timeSlots?.length > 0
    ) {
      // Find the time slot for this day
      const dayIndex = availabilityData.days.indexOf(dayOfWeek);
      const dayTimeSlot =
        availabilityData.timeSlots[
          Math.min(dayIndex, availabilityData.timeSlots.length - 1)
        ];

      if (dayTimeSlot) {
        timeSlots = generateTimeSlots(
          dayTimeSlot.timeStart,
          dayTimeSlot.timeEnd,
          interval
        );
      }
    }

    // Filter out locked slots that aren't ours
    if (lockedSlots && lockedSlots.length > 0) {
      timeSlots = timeSlots.filter((slot) => {
        const slotTimeStart = slot.timeStart.toLowerCase();

        // Check if this slot is locked by someone else
        const matchingLock = lockedSlots.find((lock) => {
          return (
            lock.timeStart.toLowerCase() === slotTimeStart &&
            lock.lockId !== bookingLockId
          );
        });

        return !matchingLock;
      });
    }

    // Filter out already booked slots
    if (existingBookings && existingBookings.length > 0) {
      timeSlots = timeSlots.filter((slot) => {
        // Convert to comparable format
        const slotTimeStart =
          timeFormat === "24h"
            ? dayjs(`2000-01-01 ${slot.timeStart}`)
                .format("h:mma")
                .toLowerCase()
            : slot.timeStart.toLowerCase();

        // Check if this slot is already booked
        return !existingBookings.some((booking) => {
          const bookingStart = booking.timeStart.toLowerCase();
          return bookingStart === slotTimeStart;
        });
      });
    }

    return timeSlots;
  }

  // Navigate to previous month
  const prevMonth = () => {
    setSelectedDate(selectedDate.subtract(1, "month"));
  };

  // Navigate to next month
  const nextMonth = () => {
    setSelectedDate(selectedDate.add(1, "month"));
  };

  // Select a time slot with lock functionality
  const selectTime = async (time) => {
    const lockSuccess = await lockTimeSlot(time);

    if (lockSuccess) {
      setSelectedTime(time);
      setOpenModal(true);
      startTimer();
    } else {
      alert(
        "This time slot is no longer available. Please select another time."
      );
      // Refresh available slots
      fetchExistingBookings(selectedDate);
      fetchLockedTimeSlots();
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmitBooking = () => {
    // Validate form data
    if (!formData.name || !formData.email) {
      alert("Please fill in your name and email");
      return;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      alert("Please enter a valid email address");
      return;
    }

    // Set loading state to true
    setIsSubmitting(true);

    // Fix for parsing time and calculating end time correctly
    let endTime;
    const duration = eventTypeData?.duration || 30;

    // Parse the time from the 12h format (e.g., "11:00am")
    if (
      selectedTime.toLowerCase().includes("am") ||
      selectedTime.toLowerCase().includes("pm")
    ) {
      const timeStr = selectedTime.toLowerCase();
      const isPM = timeStr.includes("pm");

      // Extract hours and minutes
      let [hourMin, _] = timeStr.split(/am|pm/i);
      let [hours, minutes] = hourMin
        .split(":")
        .map((num) => parseInt(num.trim(), 10));

      // Adjust for PM if needed (except for 12 PM)
      if (isPM && hours !== 12) {
        hours += 12;
      }
      // Adjust for 12 AM
      if (!isPM && hours === 12) {
        hours = 0;
      }

      // Create a dayjs object and add duration
      const timeObj = dayjs().hour(hours).minute(minutes).second(0);
      const endTimeObj = timeObj.add(duration, "minute");

      // Format based on timeFormat setting
      endTime = endTimeObj.format(timeFormat === "12h" ? "h:mma" : "HH:mm");
    } else {
      // For 24h format (e.g., "14:00")
      const [hours, minutes] = selectedTime.split(":").map(Number);
      const timeObj = dayjs().hour(hours).minute(minutes).second(0);
      const endTimeObj = timeObj.add(duration, "minute");
      endTime = endTimeObj.format(timeFormat === "12h" ? "h:mma" : "HH:mm");
    }

    const bookingData = {
      eventTypeId: eventTypeId,
      date: selectedDate.format("YYYY-MM-DD"),
      timeStart: selectedTime,
      timeEnd: endTime,
      name: formData.name,
      email: formData.email,
      notes: formData.notes,
      lockId: bookingLockId,
    };
    // Use axiosInstance to save booking to database using GraphQL
    axiosInstance
      .post("/graphql", {
        query: `
        mutation CreateBooking($input: BookingInput!) {
          createBooking(input: $input) {
            id
            date
            timeStart
            timeEnd
            name
            email
            notes
            eventTypeId
          }
        }
      `,
        variables: {
          input: bookingData,
        },
      })
      .then((response) => {
        setIsSubmitting(false);

        // Stop the timer
        if (timerIntervalRef.current) {
          clearInterval(timerIntervalRef.current);
        }
        setTimerActive(false);

        if (
          response.data?.data?.createBooking ||
          response.data?.createBooking
        ) {
          setSuccessMessage("Your booking was saved successfully!");
          setFormData({ name: "", email: "", notes: "" });
          fetchExistingBookings(selectedDate);
          fetchLockedTimeSlots();
        } else {
          console.error("Booking error:", response.data);
          setSuccessMessage("Failed to save your booking. Please try again.");

          // Release the lock
          releaseTimeSlotLock();
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.error("Error booking slot", error);
        setSuccessMessage("Failed to save your booking. Please try again.");

        // Release the lock
        releaseTimeSlotLock();
      });
  };

  // Parse eventTypeId from URL on component mount
  useEffect(() => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get("eventTypeId");

      if (!id) {
        setError(
          "No event type ID provided. Please use a valid event type link."
        );
        setLoading(false);
        return;
      }

      setEventTypeId(id);
    } catch (error) {
      console.error("Error parsing URL parameters:", error);
      setError("Invalid URL parameters. Please use a valid event type link.");
      setLoading(false);
    }
  }, []);

  // Fetch event type and availability data when eventTypeId is available
  useEffect(() => {
    if (!eventTypeId) return;

    const fetchData = async () => {
      try {
        console.log("Fetching event type with ID:", eventTypeId);

        // Fetch event type data using the eventTypeId from URL
        const response = await axiosInstance.post("/graphql", {
          query: `
          query GetEventTypePublic($id: ID!) {
            getEventTypePublic(id: $id) {
              id
              title
              url
              description
              duration
              assignment
              availabilityId
              owner {
                id
                name
                companyName
              }
              advanced {
                requiresConfirmation {
                  enabled
                }
              }
            }
          }
        `,
          variables: {
            id: eventTypeId,
          },
        });

        console.log("Full GraphQL response:", response);
        console.log("Response data structure:", JSON.stringify(response.data));

        // Check all possible locations of the getEventType data
        let eventData = null;

        // Option 1: Standard Apollo structure
        if (response.data?.data?.getEventTypePublic) {
          eventData = response.data.data.getEventTypePublic;
          console.log("Found data in response.data.data.getEventType");
        }
        // Option 2: Direct data structure
        else if (response.data?.getEventTypePublic) {
          eventData = response.data.getEventTypePublic;
          console.log("Found data in response.data.getEventType");
        }
        // Option 3: Directly in response
        else if (response?.getEventType) {
          eventData = response.getEventType;
          console.log("Found data in response.getEventType");
        }

        if (eventData) {
          console.log("Event data found:", eventData);

          // Set event data
          setEventTypeData({
            id: eventData.id,
            title: eventData.title,
            owner: eventData.owner?.name || "Event Owner",
            ownerAvatar: null,
            url: eventData.url,
            description: eventData.description,
            duration: eventData.duration,
            assignment: eventData.assignment,
            availabilityId: eventData.availabilityId,
            advanced: eventData.advanced,
          });

          // Now fetch availability data using the availabilityId from event type
          if (eventData.availabilityId) {
            try {
              const availResponse = await axiosInstance.post("/graphql", {
                query: `
                query GetAvailability($id: ID!) {
                  getAvailability(id: $id) {
                    id
                    name
                    timezone
                    days
                    timeSlots {
                      timeStart
                      timeEnd
                    }
                    overrides {
                      date
                      timeSlots {
                        timeStart
                        timeEnd
                      }
                      isUnavailable
                    }
                  }
                }
              `,
                variables: {
                  id: eventData.availabilityId,
                },
              });

              // Similar approach to handle different response structures
              let availabilityData = null;

              if (availResponse.data?.data?.getAvailability) {
                availabilityData = availResponse.data.data.getAvailability;
              } else if (availResponse.data?.getAvailability) {
                availabilityData = availResponse.data.getAvailability;
              }

              if (availabilityData) {
                setAvailabilityData(availabilityData);
              } else {
                // Fallback to default availability
                setAvailabilityData({
                  name: "Default Availability",
                  timezone: "UTC",
                  days: [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                  ],
                  timeSlots: [{ timeStart: "09:00", timeEnd: "17:00" }],
                });
              }
            } catch (error) {
              console.error("Error fetching availability:", error);
              // Fallback to default availability
              setAvailabilityData({
                name: "Default Availability",
                timezone: "UTC",
                days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                timeSlots: [{ timeStart: "09:00", timeEnd: "17:00" }],
              });
            }
          } else {
            // Fallback to default availability if no availabilityId is provided
            setAvailabilityData({
              name: "Default Availability",
              timezone: "UTC",
              days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
              timeSlots: [{ timeStart: "09:00", timeEnd: "17:00" }],
            });
          }
        } else {
          console.error("No event data found in response:", response.data);
          setError(
            "Event type not found. Please check the event ID and try again."
          );
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(
          "An error occurred while loading event data. Please try again later."
        );
        setLoading(false);
      }
    };

    fetchData();
  }, [eventTypeId]);

  useEffect(() => {
    if (availabilityData && selectedDate) {
      // Fetch existing bookings for the selected date
      fetchExistingBookings(selectedDate);
      fetchLockedTimeSlots();
    }
  }, [selectedDate, eventTypeId]);

  useEffect(() => {
    if (availabilityData) {
      const slots = getAvailableSlots(
        availabilityData,
        selectedDate,
        eventTypeData?.duration
      );
      setAvailableSlots(slots);
    }
  }, [
    selectedDate,
    availabilityData,
    eventTypeData,
    timeFormat,
    existingBookings,
  ]);

  const renderMonthView = () => {
    const daysInMonth = selectedDate.daysInMonth();
    const firstDay = selectedDate.startOf("month").day(); // 0 = Sunday, 1 = Monday, etc.
    const days = [];
    const weekdays = ["S", "M", "T", "W", "T", "F", "S"];

    // Create days for current month
    for (let i = 1; i <= daysInMonth; i++) {
      const date = selectedDate.date(i);
      const isToday =
        date.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD");
      const isSelected =
        date.format("YYYY-MM-DD") === selectedDate.format("YYYY-MM-DD");
      const dayAvailable = availabilityData?.days?.includes(
        date.format("dddd")
      );

      days.push({
        date: i,
        isCurrentMonth: true,
        isToday,
        isSelected,
        isAvailable: dayAvailable,
        fullDate: date,
      });
    }

    // Add empty cells for days before the first day of the month
    const emptyCellsBefore = [];
    for (let i = 0; i < firstDay; i++) {
      emptyCellsBefore.push({
        date: null,
        isCurrentMonth: false,
      });
    }

    // Combine the empty cells and the days of the month
    const allDays = [...emptyCellsBefore, ...days];

    return (
      <div>
        <div style={styles.monthHeader}>
          <button onClick={prevMonth} style={styles.navButton}>
            <ChevronLeft size={16} />
          </button>
          <h2 style={styles.monthTitle}>{selectedDate.format("MMMM YYYY")}</h2>
          <button onClick={nextMonth} style={styles.navButton}>
            <ChevronRight size={16} />
          </button>
        </div>

        <div style={styles.weekdaysGrid}>
          {weekdays.map((day, index) => (
            <div key={index} style={styles.weekday}>
              {day}
            </div>
          ))}
        </div>

        <div style={styles.daysGrid}>
          {allDays.map((day, index) => (
            <div key={index} style={styles.dayCell}>
              {day.date && (
                <button
                  style={{
                    ...styles.dayButton,
                    ...(day.isSelected ? styles.dayButtonSelected : {}),
                    ...(day.isToday && !day.isSelected
                      ? styles.dayButtonToday
                      : {}),
                    ...(!day.isAvailable ? styles.dayButtonUnavailable : {}),
                  }}
                  onClick={() =>
                    day.isAvailable && setSelectedDate(day.fullDate)
                  }
                  disabled={!day.isAvailable}
                >
                  {day.date}
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderWeekView = () => {
    // Get start and end of the current week
    const startOfWeek = selectedDate.startOf("week");
    const weekdays = [];

    // Generate weekdays
    for (let i = 0; i < 7; i++) {
      const date = startOfWeek.add(i, "day");
      const isToday =
        date.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD");
      const isSelected =
        date.format("YYYY-MM-DD") === selectedDate.format("YYYY-MM-DD");
      const dayAvailable = availabilityData?.days?.includes(
        date.format("dddd")
      );

      weekdays.push({
        date,
        isToday,
        isSelected,
        isAvailable: dayAvailable,
      });
    }

    return (
      <div>
        <div style={styles.monthHeader}>
          <button
            onClick={() => setSelectedDate(selectedDate.subtract(1, "week"))}
            style={styles.navButton}
          >
            <ChevronLeft size={16} />
          </button>
          <h2 style={styles.monthTitle}>
            {startOfWeek.format("MMM D")} -{" "}
            {startOfWeek.add(6, "day").format("MMM D, YYYY")}
          </h2>
          <button
            onClick={() => setSelectedDate(selectedDate.add(1, "week"))}
            style={styles.navButton}
          >
            <ChevronRight size={16} />
          </button>
        </div>

        <div style={{ display: "flex", gap: "6px", marginBottom: "12px" }}>
          {weekdays.map((day, index) => (
            <div key={index} style={{ flex: 1, textAlign: "center" }}>
              <div
                style={{
                  fontSize: "12px",
                  color: "#6b7280",
                  marginBottom: "4px",
                }}
              >
                {day.date.format("ddd")}
              </div>
              <button
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "14px",
                  border:
                    day.isToday && !day.isSelected
                      ? "1px solid #3b82f6"
                      : "none",
                  background: day.isSelected ? "#3b82f6" : "transparent",
                  color: day.isSelected
                    ? "#ffffff"
                    : day.isAvailable
                    ? "#111827"
                    : "#d1d5db",
                  cursor: day.isAvailable ? "pointer" : "not-allowed",
                  fontWeight: day.isToday ? "bold" : "normal",
                }}
                onClick={() => day.isAvailable && setSelectedDate(day.date)}
                disabled={!day.isAvailable}
              >
                {day.date.format("D")}
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderDayView = () => {
    return (
      <div>
        <div style={styles.monthHeader}>
          <button
            onClick={() => setSelectedDate(selectedDate.subtract(1, "day"))}
            style={styles.navButton}
          >
            <ChevronLeft size={16} />
          </button>
          <h2 style={styles.monthTitle}>
            {selectedDate.format("dddd, MMMM D, YYYY")}
          </h2>
          <button
            onClick={() => setSelectedDate(selectedDate.add(1, "day"))}
            style={styles.navButton}
          >
            <ChevronRight size={16} />
          </button>
        </div>

        <div
          style={{
            padding: "12px",
            backgroundColor: "#f9fafb",
            borderRadius: "8px",
            textAlign: "center",
          }}
        >
          <p style={{ color: "#6b7280", fontSize: "14px" }}>
            {availabilityData?.days?.includes(selectedDate.format("dddd"))
              ? `Available ${availabilityData?.timeSlots?.[0]?.timeStart} - ${availabilityData?.timeSlots?.[0]?.timeEnd}`
              : "Not available today"}
          </p>
        </div>
      </div>
    );
  };

  // Render error state
  if (error) {
    return (
      <div style={styles.container}>
        <div style={styles.errorContainer}>
          <h3 style={styles.errorTitle}>Error</h3>
          <p style={styles.errorMessage}>{error}</p>
          <button
            style={styles.errorButton}
            onClick={() => (window.location.href = "/")}
          >
            Return to Homepage
          </button>
        </div>
      </div>
    );
  }

  // Render loading state
  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <div style={styles.spinner}></div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.flexRow}>
          {/* Left sidebar with event details */}
          <div style={styles.sidebar}>
            {/* User profile section */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "24px",
              }}
            >
              {/* Avatar and name in one row */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    width: "36px",
                    height: "36px",
                    backgroundColor: "#3b82f6",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#ffffff",
                    fontSize: "14px",
                    overflow: "hidden",
                  }}
                >
                  {eventTypeData?.ownerAvatar ? (
                    <img
                      src={eventTypeData.ownerAvatar}
                      alt={eventTypeData?.owner || "User"}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    eventTypeData?.owner?.charAt(0)?.toUpperCase() || "P"
                  )}
                </div>
                <span
                  style={{
                    marginLeft: "10px",
                    color: "#6b7280",
                    fontSize: "14px",
                  }}
                >
                  {eventTypeData?.owner || "User"}
                </span>
              </div>

              {/* Title as a separate block */}
              <h2
                style={{
                  color: "#111827",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  marginTop: "0",
                }}
              >
                {eventTypeData?.title}
              </h2>

              {/* Description as a separate block */}
              <div
                style={{
                  color: "#6b7280",
                  fontSize: "14px",
                  lineHeight: "1.6",
                }}
                dangerouslySetInnerHTML={{ __html: eventTypeData?.description }}
              />
            </div>

            {/* Details container */}
            <div style={{ marginTop: "24px" }}>
              {eventTypeData?.advanced?.requiresConfirmation?.enabled && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                    fontSize: "14px",
                    color: "#4b5563",
                  }}
                >
                  <Check
                    size={16}
                    style={{ marginRight: "8px", color: "#6b7280" }}
                  />
                  <span>Requires confirmation</span>
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                  fontSize: "14px",
                  color: "#4b5563",
                }}
              >
                <Clock
                  size={16}
                  style={{ marginRight: "8px", color: "#6b7280" }}
                />
                <span>{eventTypeData?.duration}m</span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                  fontSize: "14px",
                  color: "#4b5563",
                }}
              >
                <Video
                  size={16}
                  style={{ marginRight: "8px", color: "#6b7280" }}
                />
                <span>Video Call</span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                  fontSize: "14px",
                  color: "#4b5563",
                }}
              >
                <Globe
                  size={16}
                  style={{ marginRight: "8px", color: "#6b7280" }}
                />
                <span>{availabilityData?.timezone || "UTC"}</span>
              </div>
            </div>
          </div>

          {/* Main calendar section */}
          <div style={styles.main}>
            <div style={styles.headerRow}>
              <h2 style={styles.dateDisplay}>{selectedDate.format("ddd D")}</h2>
              <div style={{ display: "flex" }}>
                <div style={styles.viewToggle}>
                  <button
                    style={{
                      ...styles.viewButton,
                      ...(viewMode === "month" ? styles.viewButtonActive : {}),
                    }}
                    onClick={() => setViewMode("month")}
                  >
                    Month
                  </button>
                  <button
                    style={{
                      ...styles.viewButton,
                      ...(viewMode === "week" ? styles.viewButtonActive : {}),
                    }}
                    onClick={() => setViewMode("week")}
                  >
                    Week
                  </button>
                  <button
                    style={{
                      ...styles.viewButton,
                      ...(viewMode === "day" ? styles.viewButtonActive : {}),
                    }}
                    onClick={() => setViewMode("day")}
                  >
                    Day
                  </button>
                </div>

                <div style={styles.timeFormatToggle}>
                  <button
                    style={{
                      ...styles.timeFormatButton,
                      ...(timeFormat === "12h"
                        ? styles.timeFormatButtonActive
                        : {}),
                    }}
                    onClick={() => setTimeFormat("12h")}
                  >
                    12h
                  </button>
                  <button
                    style={{
                      ...styles.timeFormatButton,
                      ...(timeFormat === "24h"
                        ? styles.timeFormatButtonActive
                        : {}),
                    }}
                    onClick={() => setTimeFormat("24h")}
                  >
                    24h
                  </button>
                </div>
              </div>
            </div>

            <div style={styles.calendarContainer}>
              {viewMode === "month" && renderMonthView()}
              {viewMode === "week" && renderWeekView()}
              {viewMode === "day" && renderDayView()}
            </div>
          </div>

          {/* Right sidebar with time slots */}
          <div style={styles.timeline}>
            <h3 style={styles.timelineHeader}>Available Times</h3>

            <div style={styles.timeSlotsContainer}>
              {availableSlots.length > 0 ? (
                <div style={styles.timeSlotsList}>
                  {availableSlots.map((slot, index) => (
                    <button
                      key={index}
                      style={{
                        ...styles.timeSlotButton,
                        ...(selectedTime === slot.timeStart
                          ? styles.timeSlotButtonSelected
                          : {}),
                      }}
                      onClick={() => selectTime(slot.timeStart)}
                    >
                      {slot.timeStart}
                    </button>
                  ))}
                </div>
              ) : (
                <div style={styles.noSlots}>
                  <p>No available slots</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div style={styles.footer}>
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.footerLink}
          >
            Powered by Instaconnect.io
          </a>
        </div>
      </div>

      {/* Booking Modal with Loading State */}
      {openModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
            padding: "20px",
          }}
        >
          <div
            style={{
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.15)",
              width: "100%",
              maxWidth: "500px",
              overflow: "hidden",
              position: "relative", // Add this for absolute positioning of loading overlay
            }}
          >
            {/* Loading overlay */}
            {isSubmitting && (
              <div style={styles.loadingOverlay} className="loading-overlay">
                <div style={styles.loadingMessage}>
                  <div
                    style={styles.loadingSpinner}
                    className="booking-spinner"
                  ></div>
                  <p style={{ marginTop: "16px", color: "#4b5563" }}>
                    Creating your booking...
                  </p>
                </div>
              </div>
            )}

            <div
              style={{
                padding: "20px 24px",
                borderBottom: "1px solid #e5e7eb",
              }}
            >
              <h3
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#111827",
                  margin: 0,
                }}
              >
                {successMessage ? "Booking Confirmed" : "Confirm Your Booking"}
              </h3>
              {renderTimer()}
            </div>

            <div
              style={{
                padding: "24px",
              }}
            >
              {successMessage ? (
                <div style={{ textAlign: "center" }}>
                  <div style={styles.successIcon} className="success-icon">
                    <Check size={24} />
                  </div>
                  <div style={styles.formSuccess}>{successMessage}</div>
                  <p style={{ marginTop: "16px", color: "#4b5563" }}>
                    We've sent the confirmation details to your email.
                  </p>
                </div>
              ) : (
                <>
                  <div style={{ marginBottom: "16px" }}>
                    <p style={{ fontSize: "15px", marginBottom: "8px" }}>
                      <strong>Event:</strong> {eventTypeData?.title}
                    </p>
                    <p style={{ fontSize: "15px", marginBottom: "8px" }}>
                      <strong>Date:</strong>{" "}
                      {selectedDate.format("dddd, MMMM D, YYYY")}
                    </p>
                    <p style={{ fontSize: "15px", marginBottom: "8px" }}>
                      <strong>Time:</strong> {selectedTime}
                    </p>
                    <p style={{ fontSize: "15px", marginBottom: "8px" }}>
                      <strong>Duration:</strong> {eventTypeData?.duration}{" "}
                      minutes
                    </p>
                  </div>

                  <div style={{ marginBottom: "16px" }}>
                    <label style={styles.formInputLabel} htmlFor="name">
                      Your Name *
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      style={styles.formInput}
                      required
                      disabled={isSubmitting}
                    />
                  </div>

                  <div style={{ marginBottom: "16px" }}>
                    <label style={styles.formInputLabel} htmlFor="email">
                      Email Address *
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      style={styles.formInput}
                      required
                      disabled={isSubmitting}
                    />
                  </div>

                  <div style={{ marginBottom: "16px" }}>
                    <label style={styles.formInputLabel} htmlFor="notes">
                      Additional Notes
                    </label>
                    <textarea
                      id="notes"
                      name="notes"
                      value={formData.notes}
                      onChange={handleChange}
                      style={styles.formTextarea}
                      disabled={isSubmitting}
                    />
                  </div>
                </>
              )}
            </div>

            <div
              style={{
                padding: "16px 24px",
                borderTop: "1px solid #e5e7eb",
                display: "flex",
                justifyContent: "flex-end",
                gap: "12px",
              }}
            >
              <button
                style={styles.modalButtonSecondary}
                onClick={() => {
                  setOpenModal(false);
                  if (successMessage) {
                    setSuccessMessage("");
                    setFormData({ name: "", email: "", notes: "" });
                  }
                }}
                disabled={isSubmitting}
              >
                {successMessage ? "Close" : "Cancel"}
              </button>

              {!successMessage && (
                <button
                  style={{
                    ...styles.modalButtonPrimary,
                    ...(isSubmitting ? styles.disabledButton : {}),
                  }}
                  onClick={handleSubmitBooking}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Confirm Booking"}
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Updated styles with animations */}
      <style>
        {`
          @keyframes spin {
            to { transform: rotate(360deg); }
          }
          
          @media (max-width: 768px) {
            .flexRow {
              flex-direction: column;
            }
            
            .sidebar, .main, .timeline {
              width: 100%;
              border-right: none;
              border-bottom: 1px solid #e5e7eb;
            }
          }

          /* Add loading animation for the spinner */
          .booking-spinner {
            width: 40px;
            height: 40px;
            border: 4px solid rgba(59, 130, 246, 0.1);
            border-top-color: #3b82f6;
            border-radius: 50%;
            animation: spin 1s linear infinite;
          }

          /* Add fade-in animation for the overlay */
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          .loading-overlay {
            animation: fadeIn 0.3s ease;
          }

          /* Add success checkmark animation */
          @keyframes scaleIn {
            0% { transform: scale(0); }
            50% { transform: scale(1.2); }
            100% { transform: scale(1); }
          }

          .success-icon {
            animation: scaleIn 0.5s ease;
          }
          @keyframes pulse {
            0% { opacity: 1; }
            50% { opacity: 0.7; }
            100% { opacity: 1; }
          }
          
          .timer-warning {
            animation: pulse 1s infinite;
          }
        `}
      </style>
    </div>
  );
}

export default EventTypePreview;
