import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik, Field, Form } from "formik";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  TextField,
  FormHelperText,
  Alert,
  Breadcrumbs as MuiBreadcrumbs,
  IconButton,
  Collapse,
  Link,
} from "@mui/material";
import { spacing } from "@mui/system";
import { axiosInstance } from "../../utils/axios";
import {
  ArrowBack as ArrowBackIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);

const PermissionItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "0.5rem",
});

const PermissionLabel = styled(Typography)({
  marginLeft: "0.5rem",
});

// Component to handle nested permissions
const NestedPermission = ({
  permission,
  values,
  setFieldValue,
  handleChange,
  level = 0,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  // Check if this permission has sub-permissions
  const hasSubPermissions =
    permission.childrens && permission.childrens.length > 0;

  // Calculate checkbox state based on children
  const calculateCheckboxState = () => {
    if (!hasSubPermissions)
      return {
        checked: Boolean(values.permissions[permission.name]),
        indeterminate: false,
      };

    let allSelected = true;
    let anySelected = false;

    const checkChildrenState = (children) => {
      if (!children || !Array.isArray(children)) return;

      children.forEach((child) => {
        if (typeof child === "string") {
          const isSelected = Boolean(values.permissions[child]);
          allSelected = allSelected && isSelected;
          anySelected = anySelected || isSelected;
        } else if (child && typeof child === "object") {
          if (child.name) {
            const isSelected = Boolean(values.permissions[child.name]);
            allSelected = allSelected && isSelected;
            anySelected = anySelected || isSelected;
          }

          if (child.childrens && Array.isArray(child.childrens)) {
            checkChildrenState(child.childrens);
          }
        }
      });
    };

    checkChildrenState(permission.childrens);

    // Get the parent permission itself
    const parentSelected = Boolean(values.permissions[permission.name]);

    return {
      checked: parentSelected,
      indeterminate: !parentSelected && anySelected,
    };
  };

  const checkboxState = calculateCheckboxState();

  // Handle selecting/deselecting all children when parent checkbox is clicked
  const handleGroupChange = (e) => {
    const isChecked = e.target.checked;

    // Set the parent permission value
    setFieldValue(`permissions.${permission.name}`, isChecked);

    // Recursively set all children
    const processAllChildren = (children) => {
      if (!children || !Array.isArray(children)) return;

      children.forEach((child) => {
        if (typeof child === "string") {
          // Set string permission
          setFieldValue(`permissions.${child}`, isChecked);
        } else if (child && typeof child === "object") {
          // Set object permission if it has a name
          if (child.name) {
            setFieldValue(`permissions.${child.name}`, isChecked);
          }

          // Recursively set all nested children
          if (child.childrens && Array.isArray(child.childrens)) {
            processAllChildren(child.childrens);
          }
        }
      });
    };

    // Process all children of this permission
    if (hasSubPermissions) {
      processAllChildren(permission.childrens);
    }
  };

  return (
    <Box sx={{ ml: level * 2, mb: 1 }}>
      {permission.name && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxState.checked}
                indeterminate={checkboxState.indeterminate}
                onChange={handleGroupChange}
                name={`permissions.${permission.name}`}
              />
            }
            label={
              <Typography fontWeight={level === 0 ? "bold" : "normal"}>
                {permission.name}
                {permission.description && ` - ${permission.description}`}
              </Typography>
            }
          />

          {hasSubPermissions && (
            <IconButton size="small" onClick={toggleExpand}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </Box>
      )}

      {hasSubPermissions && (
        <Collapse in={expanded}>
          <Box sx={{ ml: 4 }}>
            {permission.childrens.map((child, index) => {
              if (typeof child === "string") {
                // Simple string permission
                return (
                  <PermissionItem key={index}>
                    <Checkbox
                      checked={Boolean(values.permissions[child])}
                      onChange={handleChange}
                      name={`permissions.${child}`}
                    />
                    <PermissionLabel>{child}</PermissionLabel>
                  </PermissionItem>
                );
              } else {
                // Nested permission object
                return (
                  <NestedPermission
                    key={index}
                    permission={child}
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    level={level + 1}
                  />
                );
              }
            })}
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

function RolesAdd() {
  const navigate = useNavigate();
  const [permissionsData, setPermissionsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Helper function to recursively check if any permissions are selected
  const hasSelectedPermissions = (permissions, values) => {
    if (!permissions || !Array.isArray(permissions)) return false;

    // Check if any permission is selected in the entire tree
    return permissions.some((permission) => {
      // Check if this permission is selected
      if (permission.name && values.permissions[permission.name]) {
        return true;
      }

      // Check if any child permission is selected
      if (permission.childrens) {
        if (Array.isArray(permission.childrens)) {
          return permission.childrens.some((child) => {
            if (typeof child === "string") {
              return Boolean(values.permissions[child]);
            } else if (child && typeof child === "object") {
              // Recursively check nested permissions
              return hasSelectedPermissions([child], values);
            }
            return false;
          });
        }
      }

      return false;
    });
  };

  // Flatten hierarchical permissions for submission
  const getFlattenedPermissions = (values) => {
    // Extract all selected permissions from the form values
    return Object.entries(values.permissions)
      .filter(([key, value]) => value)
      .map(([key]) => key);
  };

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query GetPermissions {
              getPermissions {
                id
                name
                description
                childrens {
                  name
                  description
                  childrens
                }
              }
            }
          `,
        });

        if (
          response.data &&
          response.data.data &&
          response.data.data.getPermissions
        ) {
          setPermissionsData(response.data.data.getPermissions);
        } else if (response.data && response.data.getPermissions) {
          setPermissionsData(response.data.getPermissions);
        } else {
          throw new Error("Invalid data structure received from server");
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching permissions:", error);
        setError("Failed to load permissions. Please try again later.");
        setLoading(false);
      }
    };

    fetchPermissions();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Helmet title="Add New Role" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Add New Role
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/private">
              Spaces
            </Link>
            {/* Use Typography instead of Link to make these non-clickable */}
            <Typography color="text.primary">Settings</Typography>
            <Typography color="text.primary">Roles & Permissions</Typography>

            <Typography color="text.primary">
              Add Roles & Permissions
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={NavLink}
              to="/settings/roles-permissions"
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      <Card mb={6} mt={6}>
        <CardContent>
          <Formik
            initialValues={{
              roleName: "",
              permissions: {},
            }}
            validationSchema={Yup.object().shape({
              roleName: Yup.string()
                .required("Role Name is required")
                .trim()
                .min(1, "Role Name cannot be empty")
                .matches(
                  /^[a-zA-Z0-9\s-_]+$/,
                  "Role Name can only contain letters, numbers, spaces, hyphens and underscores"
                )
                .test(
                  "no-only-spaces",
                  "Role Name cannot contain only spaces",
                  (value) => value && value.trim().length > 0
                ),
              permissions: Yup.object().test(
                "at-least-one-selected",
                "At least one permission must be selected",
                (permissions, context) => {
                  return hasSelectedPermissions(
                    permissionsData,
                    context.parent
                  );
                }
              ),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setStatus }
            ) => {
              try {
                const selectedPermissions = getFlattenedPermissions(values);

                const response = await axiosInstance.post("/graphql", {
                  query: `
                    mutation CreateRole($input: CreateRoleInput!) {
                      createRole(input: $input) {
                        id
                        name
                        permissions
                      }
                    }
                  `,
                  variables: {
                    input: {
                      name: values.roleName,
                      permissions: selectedPermissions,
                    },
                  },
                });

                // Check for GraphQL errors in the top-level errors field
                if (response.errors) {
                  const errorMsg = response.errors[0].message;

                  if (
                    errorMsg.includes("E11000") ||
                    errorMsg.includes("duplicate key error")
                  ) {
                    setErrors({
                      roleName: `Role "${values.roleName}" already exists. Please use a different name.`,
                    });
                  } else {
                    setStatus({ error: errorMsg });
                  }

                  setSubmitting(false);
                  return;
                }

                // Success case
                navigate("/settings/roles-permissions");
              } catch (error) {
                let errorMsg = "An error occurred while creating the role.";
                let isDuplicate = false;

                // Check for duplicate error in various locations
                if (
                  error.message &&
                  (error.message.includes("E11000") ||
                    error.message.includes("duplicate key error"))
                ) {
                  isDuplicate = true;
                } else if (error.response) {
                  if (error.response.errors) {
                    errorMsg = error.response.errors[0].message;
                    if (
                      errorMsg.includes("E11000") ||
                      errorMsg.includes("duplicate key error")
                    ) {
                      isDuplicate = true;
                    }
                  }
                }

                if (isDuplicate) {
                  setErrors({
                    roleName: `Role "${values.roleName}" already exists. Please use a different name.`,
                  });
                } else {
                  setStatus({ error: errorMsg });
                }

                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              status,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                {status && status.error && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {status.error}
                  </Alert>
                )}

                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <Field
                      name="roleName"
                      as={TextField}
                      label="Role Name"
                      fullWidth
                      variant="outlined"
                      value={values.roleName}
                      error={Boolean(touched.roleName && errors.roleName)}
                      helperText={touched.roleName && errors.roleName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>

                {errors.permissions && touched.permissions && (
                  <FormHelperText error>{errors.permissions}</FormHelperText>
                )}

                <Typography variant="h6" gutterBottom sx={{ mt: 4, mb: 2 }}>
                  Permissions
                </Typography>

                <Box mt={2}>
                  {permissionsData.map((permission) => (
                    <NestedPermission
                      key={permission.id}
                      permission={permission}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                    />
                  ))}
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  mt={3}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Add New Role"
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default RolesAdd;
