import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { axiosInstance } from "../../../utils/axios";
import { DataGrid } from "@mui/x-data-grid";
import { alpha } from "@mui/material/styles";
import {
  Alert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  TablePagination,
  Toolbar,
  Tooltip,
  Typography,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card as MuiCard,
  CardContent,
  CircularProgress,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import SearchBar from "../../components/SearchBar";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const Card = styled(MuiCard)(spacing);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableToolbar(props) {
  const { numSelected, onBulkDelete, viewStyle, setViewStyle } = props;

  const handleViewClick = () => {
    setViewStyle(viewStyle === "List" ? "Card" : "List");
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "70px",
        borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
      }}
    >
      <div>
        {numSelected > 0 && (
          <Typography
            color="inherit"
            variant="subtitle1"
            sx={{ fontWeight: 500 }}
          >
            {numSelected} selected
          </Typography>
        )}
      </div>

      <Box>
        {numSelected > 0 ? (
          <Box display="flex">
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                size="large"
                onClick={onBulkDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Tooltip
            title={
              viewStyle === "List"
                ? "Change to Card View"
                : "Change to List View"
            }
          >
            <IconButton
              color="primary"
              onClick={handleViewClick}
              size="medium"
              sx={{
                borderRadius: "8px",
                p: 1,
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.08),
                "&:hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.12),
                },
              }}
            >
              {viewStyle === "List" ? <ViewModuleIcon /> : <ViewListIcon />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Toolbar>
  );
}

function Bookings() {
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [bookings, setBookings] = useState([]);
  const [loadingActions, setLoadingActions] = useState({});

  // States for data grid
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [viewStyle, setViewStyle] = useState("List");
  const [searchQuery, setSearchQuery] = useState("");
  const [bulkDeleteDialog, setBulkDeleteDialog] = useState(false);

  // Reset page when search query changes
  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter bookings based on search query
  const filteredBookings = bookings.filter((booking) => {
    if (!searchQuery) return true;

    const query = searchQuery.toLowerCase();
    return (
      booking.name.toLowerCase().includes(query) ||
      booking.email.toLowerCase().includes(query) ||
      booking.id.toString().toLowerCase().includes(query) ||
      (booking.notes && booking.notes.toLowerCase().includes(query)) ||
      (booking.date && booking.date.toLowerCase().includes(query))
    );
  });

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  const handleAcceptReject = async (id, action) => {
    // Simulate loading state for the specific button
    setLoadingActions((prev) => ({
      ...prev,
      [id]: action,
    }));

    try {
      // Simulated async action - replace with actual API call
      await new Promise((resolve) => setTimeout(resolve, 1500));

      // Reset loading state
      setLoadingActions((prev) => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });

      // Optional: Update booking status or show success message
      setAlert({
        open: true,
        message: `Booking ${action === "accept" ? "Accepted" : "Rejected"}`,
        severity: "success",
      });
    } catch (error) {
      // Reset loading state
      setLoadingActions((prev) => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });

      // Show error message
      setAlert({
        open: true,
        message: `Error ${
          action === "accept" ? "accepting" : "rejecting"
        } booking`,
        severity: "error",
      });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = bookings.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleBulkDelete = () => {
    setBulkDeleteDialog(true);
  };

  const handleBulkDeleteConfirm = async () => {
    try {
      const deletePromises = selected.map((id) =>
        axiosInstance.post("/graphql", {
          query: `
            mutation DeleteBooking($id: ID!) {
              deleteBooking(id: $id)
            }
          `,
          variables: { id },
        })
      );

      await Promise.all(deletePromises);
      setBookings((prev) =>
        prev.filter((booking) => !selected.includes(booking.id))
      );
      setSelected([]);
      setAlert({
        open: true,
        message: `Successfully deleted ${selected.length} bookings`,
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: `Error deleting bookings: ${error.message}`,
        severity: "error",
      });
    } finally {
      setBulkDeleteDialog(false);
    }
  };

  // Format date for better display
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    } catch (e) {
      return dateString;
    }
  };

  // Fetch bookings initially
  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query {
              getBookings {
                id
                date
                timeStart
                timeEnd
                name
                email
                notes
                createdAt
                updatedAt
              }
            }
          `,
        });

        if (response.data.errors) {
          console.error(response.data.errors);
          setAlert({
            open: true,
            message: "Error fetching bookings",
            severity: "error",
          });
        } else {
          setBookings(response.data.getBookings);
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
        setAlert({
          open: true,
          message: "Error fetching bookings",
          severity: "error",
        });
      }
    };

    fetchBookings();
  }, []);

  // Render table view with DataGrid
  const renderTableView = () => {
    // Get paginated data with pre-calculated serial numbers
    const paginatedBookings = stableSort(
      filteredBookings,
      getComparator(order, orderBy)
    )
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((booking, index) => ({
        ...booking,
        serialNumber: page * rowsPerPage + index + 1,
        formattedDate: formatDate(booking.date),
        timeSlot: `${booking.timeStart} - ${booking.timeEnd}`,
      }));

    // Define columns for DataGrid
    const columns = [
      {
        field: "select",
        headerName: "",
        width: 50,
        sortable: false,
        filterable: false,
        headerClassName: "select-all-header",
        renderHeader: () => (
          <Checkbox
            indeterminate={
              selected.length > 0 && selected.length < filteredBookings.length
            }
            checked={
              filteredBookings.length > 0 &&
              selected.length === filteredBookings.length
            }
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all bookings" }}
          />
        ),
        renderCell: (params) => (
          <Checkbox
            checked={isSelected(params.row.id)}
            onClick={(event) => handleClick(event, params.row.id)}
            inputProps={{ "aria-labelledby": `select-${params.row.id}` }}
          />
        ),
      },
      {
        field: "serialNumber",
        headerName: "S.No",
        width: 70,
        sortable: false,
        filterable: false,
      },
      {
        field: "id",
        headerName: "ID",
        width: 100,
        renderCell: (params) => (
          <Typography variant="body2">#{params.row.id}</Typography>
        ),
      },
      {
        field: "name",
        headerName: "Booked By",
        flex: 1,
      },
      {
        field: "formattedDate",
        headerName: "Date",
        width: 120,
      },
      {
        field: "timeSlot",
        headerName: "Time",
        width: 150,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "notes",
        headerName: "Notes",
        flex: 1,
        renderCell: (params) => (
          <Typography variant="body2">
            {params.row.notes
              ? params.row.notes.length > 50
                ? `${params.row.notes.substring(0, 50)}...`
                : params.row.notes
              : ""}
          </Typography>
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 200,
        sortable: false,
        renderCell: (params) => (
          <Box display="flex" gap={1}>
            <Button
              variant="contained"
              color="success"
              size="small"
              disabled={loadingActions[params.row.id] === "accept"}
              onClick={() => handleAcceptReject(params.row.id, "accept")}
              startIcon={
                loadingActions[params.row.id] === "accept" ? (
                  <CircularProgress size={20} />
                ) : null
              }
            >
              {loadingActions[params.row.id] === "accept"
                ? "Accepting"
                : "Accept"}
            </Button>
            <Button
              variant="contained"
              color="error"
              size="small"
              disabled={loadingActions[params.row.id] === "reject"}
              onClick={() => handleAcceptReject(params.row.id, "reject")}
              startIcon={
                loadingActions[params.row.id] === "reject" ? (
                  <CircularProgress size={20} />
                ) : null
              }
            >
              {loadingActions[params.row.id] === "reject"
                ? "Rejecting"
                : "Reject"}
            </Button>
          </Box>
        ),
      },
    ];

    return (
      <>
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={paginatedBookings}
            columns={columns}
            hideFooter={true}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            checkboxSelection={false}
            rowHeight={70}
            headerHeight={56}
            getRowId={(row) => row.id}
            components={{
              Toolbar: () => null,
            }}
            sortingMode="server"
            sortModel={[
              {
                field: orderBy,
                sort: order.toLowerCase(),
              },
            ]}
            onSortModelChange={(model) => {
              if (model.length > 0) {
                setOrderBy(model[0].field);
                setOrder(model[0].sort === "asc" ? "asc" : "desc");
              }
            }}
            sx={{
              boxShadow: 0,
              border: 0,
              "& .MuiDataGrid-cell": {
                padding: "8px 16px",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "8px 16px",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredBookings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-toolbar": {
                alignItems: "center",
                minHeight: "40px",
                padding: "0 8px",
              },
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                },
              ".MuiTablePagination-select": {
                marginRight: "8px",
                marginLeft: "8px",
              },
              ".MuiTablePagination-actions": {
                display: "flex",
                alignItems: "center",
              },
            }}
          />
        </Box>
      </>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Bookings" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Bookings
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/private">
              Spaces
            </Link>
            <Link component={NavLink} to="/calendar/dashboard">
              Calendar
            </Link>
            <Typography color="text.primary">Bookings</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <SearchBar
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Search bookings..."
        containerSx={{
          borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
        }}
      />

      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onBulkDelete={handleBulkDelete}
          viewStyle={viewStyle}
          setViewStyle={setViewStyle}
        />

        {renderTableView()}
      </Paper>

      {/* Bulk Delete Dialog */}
      <Dialog
        open={bulkDeleteDialog}
        onClose={() => setBulkDeleteDialog(false)}
      >
        <DialogTitle>Confirm Bulk Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {selected.length} selected bookings?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBulkDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={handleBulkDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete {selected.length} Bookings
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alert Snackbar */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default Bookings;
