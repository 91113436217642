// src/utils/permissionUtils.js
import { useMemo } from "react";

export const useSavedPermissions = () => {
  return useMemo(() => {
    return JSON.parse(localStorage.getItem("rbacPermissions")) || [];
  }, []);
};

// If you need a non-hook version
export const getSavedPermissions = () => {
  return JSON.parse(localStorage.getItem("rbacPermissions")) || [];
};
