// src/components/auth/SignUp.js
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { errorHandler } from "../../utils/errorHandler";
import {
  Alert as MuiAlert,
  Button,
  CircularProgress,
  TextField as MuiTextField,
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Fade,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary.main};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

function SignUp() {
  const navigate = useNavigate();
  const { initiateSignUp, verifyEmail } = useAuth();

  // State for multi-step form
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({
    name: "",
    companyName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [otp, setOtp] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const [resendSuccess, setResendSuccess] = useState(false);
  const [resending, setResending] = useState(false);

  // Timer state
  const [countdown, setCountdown] = useState(0);
  const COUNTDOWN_TIME = 60; // 60 seconds (1 minute)

  // Use a ref to track last update time
  const lastUpdateTimeRef = useRef(Date.now());
  // Use a ref to track if component is mounted
  const isMountedRef = useRef(true);

  // Define steps for the stepper
  const steps = ["Registration", "Verification"];

  // Effect to manage countdown timer with visibility change handling
  useEffect(() => {
    // Only run timer if countdown > 0
    if (countdown <= 0) return;

    // Function to check for visibility changes
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // Calculate time elapsed since last update
        const now = Date.now();
        const elapsedSeconds = Math.floor(
          (now - lastUpdateTimeRef.current) / 1000
        );

        // Update countdown based on elapsed time
        setCountdown((prevCount) => {
          const newCount = Math.max(0, prevCount - elapsedSeconds);
          return newCount;
        });
      }

      // Always update the last update time when visibility changes
      lastUpdateTimeRef.current = Date.now();
    };

    // Add visibility change listener
    document.addEventListener("visibilitychange", handleVisibilityChange);

    const timer = setInterval(() => {
      if (document.visibilityState === "visible") {
        setCountdown((prevCount) => Math.max(0, prevCount - 1));
        lastUpdateTimeRef.current = Date.now();
      }
    }, 1000);

    // Clear interval and event listener on cleanup
    return () => {
      clearInterval(timer);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [countdown]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Handler for OTP verification
  const handleVerify = async () => {
    setVerifying(true);
    setVerificationError("");

    try {
      await verifyEmail(formValues.email, otp, {
        name: formValues.name,
        email: formValues.email,
        password: formValues.password,
        companyName: formValues.companyName,
      });
      navigate("/private");
    } catch (error) {
      setVerificationError(
        error.message || "Failed to verify email. Please try again."
      );
    } finally {
      setVerifying(false);
    }
  };

  // Handler for resending OTP
  const handleResendOtp = async () => {
    setResending(true);
    setVerificationError("");
    setResendSuccess(false);

    try {
      await initiateSignUp(
        formValues.name,
        formValues.email,
        formValues.password,
        formValues.companyName
      );

      setResendSuccess(true);
      // Start countdown timer
      setCountdown(COUNTDOWN_TIME);
      lastUpdateTimeRef.current = Date.now();

      // Auto-hide the success message after 5 seconds
      setTimeout(() => {
        if (isMountedRef.current) {
          setResendSuccess(false);
        }
      }, 5000);
    } catch (error) {
      setVerificationError(
        "Failed to resend verification code. Please try again."
      );
    } finally {
      setResending(false);
    }
  };

  // Handler for OTP input - allow only numbers and limit to 6 digits
  const handleOtpChange = (e) => {
    const value = e.target.value;
    // Only update if input is numeric and no longer than 6 digits
    if ((/^\d*$/.test(value) || value === "") && value.length <= 6) {
      setOtp(value);
    }
  };

  // Handler for going back to the registration step
  const handleBack = () => {
    // Clear OTP and error message when going back
    setOtp("");
    setVerificationError("");
    setActiveStep(0);
  };

  // Format the countdown time (MM:SS format)
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <>
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 ? (
        <Formik
          initialValues={formValues}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .max(255, "Name cannot exceed 255 characters")
              .required("Name is required"),
            companyName: Yup.string().max(
              255,
              "Company Name cannot exceed 255 characters"
            ),
            email: Yup.string()
              .email("Must be a valid email")
              .max(255, "Email cannot exceed 255 characters")
              .required("Email is required"),
            password: Yup.string()
              .min(12, "Password must be at least 12 characters")
              .max(255, "Password cannot exceed 255 characters")
              .required("Password is required"),
            confirmPassword: Yup.string()
              .required("Confirm Password is required")
              .oneOf([Yup.ref("password"), null], "Passwords must match"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              // Save form values to state to preserve them when going back
              setFormValues(values);

              // Clear any previous verification errors when going forward
              setVerificationError("");
              // Clear any previous OTP input
              setOtp("");

              // Initiate the signup process and get a verification email sent
              await initiateSignUp(
                values.name,
                values.email,
                values.password,
                values.companyName
              );

              // Start the countdown timer
              setCountdown(COUNTDOWN_TIME);
              lastUpdateTimeRef.current = Date.now();

              // Move to the verification step
              setActiveStep(1);
            } catch (error) {
              const validationErrors = errorHandler(error);
              setStatus({ success: false });
              setErrors(validationErrors);
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <TextField
                type="text"
                name="name"
                label="Name"
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <TextField
                type="text"
                name="companyName"
                label="Company Name"
                value={values.companyName}
                error={Boolean(touched.companyName && errors.companyName)}
                fullWidth
                helperText={touched.companyName && errors.companyName}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <TextField
                type="email"
                name="email"
                label="Email"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <TextField
                type="password"
                name="password"
                label="Password"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <TextField
                type="password"
                name="confirmPassword"
                label="Confirm password"
                value={values.confirmPassword}
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                fullWidth
                helperText={touched.confirmPassword && errors.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Continue"
                )}
              </Button>

              <Box mt={3} textAlign="center">
                <Typography variant="body2" color="textSecondary">
                  Already have an account?{" "}
                  <StyledLink to="/auth/sign-in">Sign in here</StyledLink>
                </Typography>
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        // Email verification step
        <Box>
          <Typography variant="h6" gutterBottom>
            Email Verification
          </Typography>
          <Typography variant="body1" paragraph>
            We've sent a verification code to {formValues.email}. Please check
            your inbox and enter the 6-digit code below.
          </Typography>

          {verificationError && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {verificationError}
            </Alert>
          )}

          {/* Success message for resending code */}
          <Fade in={resendSuccess} timeout={500}>
            <Box sx={{ mb: 3 }}>
              <Alert severity="success">
                Verification code has been resent successfully!
              </Alert>
            </Box>
          </Fade>

          <TextField
            type="text"
            name="otp"
            label="6-Digit Verification Code"
            value={otp}
            onChange={handleOtpChange}
            fullWidth
            my={3}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              maxLength: 6,
            }}
            placeholder="Enter 6 digits"
            helperText={
              otp.length < 6 && otp.length > 0
                ? "Please enter all 6 digits"
                : ""
            }
            error={otp.length < 6 && otp.length > 0}
          />

          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              variant="outlined"
              onClick={handleBack}
              disabled={verifying || resending}
            >
              Back
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleVerify}
              disabled={otp.length !== 6 || verifying || resending}
            >
              {verifying ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Verify Email"
              )}
            </Button>
          </Box>

          <Box mt={3} textAlign="center">
            <Typography variant="body2" color="textSecondary">
              Didn't receive the code?{" "}
              {countdown > 0 ? (
                <Typography
                  component="span"
                  color="text.secondary"
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  Resend available in{" "}
                  <Box
                    component="span"
                    sx={{ fontWeight: "bold", ml: 0.5, color: "primary.main" }}
                  >
                    {formatTime(countdown)}
                  </Box>
                </Typography>
              ) : (
                <Button
                  color="primary"
                  onClick={handleResendOtp}
                  disabled={verifying || resending || countdown > 0}
                  sx={{ textTransform: "none" }}
                >
                  {resending ? "Sending..." : "Resend Code"}
                </Button>
              )}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}

export default SignUp;
