import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import EventTypeContext from "../../../contexts/EventTypeContext.js"; // Import the shared context
import Availability from "./Availability"; // Import the Availability component
import EventSetup from "./EventSetup";
import Assignment from "./Assignment";
import Limits from "./Limits";
import Recurring from "./Recurring";
import Advanced from "./Advanced";

import {
  Grid,
  Paper as MuiPaper,
  MenuItem,
  MenuList,
  Typography,
  ListItem,
  ListItemText,
  Box,
  IconButton,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

// Fix 1: Correctly styled Paper component
const Paper = styled(MuiPaper)(({ theme }) => ({
  // You can add specific styling here if needed
}));

function RegistrationTab() {
  // Get the event type ID from URL parameters
  const { id } = useParams();

  // Access the context
  const { hasUnsavedChanges, currentTab, setCurrentTab } =
    useContext(EventTypeContext);

  return (
    <Grid container>
      <Grid md={3}>
        <Paper>
          <MenuList style={{ height: "600px" }}>
            {currentTab === "Event Setup" ? (
              <MenuItem
                onClick={() => {
                  setCurrentTab("Event Setup");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary="Event Setup"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          30 mins
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  setCurrentTab("Event Setup");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText
                    primary="Event Setup"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          30 mins
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            )}
            {currentTab === "Availability" ? (
              <MenuItem
                onClick={() => {
                  setCurrentTab("Availability");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={
                      <>
                        Availability
                        {hasUnsavedChanges && (
                          <Box
                            component="span"
                            sx={{
                              display: "inline-block",
                              width: "8px",
                              height: "8px",
                              borderRadius: "50%",
                              bgcolor: "primary.main",
                              ml: 1,
                            }}
                          />
                        )}
                      </>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Working Hours
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  setCurrentTab("Availability");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <>
                        Availability
                        {hasUnsavedChanges && (
                          <Box
                            component="span"
                            sx={{
                              display: "inline-block",
                              width: "8px",
                              height: "8px",
                              borderRadius: "50%",
                              bgcolor: "primary.main",
                              ml: 1,
                            }}
                          />
                        )}
                      </>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Working Hours
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            )}
            {/* Other menu items - simplified for brevity */}
            {["Assignment", "Limits", "Advanced", "Recurring"].map((tab) => (
              <MenuItem
                key={tab}
                onClick={() => {
                  setCurrentTab(tab);
                }}
                style={
                  currentTab === tab
                    ? {
                        borderLeft: "3px solid",
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                        minHeight: "50px",
                      }
                    : { minHeight: "50px" }
                }
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    currentTab === tab ? (
                      <IconButton aria-label="comment">
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    ) : null
                  }
                >
                  <ListItemText primary={tab} />
                </ListItem>
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Grid>
      <Grid md={9} sx={{ paddingLeft: "25px" }}>
        {currentTab === "Event Setup" ? (
          <EventSetup
            eventTypeId={id} // Pass the real event type ID
            isTabActive={currentTab === "Event Setup"}
          />
        ) : currentTab === "Availability" ? (
          <Availability
            eventTypeId={id} // Pass the real event type ID
            isTabActive={currentTab === "Availability"}
          />
        ) : currentTab === "Limits" ? (
          <Limits />
        ) : currentTab === "Advanced" ? (
          <Advanced />
        ) : currentTab === "Assignment" ? (
          <Assignment />
        ) : (
          <Recurring />
        )}
      </Grid>
    </Grid>
  );
}

export default RegistrationTab;
