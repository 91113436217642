import React from "react";
import styled from "@emotion/styled";
import { Button as MuiButton, Menu, MenuItem } from "@mui/material";
import {
  Loop as LoopIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Button = styled(MuiButton)(spacing);

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

function Actions() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentDate, setCurrentDate] = React.useState(new Date());

  // Format the date as "Month Day" (e.g., "April 29")
  const formatDate = (date) => {
    const options = { month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle date selection
  const handleDateSelection = (selection) => {
    let newDate = new Date();

    switch (selection) {
      case "today":
        // Already set to today
        break;
      case "yesterday":
        newDate.setDate(newDate.getDate() - 1);
        break;
      case "last7days":
        // This just updates the display text, not the actual date range
        break;
      case "last30days":
        // This just updates the display text, not the actual date range
        break;
      case "thisMonth":
        // This just updates the display text, not the actual date range
        break;
      case "lastMonth":
        // Set to the first day of previous month
        newDate.setMonth(newDate.getMonth() - 1);
        newDate.setDate(1);
        break;
      default:
        break;
    }

    setCurrentDate(newDate);
    handleClose();
  };

  return (
    <React.Fragment>
      <SmallButton size="small" mr={2}>
        <LoopIcon />
      </SmallButton>
      <SmallButton size="small" mr={2}>
        <FilterListIcon />
      </SmallButton>
      <Button
        variant="contained"
        color="secondary"
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        Today: {formatDate(currentDate)}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleDateSelection("today")}>Today</MenuItem>
        <MenuItem onClick={() => handleDateSelection("yesterday")}>
          Yesterday
        </MenuItem>
        <MenuItem onClick={() => handleDateSelection("last7days")}>
          Last 7 days
        </MenuItem>
        <MenuItem onClick={() => handleDateSelection("last30days")}>
          Last 30 days
        </MenuItem>
        <MenuItem onClick={() => handleDateSelection("thisMonth")}>
          This month
        </MenuItem>
        <MenuItem onClick={() => handleDateSelection("lastMonth")}>
          Last month
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default Actions;
