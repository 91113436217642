// src/apolloClient.js
import { ApolloClient, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { serverConfig } from "../src/config";

// Get the backend URI from environment variables
const BACKEND_URI = serverConfig.serverURI;
console.log("log 037", serverConfig);

// Create HTTP and WS URIs based on the backend URI
const HTTP_URI = `${BACKEND_URI}/graphql`;
const WS_URI = BACKEND_URI.replace(/^http/, "ws") + "/graphql";

// Create an upload link for queries and mutations
const uploadLink = createUploadLink({
  uri: HTTP_URI,
  credentials: "include", // Include credentials if needed
});

// Create an authentication link to include auth token in headers
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("accessToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Add error handling link
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      // Check for permissions updated error
      if (
        message.includes("PERMISSIONS_UPDATED") ||
        (extensions && extensions.code === "PERMISSIONS_UPDATED")
      ) {
        console.log("Permissions have been updated, logging out...");

        // Clear local storage
        localStorage.removeItem("accessToken");
        localStorage.removeItem("rbacPermissions");

        // Show a notification to the user
        alert(
          "Your permissions have been updated. You'll need to log in again."
        );

        // Redirect to login page
        window.location.href = "/auth/sign-in";
      }
    });
  }

  if (networkError && networkError.statusCode === 401) {
    // Also handle 401 errors that might be permission related
    if (
      networkError.result &&
      networkError.result.code === "PERMISSIONS_UPDATED"
    ) {
      console.log("Permissions have been updated, logging out...");

      // Clear local storage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("rbacPermissions");

      // Show a notification to the user
      alert("Your permissions have been updated. You'll need to log in again.");

      // Redirect to login page
      window.location.href = "/auth/sign-in";
    }
  }
});

// Combine authLink and uploadLink with error link
const httpLinkWithAuth = errorLink.concat(authLink.concat(uploadLink));

// Create a WebSocket link for subscriptions
const wsLink = new GraphQLWsLink(
  createClient({
    url: WS_URI,
    connectionParams: () => {
      const token = localStorage.getItem("accessToken");
      return {
        authorization: token ? `Bearer ${token}` : "",
      };
    },
  })
);

// Split links based on operation type
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLinkWithAuth // Use the combined link here
);

// Initialize Apollo Client
const client = new ApolloClient({
  link: splitLink, // Use splitLink instead of uploadLink
  cache: new InMemoryCache(),
});

// Log connection information in development environment
if (process.env.NODE_ENV === "development") {
  console.log(`Apollo Client connecting to HTTP endpoint: ${HTTP_URI}`);
  console.log(`Apollo Client connecting to WebSocket endpoint: ${WS_URI}`);
}

export default client;
