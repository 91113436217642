import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import useAuth from "../../hooks/useAuth";
import { useSavedPermissions } from "../../utils/permissionUtils";
import { PERMISSIONS } from "../../utils/permissionConstants";
import { DataGrid } from "@mui/x-data-grid";
import { alpha } from "@mui/material/styles";
import SearchBar from "../components/SearchBar";
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  Checkbox,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  FormControlLabel,
  TextField,
  FormHelperText,
  InputAdornment,
  Collapse,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { axiosInstance } from "../../utils/axios";

// Helper functions for sorting
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const PermissionsGroup = styled(Box)({
  marginBottom: "1rem",
  padding: "0.5rem",
  border: "1px solid #ddd",
  borderRadius: "4px",
  backgroundColor: "#f9f9f9",
});

const SubPermissionsGroup = styled(Box)({
  marginTop: "0.5rem",
  marginLeft: "2rem",
  padding: "0.5rem",
  border: "1px solid #eee",
  borderRadius: "4px",
  backgroundColor: "#f5f5f5",
});

const UPDATE_ROLE = `
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      id
      name
      permissions
    }
  }
`;

const DELETE_ROLE = `
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id)
  }
`;

// Role Card Component for Grid View
const RoleCard = ({ role, isSelected, onSelect, serialNumber }) => {
  return (
    <Paper>
      <Card>
        <div style={{ position: "relative" }}>
          <Box
            sx={{
              backgroundColor: "#f0f0f0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "140px",
            }}
          >
            <Typography variant="h2" color="textSecondary">
              {role.name.charAt(0).toUpperCase()}
            </Typography>
          </Box>
          <Checkbox
            checked={isSelected}
            onChange={(e) => onSelect(e, role.id)}
            style={{ position: "absolute", top: "8px", left: "8px" }}
          />
        </div>
        <CardContent>
          <Box>
            <Typography variant="h5" gutterBottom>
              {role.name}
            </Typography>
          </Box>
          <Typography mb={2} color="textSecondary" component="p">
            S.No: {serialNumber}
          </Typography>
          <Box mb={2}>
            <Typography variant="body2" color="textSecondary">
              {role.permissions.length > 3
                ? `${role.permissions.slice(0, 3).join(", ")}...`
                : role.permissions.join(", ")}
            </Typography>
            <Typography variant="caption" color="primary">
              {role.permissions.length} permissions
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Paper>
  );
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    onBulkDelete,
    onBulkEdit,
    canDelete,
    canUpdate,
    viewStyle,
    setViewStyle,
    // Remove searchQuery and setSearchQuery props
  } = props;

  const handleViewClick = () => {
    setViewStyle(viewStyle === "List" ? "Card" : "List");
  };

  // Remove handleSearchChange function

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "70px",
        borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
      }}
    >
      {numSelected > 0 ? (
        <Typography
          color="inherit"
          variant="subtitle1"
          sx={{ fontWeight: 500 }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        // Remove the TextField search input from here
        <div></div> // Empty div to maintain the flex layout
      )}

      <Box>
        {numSelected > 0 ? (
          <Box display="flex">
            {numSelected === 1 && canUpdate && (
              <Tooltip title="Edit">
                <IconButton
                  aria-label="Edit"
                  size="large"
                  onClick={onBulkEdit}
                  sx={{ mr: 1 }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {canDelete && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="Delete"
                  size="large"
                  onClick={onBulkDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ) : (
          <Tooltip
            title={
              viewStyle === "List"
                ? "Change to Card View"
                : "Change to List View"
            }
          >
            <IconButton
              color="primary"
              onClick={handleViewClick}
              size="medium"
              sx={{
                borderRadius: "8px",
                p: 1,
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.08),
                "&:hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.12),
                },
              }}
            >
              {viewStyle === "List" ? <ViewModuleIcon /> : <ViewListIcon />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Toolbar>
  );
}

const PermissionHierarchy = ({
  permission,
  values,
  handleChange,
  setFieldValue,
  level = 0,
}) => {
  const [expanded, setExpanded] = useState(true);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  // Determine if this permission has sub-permissions
  const hasSubPermissions =
    permission.childrens && permission.childrens.length > 0;

  // Calculate whether all children are selected
  const calculateChildrenState = () => {
    if (!hasSubPermissions) return { allSelected: false, someSelected: false };

    let allSelected = true;
    let someSelected = false;

    const checkChildState = (child) => {
      if (typeof child === "string") {
        const isSelected = Boolean(values.permissions[child]);
        allSelected = allSelected && isSelected;
        someSelected = someSelected || isSelected;
      } else if (child && typeof child === "object") {
        // For child objects, check if the child itself is selected
        if (child.name) {
          const isSelected = Boolean(values.permissions[child.name]);
          allSelected = allSelected && isSelected;
          someSelected = someSelected || isSelected;
        }

        // Also check the child's children
        if (child.childrens && Array.isArray(child.childrens)) {
          child.childrens.forEach(checkChildState);
        }
      }
    };

    permission.childrens.forEach(checkChildState);

    return { allSelected, someSelected };
  };

  const { allSelected, someSelected } = calculateChildrenState();

  // Parent is checked if it's explicitly checked
  const isParentChecked = Boolean(values.permissions[permission.name]);

  // Parent is indeterminate if some but not all children are selected
  // AND the parent itself is not explicitly checked
  const isParentIndeterminate =
    !isParentChecked && someSelected && !allSelected;

  // Handle parent checkbox click
  const handleParentChange = (e) => {
    const checked = e.target.checked;

    // Set the parent permission value
    setFieldValue(`permissions.${permission.name}`, checked);

    // Set all children based on the parent's new state
    if (hasSubPermissions) {
      // This is the critical part - we need to recursively set ALL children
      const processChildren = (children) => {
        if (!children) return;

        children.forEach((child) => {
          if (typeof child === "string") {
            // If child is a string, directly set its value
            setFieldValue(`permissions.${child}`, checked);
          } else if (child && typeof child === "object") {
            // If child is an object with a name, set its value
            if (child.name) {
              setFieldValue(`permissions.${child.name}`, checked);
            }

            // Recursively process this child's children if any
            if (child.childrens && Array.isArray(child.childrens)) {
              processChildren(child.childrens);
            }
          }
        });
      };

      // Process all children of the current permission
      processChildren(permission.childrens);
    }
  };

  return (
    <Box sx={{ mb: 1, ml: level * 2 }}>
      {permission.name && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isParentChecked}
                indeterminate={isParentIndeterminate}
                onChange={handleParentChange}
                name={`permissions.${permission.name}`}
              />
            }
            label={permission.name}
          />

          {hasSubPermissions && (
            <IconButton size="small" onClick={toggleExpand}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </Box>
      )}

      {hasSubPermissions && expanded && (
        <Box sx={{ ml: 4 }}>
          {permission.childrens.map((child, index) => {
            if (typeof child === "string") {
              // Simple string permission
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={Boolean(values.permissions[child])}
                      onChange={handleChange}
                      name={`permissions.${child}`}
                    />
                  }
                  label={child}
                />
              );
            } else {
              // Nested permission object
              return (
                <PermissionHierarchy
                  key={index}
                  permission={child}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  level={level + 1}
                />
              );
            }
          })}
        </Box>
      )}
    </Box>
  );
};

function RolesList() {
  // Auth and permissions
  const { user } = useAuth();
  const savedPermissions = useSavedPermissions();
  const isPremium = user?.subscriptionLevel === "1";

  // Permission checks
  const canViewRoles =
    isPremium || savedPermissions.includes(PERMISSIONS.ROLES.childrens[0]);
  const canAddRoles =
    isPremium || savedPermissions.includes(PERMISSIONS.ROLES.childrens[1]);
  const canUpdateRoles =
    isPremium || savedPermissions.includes(PERMISSIONS.ROLES.childrens[2]);
  const canDeleteRoles =
    isPremium || savedPermissions.includes(PERMISSIONS.ROLES.childrens[3]);

  const [roles, setRoles] = useState([]);
  const [permissionsData, setPermissionsData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [editDialog, setEditDialog] = useState({ open: false, role: null });
  const [deleteDialog, setDeleteDialog] = useState({ open: false, role: null });
  const [bulkDeleteDialog, setBulkDeleteDialog] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  // Add view style state
  const [viewStyle, setViewStyle] = useState("List");
  // Add search state
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (canViewRoles) {
      fetchRolesAndPermissions();
    }
  }, [canViewRoles]);

  const fetchRolesAndPermissions = async () => {
    try {
      // Fetch roles (unchanged)
      const rolesResponse = await axiosInstance.post("/graphql", {
        query: `
        query {
          getRoles {
            id
            name
            permissions
          }
        }
      `,
      });

      // Fetch permissions with a query that works for both formats
      const permissionsResponse = await axiosInstance.post("/graphql", {
        query: `
        query {
          getPermissions {
            id
            name
            description
            childrens {
              name
              description
              childrens
            }
          }
        }
      `,
      });

      if (rolesResponse.errors) {
        throw new Error(rolesResponse.errors[0].message);
      }

      if (permissionsResponse.errors) {
        throw new Error(permissionsResponse.errors[0].message);
      }

      const sortedRoles =
        rolesResponse.data.getRoles || rolesResponse.data.getRoles;
      setRoles(sortedRoles);

      const permissions =
        permissionsResponse.data.getPermissions ||
        permissionsResponse.data.getPermissions;
      setPermissionsData(permissions);
    } catch (error) {
      showAlert(error.message, "error");
    }
  };

  // Flatten permission hierarchy for role editing
  const flattenPermissions = (permissions) => {
    let flat = {};

    const processPermission = (permission) => {
      if (typeof permission === "string") {
        flat[permission] = true;
      } else if (permission.name) {
        flat[permission.name] = true;

        if (permission.childrens && Array.isArray(permission.childrens)) {
          permission.childrens.forEach((child) => {
            if (typeof child === "string") {
              flat[child] = true;
            } else {
              processPermission(child);
            }
          });
        }
      }
    };

    permissions.forEach(processPermission);
    return flat;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredRoles.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleEdit = (role) => {
    if (!canUpdateRoles) {
      showAlert("You don't have permission to edit roles", "warning");
      return;
    }
    setEditDialog({ open: true, role });
  };

  const handleDelete = (role) => {
    if (!canDeleteRoles) {
      showAlert("You don't have permission to delete roles", "warning");
      return;
    }
    setDeleteDialog({ open: true, role });
  };

  const handleBulkDelete = () => {
    if (!canDeleteRoles) {
      showAlert("You don't have permission to delete roles", "warning");
      return;
    }
    setBulkDeleteDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Reset to first page when search query changes
  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditSubmit = async (values, { setSubmitting }) => {
    try {
      // Extract selected permissions from the form values
      const selectedPermissions = Object.entries(values.permissions)
        .filter(([, value]) => value)
        .map(([key]) => key);

      const response = await axiosInstance.post("/graphql", {
        query: UPDATE_ROLE,
        variables: {
          input: {
            id: editDialog.role.id,
            name: values.roleName,
            permissionIds: selectedPermissions,
          },
        },
      });

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      setRoles((prev) =>
        prev.map((role) =>
          role.id === editDialog.role.id ? response.data.updateRole : role
        )
      );

      // Clear the selected items after successful update
      setSelected([]);

      showAlert("Role updated successfully", "success");
      setEditDialog({ open: false, role: null });
    } catch (error) {
      showAlert(error.message, "error");
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: DELETE_ROLE,
        variables: { id: deleteDialog.role.id },
      });

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      setRoles((prev) =>
        prev.filter((role) => role.id !== deleteDialog.role.id)
      );
      showAlert("Role deleted successfully", "success");
      setSelected((prev) => prev.filter((id) => id !== deleteDialog.role.id));
    } catch (error) {
      showAlert(error.message, "error");
    } finally {
      setDeleteDialog({ open: false, role: null });
    }
  };

  const handleBulkDeleteConfirm = async () => {
    try {
      const deletePromises = selected.map(async (id) => {
        const response = await axiosInstance.post("/graphql", {
          query: DELETE_ROLE,
          variables: { id },
        });

        // Check for GraphQL errors in the response
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        return response;
      });

      await Promise.all(deletePromises);
      setRoles((prev) => prev.filter((role) => !selected.includes(role.id)));
      showAlert(`Successfully deleted ${selected.length} roles`, "success");
      setSelected([]);
    } catch (error) {
      showAlert(`Error deleting roles: ${error.message}`, "error");
    } finally {
      setBulkDeleteDialog(false);
    }
  };

  const showAlert = (message, severity = "success") => {
    setAlert({ open: true, message, severity });
  };

  // Table headers
  const headCells = [
    { id: "id", label: "ID", align: "left" },
    { id: "name", label: "Name", align: "left" },
    { id: "permissions", label: "Permissions", align: "left" },
    { id: "actions", label: "Actions", align: "right" },
  ];

  // Filter roles based on search query
  const filteredRoles = roles.filter((role) => {
    if (!searchQuery) return true;

    const query = searchQuery.toLowerCase();
    return (
      role.name.toLowerCase().includes(query) ||
      role.id.toString().toLowerCase().includes(query) ||
      role.permissions.some((perm) => perm.toLowerCase().includes(query))
    );
  });

  // Render card view

  const renderCardView = () => {
    const paginatedRoles = stableSort(
      filteredRoles,
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
      <>
        <Box px={2} py={2} display="flex" justifyContent="flex-end">
          <Checkbox
            indeterminate={
              selected.length > 0 && selected.length < filteredRoles.length
            }
            checked={
              filteredRoles.length > 0 &&
              selected.length === filteredRoles.length
            }
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all roles" }}
          />
          <Typography
            variant="body2"
            style={{ marginLeft: "8px", alignSelf: "center" }}
          >
            Select All
          </Typography>
        </Box>
        <Box m={1} pt={1}>
          <Grid container spacing={4}>
            {paginatedRoles.map((role, index) => (
              <Grid item key={role.id} xs={12} sm={6} md={4} lg={3}>
                <RoleCard
                  role={role}
                  isSelected={selected.includes(role.id)}
                  onSelect={handleClick}
                  serialNumber={page * rowsPerPage + index + 1}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px 0",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRoles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  marginBottom: "0",
                  display: "flex",
                  alignItems: "center",
                },
              ".MuiTablePagination-select": {
                marginRight: "32px",
                marginLeft: "8px",
              },
            }}
          />
        </div>
      </>
    );
  };

  // Render table view with DataGrid
  const renderTableView = () => {
    // Get paginated data with pre-calculated serial numbers
    const paginatedRoles = stableSort(
      filteredRoles,
      getComparator(order, orderBy)
    )
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((role, index) => ({
        ...role,
        serialNumber: page * rowsPerPage + index + 1,
      }));

    // Define columns for DataGrid - removed action column
    const columns = [
      {
        field: "select",
        headerName: "",
        width: 50,
        sortable: false,
        filterable: false,
        headerClassName: "select-all-header",
        renderHeader: () => (
          <Checkbox
            indeterminate={
              selected.length > 0 && selected.length < filteredRoles.length
            }
            checked={
              filteredRoles.length > 0 &&
              selected.length === filteredRoles.length
            }
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all roles" }}
          />
        ),
        renderCell: (params) => (
          <Checkbox
            checked={selected.includes(params.row.id)}
            onClick={(event) => handleClick(event, params.row.id)}
            inputProps={{ "aria-labelledby": `select-${params.row.id}` }}
          />
        ),
      },
      {
        field: "serialNumber",
        headerName: "S.No",
        width: 80,
        sortable: false,
        filterable: false,
      },
      { field: "name", headerName: "Name", flex: 1 },
      {
        field: "permissions",
        headerName: "Permissions",
        flex: 2,
        valueGetter: (params) => params.row.permissions.join(", "),
      },
    ];

    return (
      <>
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={paginatedRoles}
            columns={columns}
            hideFooter={true}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            checkboxSelection={false}
            rowHeight={70}
            headerHeight={56}
            components={{
              Toolbar: () => null,
            }}
            sortingMode="server"
            sortModel={[
              {
                field: orderBy,
                sort: order.toLowerCase(),
              },
            ]}
            onSortModelChange={(model) => {
              if (model.length > 0) {
                setOrderBy(model[0].field);
                setOrder(model[0].sort === "asc" ? "asc" : "desc");
              }
            }}
            sx={{
              boxShadow: 0,
              border: 0,
              "& .MuiDataGrid-cell": {
                padding: "8px 16px",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "8px 16px",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRoles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-toolbar": {
                alignItems: "center",
                minHeight: "40px",
                padding: "0 8px",
              },
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                },
              ".MuiTablePagination-select": {
                marginRight: "8px",
                marginLeft: "8px",
              },
              ".MuiTablePagination-actions": {
                display: "flex",
                alignItems: "center",
              },
            }}
          />
        </Box>
      </>
    );
  };

  if (!canViewRoles) {
    return (
      <React.Fragment>
        <Helmet title="Roles" />
        <Typography variant="h3" gutterBottom>
          Roles
        </Typography>
        {/* <Alert severity="warning"> */}
        {/*   You don't have permission to view this page. */}
        {/* </Alert> */}
      </React.Fragment>
    );
  }

  const handleBulkEdit = () => {
    if (!canUpdateRoles) {
      showAlert("You don't have permission to edit roles", "warning");
      return;
    }

    if (selected.length !== 1) {
      showAlert("Please select exactly one role to edit", "info");
      return;
    }

    // Find the selected role
    const roleToEdit = roles.find((role) => role.id === selected[0]);
    handleEdit(roleToEdit);
  };

  return (
    <React.Fragment>
      <Helmet title="Roles" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Roles & Permissions
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/private">
              Spaces
            </Link>
            {/* Use Typography instead of Link to make these non-clickable */}
            <Typography color="text.primary">Settings</Typography>
            <Typography color="text.primary">Roles & Permissions</Typography>
          </Breadcrumbs>
        </Grid>
        {canAddRoles && (
          <Grid item>
            <Button
              component={NavLink}
              to="/settings/roles-permissions-add"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              New Role
            </Button>
          </Grid>
        )}
      </Grid>
      <Divider my={6} />
      <SearchBar
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Search roles..."
        containerSx={{
          borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
        }}
      />
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onBulkDelete={handleBulkDelete}
          onBulkEdit={handleBulkEdit}
          canDelete={canDeleteRoles}
          canUpdate={canUpdateRoles}
          viewStyle={viewStyle}
          setViewStyle={setViewStyle}
        />

        {/* Toggle between view styles */}
        {viewStyle === "List" ? renderTableView() : renderCardView()}
      </Paper>
      {/* Edit Dialog */}
      <Dialog
        open={editDialog.open}
        onClose={() => setEditDialog({ open: false, role: null })}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Role</DialogTitle>
        <DialogContent>
          {editDialog.role && (
            <Formik
              initialValues={{
                roleName: editDialog.role.name,
                permissions: editDialog.role.permissions.reduce(
                  (acc, perm) => ({ ...acc, [perm]: true }),
                  {}
                ),
              }}
              validationSchema={Yup.object().shape({
                roleName: Yup.string().required("Role name is required"),
                permissions: Yup.object().test(
                  "at-least-one",
                  "At least one permission is required",
                  (obj) => Object.values(obj).some(Boolean)
                ),
              })}
              onSubmit={handleEditSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="roleName"
                    as={TextField}
                    label="Role Name"
                    fullWidth
                    margin="normal"
                    error={Boolean(touched.roleName && errors.roleName)}
                    helperText={touched.roleName && errors.roleName}
                  />

                  <Typography variant="h6" gutterBottom sx={{ mt: 3, mb: 1 }}>
                    Permissions
                  </Typography>

                  {permissionsData.map((permission) => (
                    <PermissionHierarchy
                      key={permission.id}
                      permission={permission}
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  ))}

                  {errors.permissions && touched.permissions && (
                    <FormHelperText error>{errors.permissions}</FormHelperText>
                  )}

                  <DialogActions>
                    <Button
                      onClick={() => setEditDialog({ open: false, role: null })}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      Save Changes
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({ open: false, role: null })}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the role "{deleteDialog.role?.name}
            "? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog({ open: false, role: null })}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Bulk Delete Confirmation Dialog */}
      <Dialog
        open={bulkDeleteDialog}
        onClose={() => setBulkDeleteDialog(false)}
      >
        <DialogTitle>Confirm Bulk Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {selected.length} selected roles?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBulkDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={handleBulkDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete {selected.length} Roles
          </Button>
        </DialogActions>
      </Dialog>
      {/* Alert Snackbar */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default RolesList;
