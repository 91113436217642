import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import EventTypeContext from "../../../contexts/EventTypeContext.js";
import { spacing } from "@mui/system";

import {
  Box,
  Button,
  CircularProgress,
  Divider as MuiDivider,
  FormControl,
  Grid,
  InputLabel,
  ListItem,
  MenuItem,
  Paper as MuiPaper,
  Select,
  Switch,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";

// Styled components
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);

// GraphQL queries and mutations
const GET_EVENT_TYPE = gql`
  query GetEventType($id: ID!) {
    getEventType(id: $id) {
      id
      title
      recurring {
        enabled
        frequency
        unit
        maxOccurrences
      }
    }
  }
`;

const UPDATE_EVENT_TYPE_RECURRING = gql`
  mutation UpdateEventTypeRecurring($input: UpdateEventTypeRecurringInput!) {
    updateEventTypeRecurring(input: $input) {
      eventType {
        id
        recurring {
          enabled
          frequency
          unit
          maxOccurrences
        }
      }
    }
  }
`;

function Recurring() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Access context for tracking unsaved changes
  const { setHasUnsavedChanges } = useContext(EventTypeContext);

  // Default recurring state
  const [recurring, setRecurring] = useState({
    enabled: false,
    frequency: 1,
    unit: "Month",
    maxOccurrences: 12,
  });

  // Initial recurring state to track changes
  const [initialRecurring, setInitialRecurring] = useState(null);

  // Fetch event type data
  const {
    loading: eventTypeLoading,
    error: eventTypeError,
    data: eventTypeData,
  } = useQuery(GET_EVENT_TYPE, {
    variables: { id },
    fetchPolicy: "network-only",
    skip: !id,
    onCompleted: (data) => {
      if (data && data.getEventType) {
        // Initialize recurring from server data or use defaults
        const config = data.getEventType.recurring || {
          enabled: false,
          frequency: 1,
          unit: "Month",
          maxOccurrences: 12,
        };

        setRecurring(config);
        // Store initial state to track changes
        setInitialRecurring(JSON.parse(JSON.stringify(config)));
      }
    },
  });

  // Update event type mutation
  const [updateRecurring, { loading: saveLoading }] = useMutation(
    UPDATE_EVENT_TYPE_RECURRING,
    {
      onCompleted: () => {
        setAlert({
          open: true,
          message: "Recurring settings saved successfully!",
          severity: "success",
        });
        // Update initial state to reflect new saved state
        setInitialRecurring(JSON.parse(JSON.stringify(recurring)));
        // Reset unsaved changes flag
        if (setHasUnsavedChanges) {
          setHasUnsavedChanges(false);
        }
      },
      onError: (error) => {
        setAlert({
          open: true,
          message: `Failed to save recurring settings: ${error.message}`,
          severity: "error",
        });
      },
    }
  );

  // Check if loading state should be active
  useEffect(() => {
    setLoading(eventTypeLoading);
  }, [eventTypeLoading]);

  // Check for errors
  useEffect(() => {
    if (eventTypeError) {
      setError(eventTypeError.message);
    } else {
      setError(null);
    }
  }, [eventTypeError]);

  // Check for unsaved changes
  useEffect(() => {
    if (initialRecurring) {
      const hasChanges =
        JSON.stringify(recurring) !== JSON.stringify(initialRecurring);
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(hasChanges);
      }
    }
  }, [recurring, initialRecurring, setHasUnsavedChanges]);

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  const handleRecurringToggle = (event) => {
    setRecurring({
      ...recurring,
      enabled: event.target.checked,
    });
  };

  const handleFrequencyChange = (event) => {
    setRecurring({
      ...recurring,
      frequency: parseInt(event.target.value) || 1,
    });
  };

  const handleUnitChange = (event) => {
    setRecurring({
      ...recurring,
      unit: event.target.value,
    });
  };

  const handleMaxOccurrencesChange = (event) => {
    setRecurring({
      ...recurring,
      maxOccurrences: parseInt(event.target.value) || 1,
    });
  };

  // Helper function to strip __typename fields
  const stripTypename = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(stripTypename);
    } else if (obj !== null && typeof obj === "object") {
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        if (key !== "__typename") {
          newObj[key] = stripTypename(obj[key]);
        }
      });
      return newObj;
    }
    return obj;
  };

  const saveRecurring = async () => {
    try {
      // Strip __typename fields before sending to the server
      const cleanRecurring = stripTypename(recurring);

      await updateRecurring({
        variables: {
          input: {
            id,
            recurring: cleanRecurring,
          },
        },
      });
    } catch (err) {
      console.error("Error saving recurring settings:", err);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ my: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Grid justifyContent="space-between" container spacing={10}>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom display="inline">
          Recurring
        </Typography>
        <Typography>
          Configure recurring settings for this event type
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={6}>
                  <Typography variant="subtitle1">
                    Enable recurring events
                    <Typography color="textSecondary" variant="body2">
                      Allow this event to repeat on a regular schedule
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={6} style={{ textAlign: "right" }}>
                  <Switch
                    checked={recurring.enabled}
                    onChange={handleRecurringToggle}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Grid>

          {recurring.enabled && (
            <Grid container spacing={6} style={{ marginTop: "15px" }}>
              <Grid item md={12}>
                <Grid container spacing={6}>
                  <Grid item md={2}>
                    <Typography style={{ position: "relative", top: "15px" }}>
                      Repeats every
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={recurring.frequency}
                      onChange={handleFrequencyChange}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FormControl fullWidth>
                      <InputLabel id="recurring-unit-label">Unit</InputLabel>
                      <Select
                        labelId="recurring-unit-label"
                        value={recurring.unit}
                        onChange={handleUnitChange}
                        label="Unit"
                      >
                        <MenuItem value="Week">Week</MenuItem>
                        <MenuItem value="Month">Month</MenuItem>
                        <MenuItem value="Year">Year</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={12}>
                <Grid container spacing={6}>
                  <Grid item md={2}>
                    <Typography style={{ position: "relative", top: "15px" }}>
                      For a maximum of
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={recurring.maxOccurrences}
                      onChange={handleMaxOccurrencesChange}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Typography style={{ position: "relative", top: "15px" }}>
                      occurrences
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={saveRecurring}
          disabled={
            saveLoading ||
            JSON.stringify(recurring) === JSON.stringify(initialRecurring)
          }
        >
          {saveLoading ? <CircularProgress size={24} /> : "Save"}
        </Button>

        {/* Alert for success/failure messages */}
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={alert.severity}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}

export default Recurring;
