import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  AlertTitle,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  TextField as MuiTextField,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { spacing } from "@mui/system";
import { axiosInstance } from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";
import axios from "axios"; // Assuming you're using axios to send the request

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [snackBarMsg, setSnackBarMsg] = useState("");

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      cNewPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Current password is required"),
      newPassword: Yup.string()
        .min(12, "New password must be at least 12 characters")
        .required("New password is required"),
      cNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm new password is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      setErrorMsg(""); // Clear any existing error messages at the beginning of submission

      try {
        // Send request to backend
        const response = await axiosInstance.post("/graphql", {
          query: `
          mutation {
            changePassword(
              currentPassword: "${values.password}", 
              newPassword: "${values.newPassword}"
            ) {
              success
              message
            }
          }
        `,
        });

        if (response.data.changePassword.success) {
          setSnackBarMsg("Password changed successfully!");
          resetForm();
        } else {
          setErrorMsg(response.data.changePassword.message);
        }
      } catch (error) {
        setErrorMsg("An error occurred while changing the password.");
      } finally {
        setLoading(false);
      }
    },
    validateOnMount: false,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const handleClose = () => {
    setSnackBarMsg("");
  };

  console.log("rrrrrrrrrrrr 00009");

  return (
    <Card mb={6}>
      <CardContent>
        {errorMsg && (
          <Alert mt={2} mb={3} severity="error">
            <AlertTitle>Error!!</AlertTitle>
            {errorMsg}
          </Alert>
        )}
        <form onSubmit={formik.handleSubmit}>
          <TextField
            type="password"
            label="Current Password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            fullWidth
            my={2}
          />
          <TextField
            type="password"
            label="New Password"
            name="newPassword"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            fullWidth
            my={2}
          />
          <TextField
            type="password"
            label="Confirm New Password"
            name="cNewPassword"
            value={formik.values.cNewPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.cNewPassword && Boolean(formik.errors.cNewPassword)
            }
            helperText={
              formik.touched.cNewPassword && formik.errors.cNewPassword
            }
            fullWidth
            my={2}
          />
          <Button
            variant="contained"
            color="primary"
            mt={3}
            type="submit"
            disabled={loading}
          >
            Save changes
            {loading && (
              <CircularProgress
                sx={{ color: "#fff" }}
                size={15}
                style={{ marginLeft: "10px" }}
              />
            )}
          </Button>
        </form>
        <Snackbar
          open={snackBarMsg}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackBarMsg}
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  );
}

export default ChangePassword;
