import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import EventTypeContext from "../../../contexts/EventTypeContext.js";
import EventHeader from "./EventHeader";
import RegistrationTab from "./RegistrationTab";
import { Grid, Typography, Button, Divider as MuiDivider } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

// Styled component
const Divider = styled(MuiDivider)(({ theme }) => ({
  margin: theme ? theme.spacing(6, 0) : "48px 0",
}));

// GraphQL query to get event type data
const GET_EVENT_TYPE = gql`
  query GetEventType($id: ID!) {
    getEventType(id: $id) {
      id
      title
      url
      description
      duration
      assignment
      availabilityId
    }
  }
`;

function EventTypeEdit() {
  // Get the event type ID from URL parameters
  const { id } = useParams();
  const navigate = useNavigate();

  // Create state for tracking unsaved changes and current tab
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [currentTab, setCurrentTab] = useState("Event Setup");

  // Fetch event type data
  const { loading, error, data } = useQuery(GET_EVENT_TYPE, {
    variables: { id },
    fetchPolicy: "network-only",
    skip: !id,
    onError: (error) => {
      console.error("Error fetching event type:", error);
      // Redirect to event types list if there's an error (e.g., ID not found)
      navigate("/calendar/event-types");
    },
  });

  // Use useCallback for stable function references
  const updateUnsavedChanges = useCallback((value) => {
    setHasUnsavedChanges(value);
  }, []);

  const updateCurrentTab = useCallback((tab) => {
    setCurrentTab(tab);
  }, []);

  // If ID is not provided, redirect to the event types list
  useEffect(() => {
    if (!id) {
      navigate("/calendar/event-types");
    }
  }, [id, navigate]);

  return (
    <EventTypeContext.Provider
      value={{
        hasUnsavedChanges,
        setHasUnsavedChanges: updateUnsavedChanges,
        currentTab,
        setCurrentTab: updateCurrentTab,
      }}
    >
      <React.Fragment>
        <Helmet title="Edit Event Type" />
        <Grid justifyContent="space-between" container spacing={10}>
          <Grid item>
            <Typography variant="h3" gutterBottom display="inline">
              Edit Event Type
              {!loading && data?.getEventType && (
                <Typography
                  variant="h5"
                  color="textSecondary"
                  display="inline"
                  style={{ marginLeft: "10px" }}
                >
                  {data.getEventType.title}
                </Typography>
              )}
            </Typography>
          </Grid>
          <Grid item>
            <div>
              <Button
                variant="contained"
                color="inherit"
                sx={{ mx: "0.5rem" }}
                component={Link}
                to="/calendar/event-types"
              >
                <ArrowBackIcon />
                Back
              </Button>
            </div>
          </Grid>
        </Grid>
        <Divider />
        <EventHeader eventTypeId={id} />
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <RegistrationTab />
          </Grid>
        </Grid>
      </React.Fragment>
    </EventTypeContext.Provider>
  );
}

export default EventTypeEdit;
