// src/pages/auth/ResetPassword.js
import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom"; // Import Link
import { Paper, Typography, Box } from "@mui/material";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import ResetPasswordComponent from "../../components/auth/ResetPassword";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  width: 100%;
  max-width: 450px; // Added max-width to make it slightly wider
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const ContentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

function ResetPassword() {
  return (
    <React.Fragment>
      <Link to="/">
        <Brand />
      </Link>
      <Wrapper>
        <ContentBox>
          <Helmet title="Reset Password" />
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Reset Password
          </Typography>
          <Typography component="h2" variant="body1" align="center" mb={3}>
            Enter your email to reset your password
          </Typography>
          <ResetPasswordComponent />
        </ContentBox>
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
