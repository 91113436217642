import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../utils/axios";

import {
  Typography,
  Grid,
  TextField,
  Button,
  Switch,
  ListItem,
  ListItemText,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DURATION_OPTIONS = [
  "5 mins",
  "10 mins",
  "15 mins",
  "20 mins",
  "25 mins",
  "30 mins",
  "35 mins",
  "40 mins",
  "45 mins",
  "50 mins",
  "60 mins",
];

function EventSetup() {
  const { id } = useParams();
  const [eventData, setEventData] = useState({
    title: "",
    url: "",
    description: "",
    duration: "",
    allowMultipleDurations: false,
    availableDurations: [],
  });
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Fetch event type data when component mounts
  useEffect(() => {
    const fetchEventTypeData = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query {
              getEventType(id: "${id}") {
                id
                title
                url
                description
                duration
                assignment
              }
            }
          `,
        });

        if (response.data.errors) {
          console.error("GraphQL errors:", response.data.errors);
          setAlert({
            open: true,
            message: "Error loading event data",
            severity: "error",
          });
        } else {
          const data = response.data.getEventType;
          setEventData({
            title: data.title || "",
            url: data.url || "",
            description: data.description || "",
            duration: data.duration || "",
            allowMultipleDurations: false, // This would need to be stored in your backend
            availableDurations: [], // This would need to be stored in your backend
          });
        }
      } catch (error) {
        console.error("Error fetching event type:", error);
        setAlert({
          open: true,
          message: "Failed to load event data",
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchEventTypeData();
    } else {
      setLoading(false);
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDescriptionChange = (content) => {
    setEventData((prev) => ({
      ...prev,
      description: content,
    }));
  };

  const handleMultipleDurationsToggle = (event) => {
    setEventData((prev) => ({
      ...prev,
      allowMultipleDurations: event.target.checked,
    }));
  };

  const handleDurationsChange = (event) => {
    const {
      target: { value },
    } = event;
    setEventData((prev) => ({
      ...prev,
      availableDurations: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  const handleSave = async () => {
    try {
      // Determine what to use for duration based on the multiple durations toggle
      const finalDuration = eventData.allowMultipleDurations
        ? eventData.availableDurations.length > 0
          ? parseInt(eventData.availableDurations[0].split(" ")[0])
          : parseInt(eventData.duration)
        : parseInt(eventData.duration);

      const response = await axiosInstance.post("/graphql", {
        query: `
          mutation {
            updateEventType(input: {
              id: "${id}",
              title: "${eventData.title}",
              url: "${eventData.url}",
              description: "${eventData.description.replace(/"/g, '\\"')}",
              duration: ${finalDuration}
            }) {
              eventType {
                id
                title
                url
                description
                duration
              }
            }
          }
        `,
      });

      if (response.data.errors) {
        console.error("GraphQL errors:", response.data.errors);
        setAlert({
          open: true,
          message: "Error updating event data",
          severity: "error",
        });
      } else {
        setAlert({
          open: true,
          message: "Event updated successfully",
          severity: "success",
        });
      }
    } catch (error) {
      console.error("Error updating event type:", error);
      setAlert({
        open: true,
        message: "Failed to update event data",
        severity: "error",
      });
    }
  };

  if (loading) {
    return <Typography>Loading event data...</Typography>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom>
          Event Setup
        </Typography>
        <Typography variant="body1" gutterBottom>
          Configure what information should be captured during registration
        </Typography>
      </Grid>

      <Grid item md={6} xs={12}>
        <TextField
          label="Title"
          name="title"
          value={eventData.title}
          onChange={handleInputChange}
          placeholder="Give your event an exciting name"
          variant="outlined"
          fullWidth
          margin="normal"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <TextField
          label="URL"
          name="url"
          value={eventData.url}
          onChange={handleInputChange}
          placeholder="Type your unique url"
          variant="outlined"
          fullWidth
          margin="normal"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2" gutterBottom>
          Description
        </Typography>
        <QuillWrapper>
          <ReactQuill
            theme="snow"
            value={eventData.description}
            onChange={handleDescriptionChange}
            placeholder="Type something..."
          />
        </QuillWrapper>
      </Grid>

      {!eventData.allowMultipleDurations ? (
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <TextField
            label="Duration"
            name="duration"
            value={eventData.duration}
            onChange={handleInputChange}
            placeholder="In Minutes"
            variant="outlined"
            fullWidth
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button disabled>Minutes</Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ) : (
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <FormControl fullWidth>
            <InputLabel id="durations-select-label">
              Available Durations
            </InputLabel>
            <Select
              labelId="durations-select-label"
              id="durations-select"
              multiple
              value={eventData.availableDurations}
              onChange={handleDurationsChange}
              input={<OutlinedInput label="Available Durations" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {DURATION_OPTIONS.map((duration) => (
                <MenuItem key={duration} value={duration}>
                  <Checkbox
                    checked={
                      eventData.availableDurations.indexOf(duration) > -1
                    }
                  />
                  <ListItemText primary={duration} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12} style={{ marginTop: "10px" }}>
        <ListItem alignItems="flex-start" disablePadding>
          <Grid container alignItems="center">
            <Grid item xs={12} md={6}>
              <ListItemText primary="Allow booker to select duration" />
            </Grid>
            <Grid item xs={12} md={6}>
              <Switch
                checked={eventData.allowMultipleDurations}
                onChange={handleMultipleDurationsToggle}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
          </Grid>
        </ListItem>
      </Grid>

      <Grid item xs={12} style={{ marginTop: "20px" }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Grid>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default EventSetup;
