// Determine server URI based on current URL
const getServerURI = () => {
  const hostname = window.location.hostname;
  console.log("log 038", hostname);
  if (
    hostname == "localhost" ||
    hostname == "127.0.0.1" ||
    hostname == "laptop"
  ) {
    return process.env.REACT_APP_LOCAL_BACKEND_URI;
  } else if (hostname == "app.instaconnect.io") {
    return process.env.REACT_APP_BACKEND_URI;
  } else {
    // Default fallback to the environment variable
    return process.env.REACT_SERVER_URI;
  }
};

const getRedirectURI = () => {
  const hostname = window.location.hostname;
  if (hostname == "localhost" || hostname == "127.0.0.1") {
    return process.env.REACT_APP_LOCAL_FRONTEND_URI;
  } else if (hostname == "app.instaconnect.io") {
    return process.env.REACT_APP_MAIN_DOMAIN_SERVER_URI;
  } else {
    // Default fallback to the environment variable
    return process.env.REACT_SERVER_URI;
  }
};

export const serverConfig = {
  serverURI: getServerURI(),
};

export const redirectConfig = {
  redirectURI: getRedirectURI(),
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

// Export other environment variables that might be needed
export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const microsoftClientId = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
