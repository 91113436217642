import React, { createContext } from "react";

// Create a single shared context instance
const EventTypeContext = createContext({
  hasUnsavedChanges: false,
  setHasUnsavedChanges: () => {},
  currentTab: "Event Setup",
  setCurrentTab: () => {},
});

export default EventTypeContext;
