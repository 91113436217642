import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { axiosInstance } from "../../../utils/axios";
import { DataGrid } from "@mui/x-data-grid";
import { alpha } from "@mui/material/styles";
import SearchBar from "../../components/SearchBar";

import {
  Alert,
  TextField as MuiTextField,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  TablePagination,
  Toolbar,
  Tooltip,
  Typography,
  Snackbar,
  Box,
  Card as MuiCard,
  CardContent,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { green } from "@mui/material/colors";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  background: ${green[500]};
  color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Team Card Component for Card View
const TeamCard = ({ team, isSelected, onSelect, serialNumber }) => {
  return (
    <Paper>
      <Card>
        <div style={{ position: "relative" }}>
          <div
            style={{
              backgroundColor: "#f0f0f0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "140px",
            }}
          >
            <Typography variant="h2" color="textSecondary">
              {team.name.charAt(0).toUpperCase()}
            </Typography>
          </div>
          <Checkbox
            checked={isSelected}
            onChange={(e) => onSelect(e, team.id)}
            style={{ position: "absolute", top: "8px", left: "8px" }}
          />
        </div>
        <CardContent>
          <Box>
            <Typography variant="h5" gutterBottom>
              {team.name}
            </Typography>
            <Box display="flex" alignItems="center" mb={1}>
              <Chip
                size="small"
                label="Active"
                sx={{ bgcolor: "primary.main", mr: 1 }}
              />
              <Typography variant="body2" color="textSecondary">
                S.No: {serialNumber}
              </Typography>
            </Box>
          </Box>
          <Typography mb={1} color="textSecondary" component="p">
            URL: {team.url}
          </Typography>
          {team.about && (
            <Typography variant="body2" color="textSecondary">
              {team.about.length > 100
                ? `${team.about.substring(0, 100)}...`
                : team.about}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Paper>
  );
};

function EnhancedTableToolbar(props) {
  const { numSelected, onBulkDelete, onBulkEdit, viewStyle, setViewStyle } =
    props;

  const handleViewClick = () => {
    setViewStyle(viewStyle === "List" ? "Card" : "List");
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "70px",
        borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
      }}
    >
      <div>
        {numSelected > 0 && (
          <Typography
            color="inherit"
            variant="subtitle1"
            sx={{ fontWeight: 500 }}
          >
            {numSelected} selected
          </Typography>
        )}
      </div>

      <Box>
        {numSelected > 0 ? (
          <Box display="flex">
            {numSelected === 1 && (
              <Tooltip title="Edit">
                <IconButton
                  aria-label="Edit"
                  size="large"
                  onClick={onBulkEdit}
                  sx={{ mr: 1 }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                size="large"
                onClick={onBulkDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Tooltip
            title={
              viewStyle === "List"
                ? "Change to Card View"
                : "Change to List View"
            }
          >
            <IconButton
              color="primary"
              onClick={handleViewClick}
              size="medium"
              sx={{
                borderRadius: "8px",
                p: 1,
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.08),
                "&:hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.12),
                },
              }}
            >
              {viewStyle === "List" ? <ViewModuleIcon /> : <ViewListIcon />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Toolbar>
  );
}

const CREATE_TEAM_MUTATION = `
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      team {
        id
        name
        url
        about
      }
    }
  }
`;

const UPDATE_TEAM_MUTATION = `
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      team {
        id
        name
        url
        about
      }
    }
  }
`;

function Teams() {
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [teams, setTeams] = useState([]);
  const [editTeam, setEditTeam] = useState(null);

  // States for enhanced UI
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [viewStyle, setViewStyle] = useState("List");
  const [searchQuery, setSearchQuery] = useState("");
  const [bulkDeleteDialog, setBulkDeleteDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    team: null,
  });

  // Reset page when search query changes
  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter teams based on search query
  const filteredTeams = teams.filter((team) => {
    if (!searchQuery) return true;

    const query = searchQuery.toLowerCase();
    return (
      team.name.toLowerCase().includes(query) ||
      team.url.toLowerCase().includes(query) ||
      team.id.toString().toLowerCase().includes(query) ||
      (team.about && team.about.toLowerCase().includes(query))
    );
  });

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  const handleClickOpen = () => {
    setEditTeam(null); // Clear any edit state
    setOpenDialog(true);
  };

  const handleEditTeam = (team) => {
    setEditTeam(team);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setEditTeam(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = teams.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteClick = (team) => {
    setDeleteDialog({ open: true, team });
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: `
          mutation DeleteTeam($id: ID!) {
            deleteTeam(id: $id)
          }
        `,
        variables: { id: deleteDialog.team.id },
      });

      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }

      // Remove from the list
      setTeams((prevTeams) =>
        prevTeams.filter((item) => item.id !== deleteDialog.team.id)
      );

      setAlert({
        open: true,
        message: "Team deleted successfully",
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: error.message || "Error deleting team",
        severity: "error",
      });
    } finally {
      setDeleteDialog({ open: false, team: null });
    }
  };

  const handleBulkDelete = () => {
    setBulkDeleteDialog(true);
  };

  const handleBulkEdit = () => {
    if (selected.length !== 1) {
      setAlert({
        open: true,
        message: "Please select exactly one team to edit",
        severity: "info",
      });
      return;
    }

    // Find the selected team
    const teamToEdit = teams.find((team) => team.id === selected[0]);
    handleEditTeam(teamToEdit);
  };

  const handleBulkDeleteConfirm = async () => {
    try {
      // Execute delete mutations for all selected teams
      const deletePromises = selected.map((id) =>
        axiosInstance.post("/graphql", {
          query: `
            mutation DeleteTeam($id: ID!) {
              deleteTeam(id: $id)
            }
          `,
          variables: { id },
        })
      );

      await Promise.all(deletePromises);

      // Remove deleted items from the list
      setTeams((prevTeams) =>
        prevTeams.filter((item) => !selected.includes(item.id))
      );

      setAlert({
        open: true,
        message: `Successfully deleted ${selected.length} teams`,
        severity: "success",
      });

      // Clear selections
      setSelected([]);
    } catch (error) {
      setAlert({
        open: true,
        message: `Error deleting teams: ${error.message}`,
        severity: "error",
      });
    } finally {
      setBulkDeleteDialog(false);
    }
  };

  // Fetch teams initially
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query {
              getTeams {
                id
                name
                url
                about
              }
            }
          `,
        });

        if (response.data.errors) {
          console.error(response.data.errors);
          setAlert({
            open: true,
            message: "Failed to load teams",
            severity: "error",
          });
        } else {
          setTeams(response.data.getTeams || []);
        }
      } catch (error) {
        console.error("Error fetching teams:", error);
        setAlert({
          open: true,
          message: "Failed to load teams",
          severity: "error",
        });
      }
    };

    fetchTeams();
  }, []);

  // Render card view
  const renderCardView = () => {
    const paginatedTeams = stableSort(
      filteredTeams,
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
      <>
        <Box px={2} py={2} display="flex" justifyContent="flex-end">
          <Checkbox
            indeterminate={
              selected.length > 0 && selected.length < filteredTeams.length
            }
            checked={
              filteredTeams.length > 0 &&
              selected.length === filteredTeams.length
            }
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all teams" }}
          />
          <Typography
            variant="body2"
            style={{ marginLeft: "8px", alignSelf: "center" }}
          >
            Select All
          </Typography>
        </Box>
        <Box m={1} pt={1}>
          <Grid container spacing={4}>
            {paginatedTeams.map((team, index) => (
              <Grid item key={team.id} xs={12} sm={6} md={4} lg={3}>
                <TeamCard
                  team={team}
                  isSelected={isSelected(team.id)}
                  onSelect={handleClick}
                  serialNumber={page * rowsPerPage + index + 1}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px 0",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredTeams.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  marginBottom: "0",
                  display: "flex",
                  alignItems: "center",
                },
              ".MuiTablePagination-select": {
                marginRight: "32px",
                marginLeft: "8px",
              },
            }}
          />
        </Box>
      </>
    );
  };

  // Render table view with DataGrid
  const renderTableView = () => {
    // Get paginated data with pre-calculated serial numbers
    const paginatedTeams = stableSort(
      filteredTeams,
      getComparator(order, orderBy)
    )
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((team, index) => ({
        ...team,
        serialNumber: page * rowsPerPage + index + 1,
        createdAtFormatted: "-",
        aboutExcerpt: team.about
          ? team.about.length > 50
            ? `${team.about.substring(0, 50)}...`
            : team.about
          : "",
      }));

    // Define columns for DataGrid
    const columns = [
      {
        field: "select",
        headerName: "",
        width: 50,
        sortable: false,
        filterable: false,
        headerClassName: "select-all-header",
        renderHeader: () => (
          <Checkbox
            indeterminate={
              selected.length > 0 && selected.length < filteredTeams.length
            }
            checked={
              filteredTeams.length > 0 &&
              selected.length === filteredTeams.length
            }
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all teams" }}
          />
        ),
        renderCell: (params) => (
          <Checkbox
            checked={isSelected(params.row.id)}
            onClick={(event) => handleClick(event, params.row.id)}
            inputProps={{ "aria-labelledby": `select-${params.row.id}` }}
          />
        ),
      },
      {
        field: "serialNumber",
        headerName: "S.No",
        width: 70,
        sortable: false,
        filterable: false,
      },
      {
        field: "id",
        headerName: "ID",
        width: 80,
        renderCell: (params) => (
          <Typography variant="body2">#{params.row.id}</Typography>
        ),
      },
      {
        field: "name",
        headerName: "Team Name",
        flex: 1,
      },
      {
        field: "url",
        headerName: "URL",
        flex: 1,
      },
      {
        field: "status",
        headerName: "Status",
        width: 120,
        renderCell: () => (
          <Chip size="small" label="Active" sx={{ bgcolor: "primary.main" }} />
        ),
      },
      {
        field: "createdAtFormatted",
        headerName: "Creation Date",
        width: 150,
      },
    ];

    return (
      <>
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={paginatedTeams}
            columns={columns}
            hideFooter={true}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            checkboxSelection={false}
            rowHeight={70}
            headerHeight={56}
            getRowId={(row) => row.id}
            components={{
              Toolbar: () => null,
            }}
            sortingMode="server"
            sortModel={[
              {
                field: orderBy,
                sort: order.toLowerCase(),
              },
            ]}
            onSortModelChange={(model) => {
              if (model.length > 0) {
                setOrderBy(model[0].field);
                setOrder(model[0].sort === "asc" ? "asc" : "desc");
              }
            }}
            sx={{
              boxShadow: 0,
              border: 0,
              "& .MuiDataGrid-cell": {
                padding: "8px 16px",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "8px 16px",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredTeams.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-toolbar": {
                alignItems: "center",
                minHeight: "40px",
                padding: "0 8px",
              },
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                },
              ".MuiTablePagination-select": {
                marginRight: "8px",
                marginLeft: "8px",
              },
              ".MuiTablePagination-actions": {
                display: "flex",
                alignItems: "center",
              },
            }}
          />
        </Box>
      </>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Teams" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Teams
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/private">
              Spaces
            </Link>
            <Link component={NavLink} to="/calendar/dashboard">
              Calendar
            </Link>
            <Typography color="text.secondary">Teams</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            startIcon={<AddIcon />}
          >
            Add Team
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <SearchBar
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Search teams..."
        containerSx={{
          borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
        }}
      />

      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onBulkDelete={handleBulkDelete}
          onBulkEdit={handleBulkEdit}
          viewStyle={viewStyle}
          setViewStyle={setViewStyle}
        />

        {/* Toggle between view styles */}
        {viewStyle === "List" ? renderTableView() : renderCardView()}
      </Paper>

      {/* Add/Edit Team Dialog */}
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>
          {editTeam ? "Edit Team" : "Create a new Team"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editTeam
              ? "Update the team details below."
              : "To add a new Team, please fill in the details below."}
          </DialogContentText>

          <Formik
            initialValues={{
              name: editTeam?.name || "",
              about: editTeam?.about || "",
              url: editTeam?.url || "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required"),
              url: Yup.string().required("Team URL is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const { name, about, url } = values;

                if (editTeam) {
                  // Update existing team
                  const response = await axiosInstance.post("/graphql", {
                    query: UPDATE_TEAM_MUTATION,
                    variables: {
                      input: {
                        id: editTeam.id,
                        name,
                        about,
                        url,
                      },
                    },
                  });

                  if (response.data.errors) {
                    throw new Error(response.data.errors[0].message);
                  }

                  const updatedTeam = response.data.updateTeam.team;

                  // Update the teams array with the updated team
                  setTeams(
                    teams.map((team) =>
                      team.id === updatedTeam.id ? updatedTeam : team
                    )
                  );

                  setAlert({
                    open: true,
                    message: "Team Updated Successfully!",
                    severity: "success",
                  });
                } else {
                  // Create new team
                  const response = await axiosInstance.post("/graphql", {
                    query: CREATE_TEAM_MUTATION,
                    variables: {
                      input: {
                        name,
                        about,
                        url,
                      },
                    },
                  });

                  if (response.data.errors) {
                    throw new Error(response.data.errors[0].message);
                  }

                  const newTeam = response.data.createTeam.team;
                  setTeams((prevTeams) => [...prevTeams, newTeam]);

                  setAlert({
                    open: true,
                    message: "Team Added Successfully!",
                    severity: "success",
                  });
                }

                handleClose(); // Close dialog
              } catch (error) {
                const errorMessage =
                  error.response?.data?.message ||
                  error.message ||
                  "An unexpected error occurred";

                setAlert({
                  open: true,
                  message: errorMessage,
                  severity: "error",
                });

                setStatus({ success: false });
                setErrors({ submit: errorMessage });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                <TextField
                  type="text"
                  name="name"
                  label="Name"
                  value={values.name}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <TextField
                  type="text"
                  name="url"
                  label="Team Url"
                  value={values.url}
                  error={Boolean(touched.url && errors.url)}
                  fullWidth
                  helperText={touched.url && errors.url}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <TextField
                  type="text"
                  name="about"
                  label="About"
                  value={values.about}
                  error={Boolean(touched.about && errors.about)}
                  fullWidth
                  helperText={touched.about && errors.about}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                  multiline
                  rows={4}
                />
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    color="primary"
                    variant="contained"
                  >
                    {editTeam ? "Update" : "Add"}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({ open: false, team: null })}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the team "{deleteDialog.team?.name}
            "? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog({ open: false, team: null })}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Bulk Delete Dialog */}
      <Dialog
        open={bulkDeleteDialog}
        onClose={() => setBulkDeleteDialog(false)}
      >
        <DialogTitle>Confirm Bulk Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {selected.length} selected teams?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBulkDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={handleBulkDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete {selected.length} Teams
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alert Snackbar */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default Teams;
