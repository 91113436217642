import React from "react";
import styled from "@emotion/styled";
import { Box, CircularProgress, Typography } from "@mui/material";

const LoaderWrapper = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.background.paper};
  z-index: 9999;
`;

const LogoPlaceholder = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(3)};
  font-size: ${(props) => props.theme.typography.h4.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const FullScreenLoader = ({ logo }) => {
  return (
    <LoaderWrapper>
      {logo ? logo : <LogoPlaceholder>Mira</LogoPlaceholder>}
      <CircularProgress size={40} thickness={4} />
      <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
        Loading application...
      </Typography>
    </LoaderWrapper>
  );
};

export default FullScreenLoader;
