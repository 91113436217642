import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { axiosInstance } from "../../utils/axios";
import useAuth from "../../hooks/useAuth";
import { useSavedPermissions } from "../../utils/permissionUtils";
import { PERMISSIONS } from "../../utils/permissionConstants";
import { DataGrid } from "@mui/x-data-grid";
import { alpha } from "@mui/material/styles";
import { Search as SearchIcon } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import SearchBar from "../components/SearchBar";

import {
  Alert,
  TextField as MuiTextField,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  TextField,
  TablePagination,
  Toolbar,
  Tooltip,
  Typography,
  Snackbar,
  Checkbox,
  Card as MuiCard,
  CardContent,
  CardMedia as MuiCardMedia,
  Box,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const Card = styled(MuiCard)(spacing);
const CardMedia = styled(MuiCardMedia)`
  height: 140px;
`;

const GET_SPACES = `
  query {
    getSpaces {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const CREATE_SPACE = `
  mutation CreateSpace($input: CreateSpaceInput!) {
    createSpace(input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_SPACE = `
  mutation UpdateSpace($id: ID!, $name: String!) {
    updateSpace(id: $id, name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const DELETE_SPACE = `
  mutation DeleteSpace($id: ID!) {
    deleteSpace(id: $id)
  }
`;

// Project/Space Card Component
const SpaceCard = ({ space, isSelected, onSelect, serialNumber }) => {
  return (
    <Paper>
      <Card>
        <div style={{ position: "relative" }}>
          <CardMedia
            component="div"
            sx={{
              backgroundColor: "#f0f0f0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "140px",
            }}
          >
            <Typography variant="h2" color="textSecondary">
              {space.name.charAt(0).toUpperCase()}
            </Typography>
          </CardMedia>
          <Checkbox
            checked={isSelected}
            onChange={(e) => onSelect(e, space.id)}
            style={{ position: "absolute", top: "8px", left: "8px" }}
          />
        </div>
        <CardContent>
          <Box>
            <Typography variant="h5" gutterBottom>
              {space.name}
            </Typography>
          </Box>
          <Typography mb={2} color="textSecondary" component="p">
            S.No: {serialNumber}
            <br />
            Created: {formatDate(space.createdAt)}
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
};

const formatDate = (dateString) => {
  if (!dateString) return "";
  try {
    const date = new Date(parseInt(dateString));
    if (isNaN(date.getTime())) {
      const isoDate = new Date(dateString);
      if (isNaN(isoDate.getTime())) return "Invalid Date";
      return new Intl.DateTimeFormat("default", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }).format(isoDate);
    }
    return new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Invalid Date";
  }
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    onBulkDelete,
    onBulkEdit,
    canDelete,
    canUpdate,
    viewStyle,
    setViewStyle,
  } = props;

  const handleViewClick = () => {
    setViewStyle(viewStyle === "List" ? "Card" : "List");
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "70px",
        borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
      }}
    >
      {/* Left side of toolbar - Only shows selection count when items selected */}
      <div>
        {numSelected > 0 && (
          <Typography
            color="inherit"
            variant="subtitle1"
            sx={{ fontWeight: 500 }}
          >
            {numSelected} selected
          </Typography>
        )}
      </div>

      {/* Right side - Action buttons */}
      <Box>
        {numSelected > 0 ? (
          <Box display="flex">
            {numSelected === 1 && canUpdate && (
              <Tooltip title="Edit">
                <IconButton
                  aria-label="Edit"
                  size="large"
                  onClick={onBulkEdit}
                  sx={{ mr: 1 }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {canDelete && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="Delete"
                  size="large"
                  onClick={onBulkDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ) : (
          <Tooltip
            title={
              viewStyle === "List"
                ? "Change to Card View"
                : "Change to List View"
            }
          >
            <IconButton
              color="primary"
              onClick={handleViewClick}
              size="medium"
              sx={{
                borderRadius: "8px",
                p: 1,
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.08),
                "&:hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.12),
                },
              }}
            >
              {viewStyle === "List" ? <ViewModuleIcon /> : <ViewListIcon />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Toolbar>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function Spaces() {
  // Auth and permissions
  const { user } = useAuth();
  const savedPermissions = useSavedPermissions();
  const isPremium = user?.subscriptionLevel === "1";

  // Permission checks
  const canViewSpaces =
    isPremium || savedPermissions.includes(PERMISSIONS.SPACES.childrens[0]);
  const canAddSpaces =
    isPremium || savedPermissions.includes(PERMISSIONS.SPACES.childrens[1]);
  const canUpdateSpaces =
    isPremium || savedPermissions.includes(PERMISSIONS.SPACES.childrens[2]);
  const canDeleteSpaces =
    isPremium || savedPermissions.includes(PERMISSIONS.SPACES.childrens[3]);

  const [spaces, setSpaces] = useState([]);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingSpace, setEditingSpace] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  // Add this useEffect to reset pagination when search query changes
  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // Add this filtering logic (place it after your state declarations)
  const filteredSpaces = spaces.filter((space) => {
    if (!searchQuery) return true;

    const query = searchQuery.toLowerCase();
    return (
      space.name.toLowerCase().includes(query) ||
      space.id.toString().toLowerCase().includes(query)
    );
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    spaceId: null,
    spaceName: null,
  });
  const [bulkDeleteConfirmation, setBulkDeleteConfirmation] = useState({
    open: false,
    count: 0,
  });
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  // Add view style state
  const [viewStyle, setViewStyle] = useState("List");

  useEffect(() => {
    if (canViewSpaces) {
      fetchSpaces();
    }
  }, [canViewSpaces]);

  const fetchSpaces = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: GET_SPACES,
      });

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      const sortedSpaces = response.data.getSpaces.sort((a, b) => {
        const dateA = new Date(parseInt(a.createdAt));
        const dateB = new Date(parseInt(b.createdAt));
        return dateB - dateA;
      });

      setSpaces(sortedSpaces);
    } catch (error) {
      setAlert({
        open: true,
        message: error.message || "Error fetching spaces",
        severity: "error",
      });
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = spaces.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (space) => {
    if (!canUpdateSpaces) {
      setAlert({
        open: true,
        message: "You don't have permission to edit spaces",
        severity: "warning",
      });
      return;
    }
    setEditingSpace(space);
    setOpenDialog(true);
  };

  const handleAddClick = () => {
    if (!canAddSpaces) {
      setAlert({
        open: true,
        message: "You don't have permission to add spaces",
        severity: "warning",
      });
      return;
    }
    setEditingSpace(null);
    setOpenDialog(true);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmation({
      open: false,
      spaceId: null,
      spaceName: null,
    });
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: DELETE_SPACE,
        variables: { id: deleteConfirmation.spaceId },
      });

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      setSpaces(
        spaces.filter((space) => space.id !== deleteConfirmation.spaceId)
      );
      setAlert({
        open: true,
        message: "Space deleted successfully",
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: error.message || "Error deleting space",
        severity: "error",
      });
    } finally {
      handleCancelDelete();
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingSpace(null);
  };

  const handleBulkDeleteClick = () => {
    if (!canDeleteSpaces) {
      setAlert({
        open: true,
        message: "You don't have permission to delete spaces",
        severity: "warning",
      });
      return;
    }
    setBulkDeleteConfirmation({
      open: true,
      count: selected.length,
    });
  };

  const handleCancelBulkDelete = () => {
    setBulkDeleteConfirmation({
      open: false,
      count: 0,
    });
  };

  const handleConfirmBulkDelete = async () => {
    console.log("Starting bulk delete operation");
    try {
      // Track failed deletions to show in the error message
      const failedDeletions = [];
      const successfulDeletions = [];

      // Process each deletion sequentially to better handle errors
      for (const id of selected) {
        try {
          const response = await axiosInstance.post("/graphql", {
            query: DELETE_SPACE,
            variables: { id },
          });

          console.log(`Delete response for space ${id}:`, response);

          // Check for GraphQL errors in the response
          if (response.errors) {
            const errorMessage = response.errors[0]?.message || "Unknown error";
            console.error(`Error deleting space ${id}:`, errorMessage);
            failedDeletions.push({ id, error: errorMessage });
          }
          // Check for successful deletion
          else if (response.data && response.data.deleteSpace === true) {
            // Successful deletion
            console.log(`Successfully deleted space ${id}`);
            successfulDeletions.push(id);
          }
          // Handle case where API returns false
          else if (response.data && response.data.deleteSpace === false) {
            failedDeletions.push({
              id,
              error: "Operation returned false - space may be protected",
            });
          }
          // Neither success nor explicit error detected
          else {
            console.error(
              `Unexpected response format for space ${id}:`,
              response
            );
            failedDeletions.push({
              id,
              error: "Unexpected API response format",
            });
          }
        } catch (err) {
          // Handle network errors or other exceptions
          console.error(`Network error deleting space ${id}:`, err);
          failedDeletions.push({ id, error: err.message });
        }
      }

      // Update the UI based on results
      if (failedDeletions.length === 0) {
        // All deletions were successful
        setSpaces(
          spaces.filter((space) => !successfulDeletions.includes(space.id))
        );
        setAlert({
          open: true,
          message: `Successfully deleted ${successfulDeletions.length} spaces`,
          severity: "success",
        });
      } else if (successfulDeletions.length === 0) {
        // All deletions failed
        setAlert({
          open: true,
          message: `Failed to delete spaces: ${failedDeletions
            .map((f) => f.error)
            .join("; ")}`,
          severity: "error",
        });
      } else {
        // Mixed results
        setSpaces(
          spaces.filter((space) => !successfulDeletions.includes(space.id))
        );
        setAlert({
          open: true,
          message: `Deleted ${
            successfulDeletions.length
          } spaces. Failed to delete ${
            failedDeletions.length
          } spaces: ${failedDeletions.map((f) => f.error).join("; ")}`,
          severity: "warning",
        });
      }
    } catch (error) {
      // Handle any unexpected errors in the overall process
      console.error("Unexpected error in bulk delete:", error);
      setAlert({
        open: true,
        message: `Error deleting spaces: ${error.message}`,
        severity: "error",
      });
    } finally {
      // Clear selected items regardless of outcome
      setSelected([]);
      handleCancelBulkDelete();
    }
  };

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    try {
      const mutation = editingSpace ? UPDATE_SPACE : CREATE_SPACE;
      const variables = editingSpace
        ? { id: editingSpace.id, name: values.name }
        : { input: { name: values.name } };

      const response = await axiosInstance.post("/graphql", {
        query: mutation,
        variables,
      });

      if (response.errors) {
        const errorMessage = response.errors[0].message;
        throw new Error(errorMessage);
      }

      const newSpace = editingSpace
        ? response.data.updateSpace
        : response.data.createSpace;

      setSpaces((prev) =>
        editingSpace
          ? prev.map((space) =>
              space.id === editingSpace.id ? newSpace : space
            )
          : [...prev, newSpace]
      );

      // Clear the selected items after successful update
      setSelected([]);

      setAlert({
        open: true,
        message: `Space ${editingSpace ? "updated" : "created"} successfully`,
        severity: "success",
      });

      handleCloseDialog();
    } catch (error) {
      setAlert({
        open: true,
        message:
          error.message ||
          `Error ${editingSpace ? "updating" : "creating"} space`,
        severity: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Render card view
  const renderCardView = () => {
    const paginatedSpaces = stableSort(
      filteredSpaces,
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
      <>
        <Box px={2} py={2} display="flex" justifyContent="flex-end">
          <Checkbox
            indeterminate={
              selected.length > 0 && selected.length < filteredSpaces.length
            }
            checked={
              filteredSpaces.length > 0 &&
              selected.length === filteredSpaces.length
            }
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all spaces" }}
          />
          <Typography
            variant="body2"
            style={{ marginLeft: "8px", alignSelf: "center" }}
          >
            Select All
          </Typography>
        </Box>
        <Box m={1} pt={1}>
          <Grid container spacing={4}>
            {paginatedSpaces.map((space, index) => (
              <Grid item key={space.id} xs={12} sm={6} md={4} lg={3}>
                <SpaceCard
                  space={space}
                  isSelected={isSelected(space.id)}
                  onSelect={handleClick}
                  serialNumber={page * rowsPerPage + index + 1}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px 0",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredSpaces.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  marginBottom: "0",
                  display: "flex",
                  alignItems: "center",
                },
              ".MuiTablePagination-select": {
                marginRight: "32px",
                marginLeft: "8px",
              },
            }}
          />
        </div>
      </>
    );
  };

  // Render table view with DataGrid

  const renderTableView = () => {
    // Get paginated data with pre-calculated serial numbers
    const paginatedSpaces = stableSort(
      filteredSpaces,
      getComparator(order, orderBy)
    )
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((space, index) => ({
        ...space,
        serialNumber: page * rowsPerPage + index + 1,
      }));

    // Define columns for DataGrid - removed action column
    const columns = [
      {
        field: "select",
        headerName: "",
        width: 50,
        sortable: false,
        filterable: false,
        headerClassName: "select-all-header",
        renderHeader: () => (
          <Checkbox
            indeterminate={
              selected.length > 0 && selected.length < filteredSpaces.length
            }
            checked={
              filteredSpaces.length > 0 &&
              selected.length === filteredSpaces.length
            }
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all spaces" }}
          />
        ),
        renderCell: (params) => (
          <Checkbox
            checked={isSelected(params.row.id)}
            onClick={(event) => handleClick(event, params.row.id)}
            inputProps={{ "aria-labelledby": `select-${params.row.id}` }}
          />
        ),
      },
      {
        field: "serialNumber",
        headerName: "S.No",
        width: 80,
        sortable: false,
        filterable: false,
      },
      { field: "name", headerName: "Name", flex: 1 },
      {
        field: "createdAt",
        headerName: "Created At",
        flex: 1.5,
        valueFormatter: (params) => formatDate(params.value),
      },
    ];

    return (
      <>
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={paginatedSpaces}
            columns={columns}
            hideFooter={true}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            checkboxSelection={false}
            rowHeight={70}
            headerHeight={56}
            components={{
              Toolbar: () => null,
            }}
            sortingMode="server"
            sortModel={[
              {
                field: orderBy,
                sort: order.toLowerCase(),
              },
            ]}
            onSortModelChange={(model) => {
              if (model.length > 0) {
                setOrderBy(model[0].field);
                setOrder(model[0].sort === "asc" ? "asc" : "desc");
              }
            }}
            sx={{
              boxShadow: 0,
              border: 0,
              "& .MuiDataGrid-cell": {
                padding: "8px 16px",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "8px 16px",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredSpaces.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-toolbar": {
                alignItems: "center",
                minHeight: "40px",
                padding: "0 8px",
              },
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                },
              ".MuiTablePagination-select": {
                marginRight: "8px",
                marginLeft: "8px",
              },
              ".MuiTablePagination-actions": {
                display: "flex",
                alignItems: "center",
              },
            }}
          />
        </Box>
      </>
    );
  };

  // If user doesn't have view permission, show warning
  if (!canViewSpaces) {
    return (
      <React.Fragment>
        <Helmet title="Spaces" />
        <Typography variant="h3" gutterBottom>
          Spaces
        </Typography>
        {/* <Alert severity="warning"> */}
        {/*   You don't have permission to view this page. */}
        {/* </Alert> */}
      </React.Fragment>
    );
  }

  const handleBulkEdit = () => {
    if (!canUpdateSpaces) {
      setAlert({
        open: true,
        message: "You don't have permission to edit spaces",
        severity: "warning",
      });
      return;
    }

    if (selected.length !== 1) {
      setAlert({
        open: true,
        message: "Please select exactly one space to edit",
        severity: "info",
      });
      return;
    }

    // Find the selected space
    const spaceToEdit = spaces.find((space) => space.id === selected[0]);
    handleEditClick(spaceToEdit);
  };

  // Handler for search changes
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <React.Fragment>
      <Helmet title="Spaces" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Spaces
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/private">
              Spaces
            </Link>
            {/* Use Typography instead of Link to make these non-clickable */}
            <Typography color="text.primary">Settings</Typography>
            <Typography color="text.primary">Space Management</Typography>
          </Breadcrumbs>
        </Grid>
        {canAddSpaces && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddClick}
              startIcon={<AddIcon />}
            >
              Add Space
            </Button>
          </Grid>
        )}
      </Grid>
      <Divider my={6} />

      <SearchBar
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Search groups..."
        containerSx={{
          borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
        }}
      />
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onBulkDelete={handleBulkDeleteClick}
          onBulkEdit={handleBulkEdit}
          canDelete={canDeleteSpaces}
          canUpdate={canUpdateSpaces}
          viewStyle={viewStyle}
          setViewStyle={setViewStyle}
        />

        {/* Toggle between view styles */}
        {viewStyle === "List" ? renderTableView() : renderCardView()}
      </Paper>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {editingSpace ? "Edit Space" : "Create New Space"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editingSpace
              ? "Edit the space details below"
              : "Enter the details for the new space"}
          </DialogContentText>
          <Formik
            initialValues={{
              name: editingSpace ? editingSpace.name : "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required").trim(),
            })}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <MuiTextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Name"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
                <DialogActions>
                  <Button onClick={handleCloseDialog}>Cancel</Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                  >
                    {isSubmitting
                      ? "Saving..."
                      : editingSpace
                      ? "Save Changes"
                      : "Create Space"}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteConfirmation.open}
        onClose={handleCancelDelete}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete the space "
            {deleteConfirmation.spaceName}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={bulkDeleteConfirmation.open}
        onClose={handleCancelBulkDelete}
        aria-labelledby="bulk-delete-dialog-title"
        aria-describedby="bulk-delete-dialog-description"
      >
        <DialogTitle id="bulk-delete-dialog-title">
          Confirm Bulk Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="bulk-delete-dialog-description">
            Are you sure you want to delete {bulkDeleteConfirmation.count}{" "}
            selected spaces? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelBulkDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmBulkDelete}
            color="error"
            variant="contained"
            autoFocus
          >
            Delete {bulkDeleteConfirmation.count} Spaces
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default Spaces;
