// src/pages/auth/ChangePassword.js
// File path: src/pages/auth/ChangePassword.js

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Paper, Typography, Box, Alert } from "@mui/material";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import ChangePasswordComponent from "../../components/auth/ChangePassword";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.spacing(2)};
`;

function ChangePassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const [debugInfo, setDebugInfo] = useState(null);

  useEffect(() => {
    // Gather all the debug information
    const info = {
      locationState: location.state,
      emailFromLocationState: location.state?.email,
      resetRequiredFromLocationState: location.state?.passwordResetRequired,
      emailFromStorage: localStorage.getItem("resetEmail"),
      resetRequiredFromStorage:
        localStorage.getItem("resetRequired") === "true",
      temporaryToken: localStorage.getItem("temporaryToken")
        ? "Present"
        : "Not found",
      accessToken: localStorage.getItem("accessToken")
        ? "Present"
        : "Not found",
      urlQueryParams: new URLSearchParams(location.search).toString(),
      pathname: location.pathname,
    };

    console.log("Debug Info in ChangePassword page:", info);
    setDebugInfo(info);

    // Check for query params in the URL
    const params = new URLSearchParams(location.search);
    const emailFromParams = params.get("email");
    const resetFromParams = params.get("required");

    if (emailFromParams) {
      localStorage.setItem("resetEmail", emailFromParams);
    }

    if (resetFromParams) {
      localStorage.setItem("resetRequired", "true");
    }
  }, [location]);

  // For debugging only - can be removed in production
  const showDebugInfo = true;

  console.log("kkkssss");

  return (
    <Wrapper>
      <Helmet title="Change Password" />

      {/* Show logo if not a required password reset */}
      {!debugInfo?.resetRequiredFromLocationState &&
        !debugInfo?.resetRequiredFromStorage && (
          <Link to="/">
            <Brand />
          </Link>
        )}

      <ChangePasswordComponent />
    </Wrapper>
  );
}

export default ChangePassword;
