import React, { useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useApp } from "./AppContext";

import {
  AppBar,
  Button as MuiButton,
  Container,
  Grid,
  Box,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as Logo } from "../../../vendor/logo.svg";

const Button = styled(MuiButton)(spacing);

const Brand = styled.div`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  margin-top: -2px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;

  vertical-align: middle;
  display: inline;
`;

const AppBarComponent = () => {
  const { user } = useAuth();
  const { isAppReady } = useApp();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderAuthButtons = () => {
    if (user) {
      return (
        <Button ml={2} color="inherit" component={Link} to="/private">
          Dashboard
        </Button>
      );
    }

    return (
      <>
        <Button ml={2} color="inherit" component={Link} to="/auth/sign-in">
          Sign In
        </Button>
        <Button ml={2} color="inherit" component={Link} to="/auth/sign-up">
          Sign Up
        </Button>
      </>
    );
  };

  // Mobile menu items
  const renderMobileAuthItems = () => {
    if (user) {
      return (
        <MenuItem onClick={handleClose} component={Link} to="/private">
          Dashboard
        </MenuItem>
      );
    }

    return (
      <>
        <MenuItem onClick={handleClose} component={Link} to="/auth/sign-in">
          Sign In
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/auth/sign-up">
          Sign Up
        </MenuItem>
      </>
    );
  };

  return (
    <React.Fragment>
      <AppBar position="relative" color="transparent" elevation={0}>
        <Toolbar>
          <Container>
            <Grid container alignItems="center">
              <Grid item>
                <Brand>
                  <BrandIcon />
                  Mira
                </Brand>
              </Grid>
              <Grid item xs />
              <Grid item>
                {/* Desktop menu */}
                <Box sx={{ display: { xs: "none", md: "inline-block" } }}>
                  <Button
                    ml={2}
                    color="inherit"
                    component={Link}
                    to="/dashboard/analytics"
                    target="_blank"
                  >
                    Live Preview
                  </Button>
                  <Button
                    ml={2}
                    color="inherit"
                    component={Link}
                    to="/documentation/welcome"
                    target="_blank"
                  >
                    Documentation
                  </Button>
                  <Button
                    ml={2}
                    color="inherit"
                    component={Link}
                    to="/documentation/support"
                    target="_blank"
                  >
                    Support
                  </Button>
                  {isAppReady && renderAuthButtons()}
                </Box>

                {/* Mobile menu button */}
                <Box sx={{ display: { xs: "inline-block", md: "none" } }}>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleClick}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>

                {/* Mobile menu dropdown */}
                <Menu
                  id="mobile-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "mobile-button",
                  }}
                >
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/dashboard/analytics"
                  >
                    Live Preview
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/documentation/welcome"
                  >
                    Documentation
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/documentation/support"
                  >
                    Support
                  </MenuItem>
                  {isAppReady && renderMobileAuthItems()}
                </Menu>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default AppBarComponent;
