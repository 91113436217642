// src/pages/settings/Users.js
// File path: src/pages/settings/Users.js

import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { axiosInstance } from "../../utils/axios";
import useAuth from "../../hooks/useAuth";
import { useSavedPermissions } from "../../utils/permissionUtils";
import { PERMISSIONS } from "../../utils/permissionConstants";
import { DataGrid } from "@mui/x-data-grid";
import { alpha } from "@mui/material/styles";
import { Search as SearchIcon } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Person as PersonIcon } from "@mui/icons-material";
import SearchBar from "../components/SearchBar";
import {
  Alert,
  TextField as MuiTextField,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Snackbar,
  MenuItem,
  Checkbox,
  Select,
  InputLabel,
  FormControl,
  ListItemText,
  TextField,
  Box,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

// Styled Components
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Card = styled(MuiCard)(spacing);
const Chip = styled(MuiChip)`
  ${spacing};
  margin-right: ${(props) => props.theme.spacing(1)};
`;

// User Card Component
const UserCard = ({ user, isSelected, onSelect, serialNumber }) => {
  return (
    <Paper>
      <Card>
        <div style={{ position: "relative" }}>
          <div
            style={{
              backgroundColor: "#f0f0f0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "140px",
            }}
          >
            <Typography variant="h2" color="textSecondary">
              {user.name.charAt(0).toUpperCase()}
            </Typography>
          </div>
          <Checkbox
            checked={isSelected}
            onChange={(e) => onSelect(e, user.id)}
            style={{ position: "absolute", top: "8px", left: "8px" }}
          />
        </div>
        <CardContent>
          <Box>
            <Typography variant="h5" gutterBottom>
              {user.name}
            </Typography>
          </Box>
          <Typography mb={2} color="textSecondary" component="p">
            S.No: {serialNumber}
            <br />
            Email: {user.email}
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
};

// Enhanced Table Toolbar Component

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    onBulkDelete,
    onBulkEdit,
    canDelete,
    canUpdate,
    viewStyle,
    setViewStyle,
    // Remove searchQuery and setSearchQuery props
  } = props;

  const handleViewClick = () => {
    setViewStyle(viewStyle === "List" ? "Card" : "List");
  };

  // Remove handleSearchChange function since it's now handled by SearchBar

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "70px",
        borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
      }}
    >
      {numSelected > 0 ? (
        <Typography
          color="inherit"
          variant="subtitle1"
          sx={{ fontWeight: 500 }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <div></div> // Empty div to maintain the spacing, since search is now outside the toolbar
      )}

      <Box>
        {numSelected > 0 ? (
          <Box display="flex">
            {numSelected === 1 && canUpdate && (
              <Tooltip title="Edit">
                <IconButton
                  aria-label="Edit"
                  size="large"
                  onClick={onBulkEdit}
                  sx={{ mr: 1 }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {canDelete && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="Delete"
                  size="large"
                  onClick={onBulkDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ) : (
          <Tooltip
            title={
              viewStyle === "List"
                ? "Change to Card View"
                : "Change to List View"
            }
          >
            <IconButton
              color="primary"
              onClick={handleViewClick}
              size="medium"
              sx={{
                borderRadius: "8px",
                p: 1,
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.08),
                "&:hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.12),
                },
              }}
            >
              {viewStyle === "List" ? <ViewModuleIcon /> : <ViewListIcon />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Toolbar>
  );
}

// Helper functions for sorting
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Main Component
function Users() {
  const navigate = useNavigate();
  const { user, impersonateUser } = useAuth();
  const savedPermissions = useSavedPermissions();
  const isPremium = user?.subscriptionLevel === "1";

  // Permission checks
  const canViewUsers =
    isPremium || savedPermissions.includes(PERMISSIONS.USERS.childrens[0]);
  const canAddUsers =
    isPremium || savedPermissions.includes(PERMISSIONS.USERS.childrens[1]);
  const canUpdateUsers =
    isPremium || savedPermissions.includes(PERMISSIONS.USERS.childrens[2]);
  const canDeleteUsers =
    isPremium || savedPermissions.includes(PERMISSIONS.USERS.childrens[3]);

  // State
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState({ open: false, user: null });
  const [bulkDeleteDialog, setBulkDeleteDialog] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [viewStyle, setViewStyle] = useState("List");
  const [validationAlert, setValidationAlert] = useState({
    open: false,
    message: "",
  });

  // Reset to first page when search query changes
  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // Filter users based on search query
  const filteredUsers = users.filter((user) => {
    if (!searchQuery) return true;

    const query = searchQuery.toLowerCase();
    return (
      user.name.toLowerCase().includes(query) ||
      user.email.toLowerCase().includes(query) ||
      user.id.toString().includes(query)
    );
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Fetch users on component mount
  useEffect(() => {
    if (!canViewUsers) {
      return;
    }

    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query {
              getUsers {
                id
                name
                email
                roles {
                  id
                  name
                }
                groups {
                  id
                  name
                }
              }
            }
          `,
        });

        if (response.errors) {
          console.error(response.errors);
          throw new Error(response.errors[0].message);
        } else {
          setUsers(response.data.getUsers || []);
        }
      } catch (error) {
        setAlert({
          open: true,
          message:
            "Error fetching users: " + (error.message || "Unknown error"),
          severity: "error",
        });
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query {
              getRoles {
                id
                name
              }
            }
          `,
        });
        setRoles(response.data.getRoles || []);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    const fetchGroups = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query {
              getGroups {
                id
                name
              }
            }
          `,
        });
        setGroups(response.data.getGroups || []);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchUsers();
    fetchRoles();
    fetchGroups();
  }, [canViewUsers]);

  // Handle click events
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteredUsers.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Dialog handlers
  const handleClickOpen = () => {
    if (!canAddUsers) {
      setAlert({
        open: true,
        message: "You don't have permission to add users",
        severity: "warning",
      });
      return;
    }
    setEditingUser(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingUser(null);
  };

  const handleEditClick = (user) => {
    if (!canUpdateUsers) {
      setAlert({
        open: true,
        message: "You don't have permission to edit users",
        severity: "warning",
      });
      return;
    }
    setEditingUser(user);
    setOpenDialog(true);
  };

  // Delete handlers
  const handleDelete = (user) => {
    if (!canDeleteUsers) {
      setAlert({
        open: true,
        message: "You don't have permission to delete users",
        severity: "warning",
      });
      return;
    }
    setDeleteDialog({ open: true, user });
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: `
        mutation DeleteUser($id: ID!) {
          deleteUser(id: $id)
        }
      `,
        variables: { id: deleteDialog.user.id },
      });

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      setUsers((prev) =>
        prev.filter((user) => user.id !== deleteDialog.user.id)
      );
      setAlert({
        open: true,
        message: "User deleted successfully",
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: error.message || "Error deleting user",
        severity: "error",
      });
    } finally {
      setDeleteDialog({ open: false, user: null });
    }
  };

  const handleBulkDelete = () => {
    if (!canDeleteUsers) {
      setAlert({
        open: true,
        message: "You don't have permission to delete users",
        severity: "warning",
      });
      return;
    }
    if (selected.length === 0) {
      setAlert({
        open: true,
        message: "No users selected",
        severity: "info",
      });
      return;
    }
    setBulkDeleteDialog(true);
  };

  const handleBulkDeleteConfirm = async () => {
    try {
      const deletePromises = selected.map((id) =>
        axiosInstance.post("/graphql", {
          query: `
          mutation DeleteUser($id: ID!) {
            deleteUser(id: $id)
          }
        `,
          variables: { id },
        })
      );

      await Promise.all(deletePromises);
      setUsers((prev) => prev.filter((user) => !selected.includes(user.id)));
      setSelected([]);
      setAlert({
        open: true,
        message: `Successfully deleted ${selected.length} users`,
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: "Error deleting users",
        severity: "error",
      });
    } finally {
      setBulkDeleteDialog(false);
    }
  };

  // Impersonate user
  const handleImpersonateClick = async (user) => {
    try {
      await impersonateUser(user.id);
      navigate("/dashboard");
    } catch (error) {
      setAlert({
        open: true,
        message: "Failed to impersonate user",
        severity: "error",
      });
    }
  };

  // Form submission for adding/editing user
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const { name, email, assignmentType, selectedRoles, selectedGroups } =
        values;

      // Prepare input based on assignment type
      const roles = assignmentType === "roles" ? selectedRoles : [];
      const groups = assignmentType === "groups" ? selectedGroups : [];

      if (editingUser) {
        // Update existing user
        const updateInput = {
          id: editingUser.id,
          name,
          email,
          roles,
          groups,
        };

        // Only include password if it was provided (for editing)
        if (values.password) {
          updateInput.password = values.password;
        }

        const response = await axiosInstance.post("/graphql", {
          query: `
            mutation UpdateUser($input: UpdateUserInput!) {
              updateUser(input: $input) {
                user {
                  id
                  name
                  email
                  roles {
                    id
                    name
                  }
                  groups {
                    id
                    name
                  }
                }
              }
            }
          `,
          variables: {
            input: updateInput,
          },
        });

        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        const updatedUser = response.data.updateUser.user;
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === updatedUser.id ? updatedUser : user
          )
        );

        setAlert({
          open: true,
          message: "User updated successfully!",
          severity: "success",
        });

        // Clear selection after successful update
        setSelected([]);
      } else {
        // Create new user with the sendWelcomeEmail flag
        const response = await axiosInstance.post("/graphql", {
          query: `
            mutation RegisterUser($input: UserInput!) {
              registerUser(input: $input) {
                user {
                  id
                  name
                  email
                  roles {
                    id
                    name
                  }
                  groups {
                    id
                    name
                  }
                }
              }
            }
          `,
          variables: {
            input: {
              name,
              email,
              roles,
              groups,
              sendWelcomeEmail: true,
            },
          },
        });

        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        const newUser = response.data.registerUser.user;
        setUsers((prevUsers) => [...prevUsers, newUser]);

        setAlert({
          open: true,
          message:
            "User added successfully! A welcome email with login details has been sent.",
          severity: "success",
        });
      }

      handleCloseDialog();
      resetForm();
    } catch (error) {
      setAlert({
        open: true,
        message: error.message || "An unexpected error occurred",
        severity: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  // Render card view
  const renderCardView = () => {
    const paginatedUsers = stableSort(
      filteredUsers,
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
      <>
        <Box px={2} py={2} display="flex" justifyContent="flex-end">
          <Checkbox
            indeterminate={
              selected.length > 0 && selected.length < filteredUsers.length
            }
            checked={
              filteredUsers.length > 0 &&
              selected.length === filteredUsers.length
            }
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all users" }}
          />
          <Typography
            variant="body2"
            style={{ marginLeft: "8px", alignSelf: "center" }}
          >
            Select All
          </Typography>
        </Box>
        <Box m={1} pt={1}>
          <Grid container spacing={4}>
            {paginatedUsers.map((user, index) => (
              <Grid item key={user.id} xs={12} sm={6} md={4} lg={3}>
                <UserCard
                  user={user}
                  isSelected={isSelected(user.id)}
                  onSelect={handleClick}
                  serialNumber={page * rowsPerPage + index + 1}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px 0",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  marginBottom: "0",
                  display: "flex",
                  alignItems: "center",
                },
              ".MuiTablePagination-select": {
                marginRight: "32px",
                marginLeft: "8px",
              },
            }}
          />
        </div>
      </>
    );
  };

  // Render DataGrid table view
  const renderTableView = () => {
    // Get paginated data with pre-calculated serial numbers
    const paginatedUsers = stableSort(
      filteredUsers,
      getComparator(order, orderBy)
    )
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((user, index) => ({
        ...user,
        serialNumber: page * rowsPerPage + index + 1,
      }));

    // Define columns for DataGrid - removed action column
    const columns = [
      {
        field: "select",
        headerName: "",
        width: 50,
        sortable: false,
        filterable: false,
        headerClassName: "select-all-header",
        renderHeader: () => (
          <Checkbox
            indeterminate={
              selected.length > 0 && selected.length < filteredUsers.length
            }
            checked={
              filteredUsers.length > 0 &&
              selected.length === filteredUsers.length
            }
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all users" }}
          />
        ),
        renderCell: (params) => (
          <Checkbox
            checked={isSelected(params.row.id)}
            onClick={(event) => handleClick(event, params.row.id)}
            inputProps={{ "aria-labelledby": `select-${params.row.id}` }}
          />
        ),
      },
      {
        field: "serialNumber",
        headerName: "S.No",
        width: 80,
        sortable: false,
        filterable: false,
      },
      { field: "name", headerName: "Name", flex: 1 },
      { field: "email", headerName: "Email", flex: 1.5 },
      {
        field: "roles",
        headerName: "Roles",
        flex: 1,
        renderCell: (params) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {params.row.roles && params.row.roles.length > 0 ? (
              params.row.roles.map((role) => (
                <Chip
                  key={role.id}
                  label={role.name}
                  size="small"
                  variant="outlined"
                  color="primary"
                />
              ))
            ) : (
              <Typography variant="caption" color="text.secondary">
                No roles
              </Typography>
            )}
          </Box>
        ),
      },
    ];

    return (
      <>
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={paginatedUsers}
            columns={columns}
            hideFooter={true}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            checkboxSelection={false}
            rowHeight={70}
            headerHeight={56}
            components={{
              Toolbar: () => null,
            }}
            sortingMode="server"
            sortModel={[
              {
                field: orderBy,
                sort: order.toLowerCase(),
              },
            ]}
            onSortModelChange={(model) => {
              if (model.length > 0) {
                setOrderBy(model[0].field);
                setOrder(model[0].sort === "asc" ? "asc" : "desc");
              }
            }}
            sx={{
              boxShadow: 0,
              border: 0,
              "& .MuiDataGrid-cell": {
                padding: "8px 16px",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "8px 16px",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-toolbar": {
                alignItems: "center",
                minHeight: "40px",
                padding: "0 8px",
              },
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                },
              ".MuiTablePagination-select": {
                marginRight: "8px",
                marginLeft: "8px",
              },
              ".MuiTablePagination-actions": {
                display: "flex",
                alignItems: "center",
              },
            }}
          />
        </Box>
      </>
    );
  };

  // If user doesn't have permission, show warning
  if (!canViewUsers) {
    return (
      <React.Fragment>
        <Helmet title="Users" />
        <Typography variant="h3" gutterBottom>
          Users
        </Typography>
        {/* <Alert severity="warning"> */}
        {/*   You don't have permission to view this page. */}
        {/* </Alert> */}
      </React.Fragment>
    );
  }

  const handleBulkEdit = () => {
    if (!canUpdateUsers) {
      setAlert({
        open: true,
        message: "You don't have permission to edit users",
        severity: "warning",
      });
      return;
    }

    if (selected.length !== 1) {
      setAlert({
        open: true,
        message: "Please select exactly one user to edit",
        severity: "info",
      });
      return;
    }

    // Find the selected user
    const userToEdit = users.find((user) => user.id === selected[0]);
    handleEditClick(userToEdit);
  };

  return (
    <React.Fragment>
      <Helmet title="Users" />

      {/* Header */}
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Users
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/private">
              Spaces
            </Link>
            <Typography color="textSecondary">Settings</Typography>
            <Typography color="textSecondary">Users</Typography>
          </Breadcrumbs>
        </Grid>
        {canAddUsers && (
          <Grid item>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                startIcon={<AddIcon />}
              >
                Add User
              </Button>
            </div>
          </Grid>
        )}
      </Grid>

      <Divider my={6} />

      <SearchBar
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Search users..."
        containerSx={{
          borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
        }}
      />
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onBulkDelete={handleBulkDelete}
          onBulkEdit={handleBulkEdit}
          canDelete={canDeleteUsers}
          canUpdate={canUpdateUsers}
          viewStyle={viewStyle}
          setViewStyle={setViewStyle}
        />

        {/* Toggle between view styles */}
        {viewStyle === "List" ? renderTableView() : renderCardView()}
      </Paper>

      {/* Add/Edit User Dialog with Radio Button Selection */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {editingUser ? "Edit User" : "Create New User"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editingUser
              ? "Edit user details below"
              : "To add a new user, please fill in the details below. A temporary password will be generated and sent by email."}
          </DialogContentText>
          <Formik
            initialValues={{
              name: editingUser ? editingUser.name : "",
              email: editingUser ? editingUser.email : "",
              password: "", // Only used for editing

              // Determine initial assignment type based on user data for editing
              assignmentType: editingUser
                ? editingUser.roles && editingUser.roles.length > 0
                  ? "roles"
                  : "groups"
                : "roles", // Default to "roles" for new users

              selectedRoles: editingUser
                ? editingUser.roles.map((role) => role.id)
                : [],
              selectedGroups: editingUser
                ? editingUser.groups.map((group) => group.id)
                : [],
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .trim()
                .required("Name is required")
                .min(1, "Name cannot be empty")
                .matches(/\S/, "Name cannot be only whitespace")
                .max(255, "Name is too long"),
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: editingUser
                ? Yup.string()
                    .min(12, "Must be at least 12 characters")
                    .max(255)
                : Yup.string(), // No validation for password when creating a new user
              assignmentType: Yup.string()
                .required("Assignment type is required")
                .oneOf(["roles", "groups"]),
              selectedRoles: Yup.array(),
              selectedGroups: Yup.array(),
            })}
            onSubmit={(values, formikHelpers) => {
              const { setSubmitting } = formikHelpers;

              // Modified validation based on assignment type
              if (
                values.assignmentType === "roles" &&
                values.selectedRoles.length === 0
              ) {
                setValidationAlert({
                  open: true,
                  message: "You must select at least one role",
                });
                setSubmitting(false);
                return;
              } else if (
                values.assignmentType === "groups" &&
                values.selectedGroups.length === 0
              ) {
                setValidationAlert({
                  open: true,
                  message: "You must select at least one group",
                });
                setSubmitting(false);
                return;
              }

              // Otherwise, proceed with the normal submission
              handleSubmit(values, formikHelpers);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert severity="warning">{errors.submit}</Alert>
                )}
                <MuiTextField
                  type="text"
                  name="name"
                  label="Name"
                  value={values.name}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                  margin="normal"
                />

                <MuiTextField
                  type="email"
                  name="email"
                  label="Email address"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                  margin="normal"
                />

                {/* Only show password field when editing a user */}
                {editingUser && (
                  <MuiTextField
                    type="password"
                    name="password"
                    label="New Password (leave blank to keep current)"
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                    margin="normal"
                  />
                )}

                {/* Radio Button Selection Type */}
                <FormControl component="fieldset" margin="normal" fullWidth>
                  <Typography variant="subtitle2" gutterBottom>
                    Select user assignment type:
                  </Typography>
                  <RadioGroup
                    row
                    name="assignmentType"
                    value={values.assignmentType}
                    onChange={(e) => {
                      const newType = e.target.value;
                      setFieldValue("assignmentType", newType);

                      // Clear the selections for the other type when switching
                      if (newType === "roles") {
                        setFieldValue("selectedGroups", []);
                      } else {
                        setFieldValue("selectedRoles", []);
                      }
                    }}
                  >
                    <FormControlLabel
                      value="roles"
                      control={<Radio />}
                      label="Assign Roles"
                    />
                    <FormControlLabel
                      value="groups"
                      control={<Radio />}
                      label="Assign Groups"
                    />
                  </RadioGroup>
                </FormControl>

                {/* Roles Selection - Only shown if roles is selected */}
                {values.assignmentType === "roles" && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Roles</InputLabel>
                    <Select
                      multiple
                      name="selectedRoles"
                      value={values.selectedRoles}
                      onChange={(e) =>
                        setFieldValue("selectedRoles", e.target.value)
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                roles.find((role) => role.id === value)?.name ||
                                value
                              }
                              sx={{ mr: 0.5, mb: 0.5 }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {roles.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          <Checkbox
                            checked={values.selectedRoles.indexOf(role.id) > -1}
                          />
                          <ListItemText primary={role.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      Select one or more roles for this user
                    </FormHelperText>
                  </FormControl>
                )}

                {/* Groups Selection - Only shown if groups is selected */}
                {values.assignmentType === "groups" && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Groups</InputLabel>
                    <Select
                      multiple
                      name="selectedGroups"
                      value={values.selectedGroups}
                      onChange={(e) =>
                        setFieldValue("selectedGroups", e.target.value)
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                groups.find((group) => group.id === value)
                                  ?.name || value
                              }
                              sx={{ mr: 0.5, mb: 0.5 }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {groups.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          <Checkbox
                            checked={
                              values.selectedGroups.indexOf(group.id) > -1
                            }
                          />
                          <ListItemText primary={group.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      Select one or more groups for this user
                    </FormHelperText>
                  </FormControl>
                )}

                <DialogActions>
                  <Button onClick={handleCloseDialog}>Cancel</Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? "Saving..."
                      : editingUser
                      ? "Save Changes"
                      : "Create User"}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({ open: false, user: null })}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete the user "{deleteDialog.user?.name}
            "? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog({ open: false, user: null })}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Bulk Delete Dialog */}
      <Dialog
        open={bulkDeleteDialog}
        onClose={() => setBulkDeleteDialog(false)}
        aria-labelledby="bulk-delete-dialog-title"
        aria-describedby="bulk-delete-dialog-description"
      >
        <DialogTitle id="bulk-delete-dialog-title">
          Confirm Bulk Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="bulk-delete-dialog-description">
            Are you sure you want to delete {selected.length} selected users?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBulkDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={handleBulkDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete {selected.length} Users
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alerts */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={validationAlert.open}
        autoHideDuration={6000}
        onClose={() => setValidationAlert({ ...validationAlert, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          severity="error"
          onClose={() =>
            setValidationAlert({ ...validationAlert, open: false })
          }
          sx={{ width: "100%" }}
        >
          {validationAlert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default Users;
