// components/calendar/CalendarIntegration.js
import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Box,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  Google as GoogleIcon,
  Microsoft as MicrosoftIcon,
  Delete as DeleteIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import {
  redirectConfig,
  googleClientId,
  microsoftClientId,
} from "../../../../src/config";

// Define GraphQL queries using gql
const GET_CALENDAR_CONNECTIONS = gql`
  query GetCalendarConnections {
    getCalendarConnections {
      id
      provider
      email
      isDefault
      connected
      lastSynced
    }
  }
`;

const DISCONNECT_CALENDAR = gql`
  mutation DisconnectCalendar($connectionId: ID!) {
    disconnectCalendar(connectionId: $connectionId)
  }
`;

const SET_DEFAULT_CALENDAR = gql`
  mutation SetDefaultCalendar($connectionId: ID!) {
    setDefaultCalendar(connectionId: $connectionId) {
      id
      isDefault
    }
  }
`;

const CalendarIntegration = () => {
  const [connections, setConnections] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({ open: false, id: null });

  // Query for fetching calendar connections
  const { data, loading, error, refetch } = useQuery(GET_CALENDAR_CONNECTIONS, {
    onError: (error) => {
      console.error("Calendar connection error:", error);
    },
  });

  // Mutations
  const [disconnectCalendar] = useMutation(DISCONNECT_CALENDAR);
  const [setDefaultCalendar] = useMutation(SET_DEFAULT_CALENDAR);

  useEffect(() => {
    if (data?.getCalendarConnections) {
      setConnections(data.getCalendarConnections);
    }
  }, [data]);

  const handleGoogleConnect = () => {
    const clientId = googleClientId;
    const redirectUri = encodeURIComponent(
      `${redirectConfig.redirectURI}/calendar/auth/google/callback`
    );
    const scope = encodeURIComponent(
      "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email"
    );

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&access_type=offline&prompt=consent`;

    window.location.href = authUrl;
  };

  const handleMicrosoftConnect = () => {
    const clientId = microsoftClientId;
    const redirectUri = `${redirectConfig.redirectURI}/calendar/auth/microsoft/callback`;

    const params = new URLSearchParams({
      client_id: clientId,
      response_type: "code",
      redirect_uri: redirectUri,
      response_mode: "query",
      scope: "offline_access Calendars.ReadWrite User.Read",
      prompt: "consent",
      access_type: "offline",
    });

    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${params}`;
    console.log("Auth URL:", authUrl); // For debugging
    window.location.href = authUrl;
  };

  const handleDisconnect = (connectionId) => {
    setConfirmDialog({ open: true, id: connectionId });
  };

  const handleConfirmDisconnect = async () => {
    try {
      await disconnectCalendar({
        variables: { connectionId: confirmDialog.id },
      });
      await refetch();
    } catch (err) {
      console.error("Error disconnecting calendar:", err);
    } finally {
      setConfirmDialog({ open: false, id: null });
    }
  };

  const handleSetDefault = async (connectionId) => {
    try {
      await setDefaultCalendar({
        variables: { connectionId },
      });
      await refetch();
    } catch (err) {
      console.error("Error setting default calendar:", err);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading calendar connections</div>;

  return (
    <Box>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Calendar Integration
          </Typography>
          <Divider sx={{ my: 2 }} />

          {/* Connection Buttons */}
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<GoogleIcon />}
                onClick={handleGoogleConnect}
                disabled={connections.some((c) => c.provider === "google")}
              >
                Connect Google Calendar
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<MicrosoftIcon />}
                onClick={handleMicrosoftConnect}
                disabled={connections.some((c) => c.provider === "microsoft")}
              >
                Connect Microsoft Calendar
              </Button>
            </Grid>
          </Grid>

          {/* Connected Calendars */}
          <Typography variant="subtitle1" gutterBottom>
            Connected Calendars
          </Typography>

          <Grid container spacing={2}>
            {connections.map((connection) => (
              <Grid item xs={12} key={connection.id}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        {connection.provider === "google" ? (
                          <GoogleIcon color="primary" />
                        ) : (
                          <MicrosoftIcon color="primary" />
                        )}
                      </Grid>
                      <Grid item xs>
                        <Typography variant="subtitle2">
                          {connection.email}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          Last synced:{" "}
                          {new Date(connection.lastSynced).toLocaleString()}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {connection.isDefault ? (
                          <Chip
                            size="small"
                            label="Default"
                            icon={<CheckIcon />}
                            color="success"
                          />
                        ) : (
                          <Button
                            size="small"
                            onClick={() => handleSetDefault(connection.id)}
                          >
                            Set as Default
                          </Button>
                        )}
                      </Grid>
                      <Grid item>
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() => handleDisconnect(connection.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}

            {connections.length === 0 && (
              <Grid item xs={12}>
                <Typography color="textSecondary" align="center">
                  No calendars connected
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, id: null })}
      >
        <DialogTitle>Disconnect Calendar</DialogTitle>
        <DialogContent>
          Are you sure you want to disconnect this calendar? All synced events
          will remain but no new events will be synced.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog({ open: false, id: null })}>
            Cancel
          </Button>
          <Button onClick={handleConfirmDisconnect} color="error">
            Disconnect
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CalendarIntegration;
