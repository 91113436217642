import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import EventTypeContext from "../../../contexts/EventTypeContext.js";

import {
  Box,
  Button,
  CircularProgress,
  Divider as MuiDivider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper as MuiPaper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Alert,
  AlertTitle,
  Snackbar,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";

// Styled components
const Divider = styled(MuiDivider)(({ theme }) => ({
  margin: theme ? theme.spacing(2, 0) : "16px 0",
}));

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme ? theme.spacing(2) : "16px",
}));

// GraphQL queries and mutations
const GET_ALL_AVAILABILITIES = gql`
  query GetAllAvailabilities {
    getAllAvailabilities {
      id
      name
      timezone
      days
      timeSlots {
        timeStart
        timeEnd
      }
      clientId
      userId
      createdAt
      updatedAt
    }
  }
`;

const GET_EVENT_TYPE = gql`
  query GetEventType($id: ID!) {
    getEventType(id: $id) {
      id
      title
      url
      description
      duration
      assignment
      availabilityId
    }
  }
`;

const UPDATE_EVENT_TYPE = gql`
  mutation UpdateEventType($input: UpdateEventTypeInput!) {
    updateEventType(input: $input) {
      eventType {
        id
        title
        availabilityId
      }
    }
  }
`;

function Availability({ eventTypeId, isTabActive }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedAvailabilityId, setSelectedAvailabilityId] = useState("");
  const [initialAvailabilityId, setInitialAvailabilityId] = useState("");
  const [availabilityData, setAvailabilityData] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Add ref to track if component has been initialized
  const isInitialized = useRef(false);

  // Access the context
  const context = useContext(EventTypeContext);

  // Fetch all availabilities
  const { loading, error, data, refetch } = useQuery(GET_ALL_AVAILABILITIES, {
    fetchPolicy: "network-only", // Don't use cache, always make network request
  });

  // Fetch event type to get current availabilityId
  const { data: eventTypeData, loading: eventTypeLoading } = useQuery(
    GET_EVENT_TYPE,
    {
      variables: { id: eventTypeId },
      skip: !eventTypeId,
      fetchPolicy: "network-only",
    }
  );

  // Update event type mutation
  const [updateEventType, { loading: updateLoading }] = useMutation(
    UPDATE_EVENT_TYPE,
    {
      onCompleted: (data) => {
        setAlert({
          open: true,
          message: "Availability saved successfully!",
          severity: "success",
        });

        // Reset the context state when save is successful
        if (context && typeof context.setHasUnsavedChanges === "function") {
          context.setHasUnsavedChanges(false);
        }

        // Update the initial value to reflect the new state
        setInitialAvailabilityId(selectedAvailabilityId);
      },
      onError: (error) => {
        setAlert({
          open: true,
          message: `Failed to save availability: ${error.message}`,
          severity: "error",
        });
      },
    }
  );

  // Handle availability selection change
  const handleAvailabilityChange = (event) => {
    const selectedId = event.target.value;
    setSelectedAvailabilityId(selectedId);

    if (selectedId && data) {
      const selectedAvailability = data.getAllAvailabilities.find(
        (availability) => availability.id === selectedId
      );
      setAvailabilityData(selectedAvailability);

      // Check if the selection is different from the initial value
      const hasChanged = selectedId !== initialAvailabilityId;

      if (
        hasChanged &&
        context &&
        typeof context.setHasUnsavedChanges === "function"
      ) {
        context.setHasUnsavedChanges(true);
      }
    }
  };

  // Navigate to availability page
  const handleEditAvailability = () => {
    navigate("/calendar/availability");
  };

  // Create new availability
  const handleCreateAvailability = () => {
    navigate("/calendar/availability");
  };

  // Save availability to event type
  const handleSaveAvailability = async () => {
    if (!selectedAvailabilityId || !eventTypeId) {
      setAlert({
        open: true,
        message: "Unable to save: Missing availability or event type ID",
        severity: "error",
      });
      return;
    }

    try {
      await updateEventType({
        variables: {
          input: {
            id: eventTypeId,
            availabilityId: selectedAvailabilityId,
          },
        },
      });
    } catch (error) {
      console.error("Error saving availability:", error);
      // Error handling is done in the onError callback
    }
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  // Initialize with data when loaded
  useEffect(() => {
    // Only run initialization logic if data is available and event type data is loaded
    if (
      !isInitialized.current &&
      data?.getAllAvailabilities?.length > 0 &&
      eventTypeData?.getEventType
    ) {
      const eventType = eventTypeData.getEventType;

      // If the event type has an availability ID, use it
      if (eventType.availabilityId) {
        setSelectedAvailabilityId(eventType.availabilityId);
        setInitialAvailabilityId(eventType.availabilityId);

        const selectedAvailability = data.getAllAvailabilities.find(
          (availability) => availability.id === eventType.availabilityId
        );

        if (selectedAvailability) {
          setAvailabilityData(selectedAvailability);
        }
      } else {
        // Otherwise use the first availability
        const firstAvailabilityId = data.getAllAvailabilities[0].id;
        setSelectedAvailabilityId(firstAvailabilityId);
        setInitialAvailabilityId(firstAvailabilityId);
        setAvailabilityData(data.getAllAvailabilities[0]);
      }

      // Reset the context state when initializing
      if (context && typeof context.setHasUnsavedChanges === "function") {
        context.setHasUnsavedChanges(false);
      }

      // Mark as initialized to prevent re-initialization
      isInitialized.current = true;
    }
  }, [data, eventTypeData, context]);

  // Update availabilityData when selection changes
  useEffect(() => {
    // Skip during initialization
    if (!selectedAvailabilityId || !data || !data.getAllAvailabilities) return;

    // Find the selected availability data
    const selectedAvailability = data.getAllAvailabilities.find(
      (availability) => availability.id === selectedAvailabilityId
    );

    if (selectedAvailability) {
      setAvailabilityData(selectedAvailability);
    }
  }, [selectedAvailabilityId, data]);

  // Refetch data when tab becomes active
  useEffect(() => {
    if (isTabActive && refetch) {
      refetch();
    }
  }, [isTabActive, refetch]);

  // Get day schedule display
  const getDaySchedule = (day) => {
    if (!availabilityData) return "Loading...";

    const isDayAvailable = availabilityData.days.includes(day);

    if (!isDayAvailable) return "Unavailable";

    // Just display the first time slot to avoid repetition
    if (availabilityData.timeSlots && availabilityData.timeSlots.length > 0) {
      const slot = availabilityData.timeSlots[0];
      return `${slot.timeStart} - ${slot.timeEnd}`;
    }

    return "No time slots defined";
  };

  // If loading
  if (loading || eventTypeLoading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  // If error
  if (error) {
    return (
      <Alert severity="error" sx={{ my: 2 }}>
        <AlertTitle>Error</AlertTitle>
        Failed to load availabilities: {error.message}
      </Alert>
    );
  }

  // If no availabilities found
  if (
    !data ||
    !data.getAllAvailabilities ||
    data.getAllAvailabilities.length === 0
  ) {
    return (
      <Box my={2}>
        <Alert severity="info">
          <AlertTitle>No Availabilities Found</AlertTitle>
          You need to create an availability schedule before configuring this
          event type.
        </Alert>
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateAvailability}
          >
            Create New Availability
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Grid justifyContent="space-between" container spacing={6}>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom display="inline">
          Availability
        </Typography>
        <Typography>
          Configure when you are available for this event type
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Grid container spacing={6}>
          <Grid item md={12}>
            <Box mb={4}>
              <FormControl fullWidth>
                <InputLabel id="availability-select-label">
                  Availability Schedule
                </InputLabel>
                <Select
                  labelId="availability-select-label"
                  id="availability-select"
                  value={selectedAvailabilityId}
                  onChange={handleAvailabilityChange}
                  label="Availability Schedule"
                >
                  {data.getAllAvailabilities.map((availability) => (
                    <MenuItem key={availability.id} value={availability.id}>
                      {availability.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        {availabilityData && (
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="availability schedule table"
            >
              <TableBody>
                <TableRow>
                  <TableCell style={{ borderBottom: "0" }}>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bold" }}
                    >
                      Monday
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: "0" }}>
                    {getDaySchedule("Monday")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "0" }}>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bold" }}
                    >
                      Tuesday
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: "0" }}>
                    {getDaySchedule("Tuesday")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "0" }}>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bold" }}
                    >
                      Wednesday
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: "0" }}>
                    {getDaySchedule("Wednesday")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "0" }}>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bold" }}
                    >
                      Thursday
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: "0" }}>
                    {getDaySchedule("Thursday")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "0" }}>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bold" }}
                    >
                      Friday
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: "0" }}>
                    {getDaySchedule("Friday")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "0" }}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontWeight: "bold",
                        textDecoration: availabilityData.days.includes(
                          "Saturday"
                        )
                          ? "none"
                          : "line-through",
                      }}
                    >
                      Saturday
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: "0" }}>
                    {getDaySchedule("Saturday")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "0" }}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontWeight: "bold",
                        textDecoration: availabilityData.days.includes("Sunday")
                          ? "none"
                          : "line-through",
                      }}
                    >
                      Sunday
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: "0" }}>
                    {getDaySchedule("Sunday")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Divider />

        <TableContainer style={{ background: "#eee" }}>
          <Table
            aria-labelledby="timezone-info"
            size="medium"
            aria-label="timezone info table"
          >
            <TableBody>
              <TableRow>
                <TableCell style={{ borderBottom: "0" }}>
                  <IconButton
                    aria-label="Timezone"
                    size="small"
                    aria-haspopup="true"
                    variant="contained"
                    color="secondary"
                  >
                    <LanguageIcon
                      style={{
                        fontSize: "1rem",
                        position: "relative",
                        bottom: "1px",
                        color: "#000",
                      }}
                    />
                  </IconButton>
                  {availabilityData
                    ? availabilityData.timezone
                    : "Loading timezone..."}
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "0" }}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={handleEditAvailability}
                  >
                    Edit Availability
                    <IconButton
                      aria-label="Edit"
                      size="small"
                      aria-haspopup="true"
                      variant="contained"
                      color="secondary"
                    >
                      <EditIcon
                        style={{
                          fontSize: "1rem",
                          position: "relative",
                          bottom: "1px",
                          color: "#000",
                        }}
                      />
                    </IconButton>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Add Save Button */}
        <Box mt={4} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveAvailability}
            disabled={
              updateLoading || selectedAvailabilityId === initialAvailabilityId
            }
          >
            {updateLoading ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </Box>
      </Grid>

      {/* Alert for save success/failure */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default Availability;
