import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import EventTypeContext from "../../../contexts/EventTypeContext.js";

import {
  Avatar as MuiAvatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Chip as MuiChip,
  Divider as MuiDivider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Paper as MuiPaper,
  Select,
  Snackbar,
  Alert,
  Typography,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

// Styled components
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);

// GraphQL queries and mutations
const GET_EVENT_TYPE = gql`
  query GetEventType($id: ID!) {
    getEventType(id: $id) {
      id
      title
      assignment
      assignmentConfig {
        schedulingType
        fixedHosts {
          enabled
          addAllTeamMembers
          hosts
        }
        roundRobinHosts {
          enabled
          addAllTeamMembers
          hosts
        }
      }
    }
  }
`;

const GET_USERS = gql`
  query GetUsers {
    getUsers {
      id
      name
      email
    }
  }
`;

const UPDATE_EVENT_TYPE_ASSIGNMENT = gql`
  mutation UpdateEventTypeAssignment($input: UpdateEventTypeAssignmentInput!) {
    updateEventTypeAssignment(input: $input) {
      eventType {
        id
        assignment
        assignmentConfig {
          schedulingType
          fixedHosts {
            enabled
            addAllTeamMembers
            hosts
          }
          roundRobinHosts {
            enabled
            addAllTeamMembers
            hosts
          }
        }
      }
    }
  }
`;

function Assignment() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Access context for tracking unsaved changes
  const { setHasUnsavedChanges } = useContext(EventTypeContext);

  // Default assignment state
  const [assignmentConfig, setAssignmentConfig] = useState({
    schedulingType: "Collective",
    fixedHosts: {
      enabled: false,
      addAllTeamMembers: false,
      hosts: [],
    },
    roundRobinHosts: {
      enabled: false,
      addAllTeamMembers: false,
      hosts: [],
    },
  });

  // Initial assignment state to track changes
  const [initialAssignment, setInitialAssignment] = useState(null);

  // Fetch event type data
  const {
    loading: eventTypeLoading,
    error: eventTypeError,
    data: eventTypeData,
  } = useQuery(GET_EVENT_TYPE, {
    variables: { id },
    fetchPolicy: "network-only",
    skip: !id,
    onCompleted: (data) => {
      if (data && data.getEventType) {
        // Initialize assignment config from server data or use defaults
        const config = data.getEventType.assignmentConfig || {
          schedulingType: "Collective",
          fixedHosts: {
            enabled: false,
            addAllTeamMembers: false,
            hosts: [],
          },
          roundRobinHosts: {
            enabled: false,
            addAllTeamMembers: false,
            hosts: [],
          },
        };

        setAssignmentConfig(config);
        // Store initial state to track changes
        setInitialAssignment(JSON.parse(JSON.stringify(config)));
      }
    },
  });

  // Fetch users for dropdowns
  const {
    loading: usersLoading,
    error: usersError,
    data: usersData,
  } = useQuery(GET_USERS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.getUsers) {
        setUsers(data.getUsers);
      }
    },
  });

  // Update event type mutation
  const [updateAssignment, { loading: saveLoading }] = useMutation(
    UPDATE_EVENT_TYPE_ASSIGNMENT,
    {
      onCompleted: () => {
        setAlert({
          open: true,
          message: "Assignment settings saved successfully!",
          severity: "success",
        });
        // Update initial state to reflect new saved state
        setInitialAssignment(JSON.parse(JSON.stringify(assignmentConfig)));
        // Reset unsaved changes flag
        if (setHasUnsavedChanges) {
          setHasUnsavedChanges(false);
        }
      },
      onError: (error) => {
        setAlert({
          open: true,
          message: `Failed to save assignment settings: ${error.message}`,
          severity: "error",
        });
      },
    }
  );

  // Check if loading state should be active
  useEffect(() => {
    setLoading(eventTypeLoading || usersLoading);
  }, [eventTypeLoading, usersLoading]);

  // Check for errors
  useEffect(() => {
    if (eventTypeError) {
      setError(eventTypeError.message);
    } else if (usersError) {
      setError(usersError.message);
    } else {
      setError(null);
    }
  }, [eventTypeError, usersError]);

  // Check for unsaved changes
  useEffect(() => {
    if (initialAssignment) {
      const hasChanges =
        JSON.stringify(assignmentConfig) !== JSON.stringify(initialAssignment);
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(hasChanges);
      }
    }
  }, [assignmentConfig, initialAssignment, setHasUnsavedChanges]);

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  const handleSchedulingTypeChange = (event) => {
    setAssignmentConfig({
      ...assignmentConfig,
      schedulingType: event.target.value,
    });
  };

  const handleFixedHostsToggle = (event) => {
    setAssignmentConfig({
      ...assignmentConfig,
      fixedHosts: {
        ...assignmentConfig.fixedHosts,
        enabled: event.target.checked,
      },
    });
  };

  const handleAddAllTeamMembersToggle = (hostType) => (event) => {
    if (hostType === "fixed") {
      setAssignmentConfig({
        ...assignmentConfig,
        fixedHosts: {
          ...assignmentConfig.fixedHosts,
          addAllTeamMembers: event.target.checked,
        },
      });
    } else if (hostType === "roundRobin") {
      setAssignmentConfig({
        ...assignmentConfig,
        roundRobinHosts: {
          ...assignmentConfig.roundRobinHosts,
          addAllTeamMembers: event.target.checked,
        },
      });
    }
  };

  const handleRoundRobinHostsToggle = (event) => {
    setAssignmentConfig({
      ...assignmentConfig,
      roundRobinHosts: {
        ...assignmentConfig.roundRobinHosts,
        enabled: event.target.checked,
      },
    });
  };

  const handleUserSelect = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleAddHost = (hostType) => {
    if (!selectedUser) return;

    if (hostType === "fixed") {
      // Check if user is already added
      if (assignmentConfig.fixedHosts.hosts.includes(selectedUser)) {
        return;
      }

      setAssignmentConfig({
        ...assignmentConfig,
        fixedHosts: {
          ...assignmentConfig.fixedHosts,
          hosts: [...assignmentConfig.fixedHosts.hosts, selectedUser],
        },
      });
    } else if (hostType === "roundRobin") {
      // Check if user is already added
      if (assignmentConfig.roundRobinHosts.hosts.includes(selectedUser)) {
        return;
      }

      setAssignmentConfig({
        ...assignmentConfig,
        roundRobinHosts: {
          ...assignmentConfig.roundRobinHosts,
          hosts: [...assignmentConfig.roundRobinHosts.hosts, selectedUser],
        },
      });
    }

    // Reset selection
    setSelectedUser("");
  };

  const handleRemoveHost = (hostType, hostId) => {
    if (hostType === "fixed") {
      setAssignmentConfig({
        ...assignmentConfig,
        fixedHosts: {
          ...assignmentConfig.fixedHosts,
          hosts: assignmentConfig.fixedHosts.hosts.filter(
            (id) => id !== hostId
          ),
        },
      });
    } else if (hostType === "roundRobin") {
      setAssignmentConfig({
        ...assignmentConfig,
        roundRobinHosts: {
          ...assignmentConfig.roundRobinHosts,
          hosts: assignmentConfig.roundRobinHosts.hosts.filter(
            (id) => id !== hostId
          ),
        },
      });
    }
  };

  // Helper function to strip __typename fields
  const stripTypename = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(stripTypename);
    } else if (obj !== null && typeof obj === "object") {
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        if (key !== "__typename") {
          newObj[key] = stripTypename(obj[key]);
        }
      });
      return newObj;
    }
    return obj;
  };

  const saveAssignment = async () => {
    try {
      // Strip __typename fields before sending to the server
      const cleanAssignmentConfig = stripTypename(assignmentConfig);

      await updateAssignment({
        variables: {
          input: {
            id,
            assignmentConfig: cleanAssignmentConfig,
          },
        },
      });
    } catch (err) {
      console.error("Error saving assignment:", err);
    }
  };

  // Find user by ID
  const getUserById = (userId) => {
    return users.find((user) => user.id === userId) || { name: "Unknown User" };
  };

  // Render host list
  const renderHostList = (hostType, hosts) => {
    return hosts.map((hostId) => {
      const user = getUserById(hostId);
      return (
        <Box key={hostId} mb={2} p={2} border="1px solid #c4c4c4">
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="subtitle1">{user.name}</Typography>
            </Grid>
            <Grid item xs={2}>
              <DeleteIcon
                style={{ float: "right", cursor: "pointer" }}
                onClick={() => handleRemoveHost(hostType, hostId)}
              />
            </Grid>
          </Grid>
        </Box>
      );
    });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ my: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Grid justifyContent="space-between" container spacing={10}>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom display="inline">
          Assignment
        </Typography>
        <Typography>
          Schedule meetings when everyone is available or rotate through members
          on your team
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Box style={{ marginBottom: "15px" }}>
          <Grid container spacing={6}>
            <Grid item md={12} style={{ marginBottom: "15px" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="scheduling-type-label">
                  Scheduling Type
                </InputLabel>
                <Select
                  labelId="scheduling-type-label"
                  value={assignmentConfig.schedulingType}
                  onChange={handleSchedulingTypeChange}
                  label="Scheduling Type"
                >
                  <MenuItem value="Collective">Collective</MenuItem>
                  <MenuItem value="Round Robin">Round Robin</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {/* Collective scheduling type content */}
        {assignmentConfig.schedulingType === "Collective" && (
          <>
            <Divider />
            <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
              <Grid item md={12}>
                <ListItem alignItems="flex-start" disablePadding>
                  <Grid container>
                    <Grid md={6}>
                      <Typography variant="subtitle1">
                        Fixed Hosts
                        <Typography color="textSecondary" variant="body2">
                          Add anyone who needs to attend the event
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid md={6} style={{ textAlign: "right" }}>
                      <Checkbox
                        checked={assignmentConfig.fixedHosts.enabled}
                        onChange={handleFixedHostsToggle}
                      />
                    </Grid>
                  </Grid>
                </ListItem>

                {assignmentConfig.fixedHosts.enabled && (
                  <Grid container spacing={6} style={{ marginTop: "0px" }}>
                    <Grid item md={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                assignmentConfig.fixedHosts.addAllTeamMembers
                              }
                              onChange={handleAddAllTeamMembersToggle("fixed")}
                            />
                          }
                          label="Add all team members, including future members"
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item md={12}>
                      <Grid container spacing={6}>
                        <Grid item md={12}>
                          <FormControl sx={{ width: "100%" }}>
                            <InputLabel id="user-select-label">
                              Select User
                            </InputLabel>
                            <Select
                              labelId="user-select-label"
                              value={selectedUser}
                              onChange={handleUserSelect}
                              label="Select User"
                            >
                              {users.map((user) => (
                                <MenuItem key={user.id} value={user.id}>
                                  {user.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "16px" }}
                            onClick={() => handleAddHost("fixed")}
                            disabled={!selectedUser}
                          >
                            Add Host
                          </Button>
                        </Grid>

                        <Grid item md={12}>
                          {renderHostList(
                            "fixed",
                            assignmentConfig.fixedHosts.hosts
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </>
        )}

        {/* Round Robin scheduling type content */}
        {assignmentConfig.schedulingType === "Round Robin" && (
          <>
            <Divider />
            <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
              <Grid item md={12}>
                <ListItem alignItems="flex-start" disablePadding>
                  <Grid container>
                    <Grid md={6}>
                      <Typography variant="subtitle1">
                        Fixed Hosts
                        <Typography color="textSecondary" variant="body2">
                          Add anyone who needs to attend the event
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid md={6} style={{ textAlign: "right" }}>
                      <Checkbox
                        checked={assignmentConfig.fixedHosts.enabled}
                        onChange={handleFixedHostsToggle}
                      />
                    </Grid>
                  </Grid>
                </ListItem>

                {assignmentConfig.fixedHosts.enabled && (
                  <Grid container spacing={6} style={{ marginTop: "0px" }}>
                    <Grid item md={12}>
                      <Grid container spacing={6}>
                        <Grid item md={12}>
                          <FormControl sx={{ width: "100%" }}>
                            <InputLabel id="user-select-label">
                              Select User
                            </InputLabel>
                            <Select
                              labelId="user-select-label"
                              value={selectedUser}
                              onChange={handleUserSelect}
                              label="Select User"
                            >
                              {users.map((user) => (
                                <MenuItem key={user.id} value={user.id}>
                                  {user.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "16px" }}
                            onClick={() => handleAddHost("fixed")}
                            disabled={!selectedUser}
                          >
                            Add Host
                          </Button>
                        </Grid>

                        <Grid item md={12}>
                          {renderHostList(
                            "fixed",
                            assignmentConfig.fixedHosts.hosts
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>

            <Divider />
            <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
              <Grid item md={12}>
                <ListItem alignItems="flex-start" disablePadding>
                  <Grid container>
                    <Grid md={6}>
                      <Typography variant="subtitle1">
                        Round-Robin Hosts
                        <Typography color="textSecondary" variant="body2">
                          People in the group take turns and only one person
                          will show up for the event
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid md={6} style={{ textAlign: "right" }}>
                      <Checkbox
                        checked={assignmentConfig.roundRobinHosts.enabled}
                        onChange={handleRoundRobinHostsToggle}
                      />
                    </Grid>
                  </Grid>
                </ListItem>

                {assignmentConfig.roundRobinHosts.enabled && (
                  <Grid container spacing={6} style={{ marginTop: "0px" }}>
                    <Grid item md={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                assignmentConfig.roundRobinHosts
                                  .addAllTeamMembers
                              }
                              onChange={handleAddAllTeamMembersToggle(
                                "roundRobin"
                              )}
                            />
                          }
                          label="Add all team members, including future members"
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item md={12}>
                      <Grid container spacing={6}>
                        <Grid item md={12}>
                          <FormControl sx={{ width: "100%" }}>
                            <InputLabel id="round-robin-user-select-label">
                              Select User
                            </InputLabel>
                            <Select
                              labelId="round-robin-user-select-label"
                              value={selectedUser}
                              onChange={handleUserSelect}
                              label="Select User"
                            >
                              {users.map((user) => (
                                <MenuItem key={user.id} value={user.id}>
                                  {user.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "16px" }}
                            onClick={() => handleAddHost("roundRobin")}
                            disabled={!selectedUser}
                          >
                            Add Host
                          </Button>
                        </Grid>

                        <Grid item md={12}>
                          {renderHostList(
                            "roundRobin",
                            assignmentConfig.roundRobinHosts.hosts
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={saveAssignment}
          disabled={
            saveLoading ||
            JSON.stringify(assignmentConfig) ===
              JSON.stringify(initialAssignment)
          }
        >
          {saveLoading ? <CircularProgress size={24} /> : "Save"}
        </Button>

        {/* Alert for success/failure messages */}
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={alert.severity}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}

export default Assignment;
