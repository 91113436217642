import "react-app-polyfill/stable";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import "font-awesome/css/font-awesome.min.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { serverConfig } from "../src/config";

import "chart.js/auto";

import App from "./App";
import reportWebVitals from "./utils/reportWebVitals";
import { ThemeProvider } from "./contexts/ThemeContext";

// Get the backend URI from environment variables
const BACKEND_URI = serverConfig.redirectURI || "http://localhost:3000";
const GRAPHQL_URI = `${BACKEND_URI}/graphql`;

// Note: Remove the following line if you want to disable the API mocks.
// import "./mocks";
const client = new ApolloClient({
  uri: GRAPHQL_URI, // Using environment variable
  cache: new InMemoryCache(),
});

// Log connection information in development environment
if (process.env.NODE_ENV === "development") {
  console.log(`Apollo Client connecting to GraphQL endpoint: ${GRAPHQL_URI}`);
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
