import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { Sliders, Users } from "react-feather";
import {
  Grid, // Import Grid from react-feather
  CheckSquare, // Import CheckSquare from react-feather
  Map,
} from "react-feather";

const useEventItems = () => {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    const savedPermissions =
      JSON.parse(localStorage.getItem("rbacPermissions")) || [];

    if (isInitialized) {
      if (isAuthenticated) {
        if (user?.subscriptionLevel === "1") {
          const allPagesNavItems = [
            {
              title: "Events",
              pages: [
                {
                  href: "dashboard",
                  icon: Sliders,
                  title: "Dashboard",
                },
                {
                  href: "/events/events",
                  icon: Users,
                  title: "Events",
                },
              ],
            },
          ];

          if (JSON.stringify(navItems) !== JSON.stringify(allPagesNavItems)) {
            setNavItems(allPagesNavItems);
          }
        } else {
          const pagesSection = [
            {
              href: "settings",
              icon: Sliders,
              title: "Dashboard",
            },
            {
              href: "/events/events",
              icon: Users,
              title: "Events",
            },
            savedPermissions.includes("VIEW_USERS") && {
              href: "/settings/users",
              icon: Users,
              title: "User Management",
            },
            savedPermissions.includes("VIEW_GROUPS") && {
              href: "/settings/groups",
              icon: Grid,
              title: "Groups",
            },
            savedPermissions.includes("VIEW_ROLES_PERMISSIONS") && {
              href: "/settings/roles-permissions",
              icon: CheckSquare,
              title: "Roles & Permissions",
            },
            savedPermissions.includes("VIEW_SPACE_MANAGEMENT") && {
              href: "/space-management",
              icon: Map,
              title: "Space Management",
            },
          ].filter(Boolean); // Remove any undefined entries

          const gettingStartedNavItems = [
            {
              title: "Getting Started",
              pages: pagesSection,
            },
          ];

          if (
            JSON.stringify(navItems) !== JSON.stringify(gettingStartedNavItems)
          ) {
            setNavItems(gettingStartedNavItems);
          }
        }
      } else {
        if (navItems.length !== 0) {
          setNavItems([]);
        }
      }
    }
  }, [isInitialized, isAuthenticated, user]);

  return navItems;
};

export default useEventItems;
