// src/pages/auth/SignIn.js
import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useLocation, Link } from "react-router-dom";
import { Paper, Typography, Box } from "@mui/material";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import Notification from "../../components/Common/Notification";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const ForgotPasswordLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary.main};
  display: block;
  margin-top: 16px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

function SignIn() {
  const location = useLocation();
  const [notification, setNotification] = React.useState(
    location.state?.message || null
  );

  // Clear location state after reading it to prevent notification on refresh
  React.useEffect(() => {
    if (location.state?.message) {
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  return (
    <React.Fragment>
      <Link to="/">
        <Brand />
      </Link>

      {/* Notification component that appears at the bottom left */}
      <Notification
        message={notification}
        severity="success"
        position={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => setNotification(null)}
      />

      <Wrapper>
        <Helmet title="Sign In" />
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account to continue
        </Typography>
        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
