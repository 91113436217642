import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink, Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { axiosInstance } from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";
import { DataGrid } from "@mui/x-data-grid";
import { alpha } from "@mui/material/styles";
import {
  Alert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  TablePagination,
  Toolbar,
  Tooltip,
  Typography,
  Snackbar,
  Menu,
  MenuItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card as MuiCard,
  CardContent,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Visibility as VisibilityIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import SearchBar from "../../components/SearchBar";

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const Card = styled(MuiCard)(spacing);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Event Type Card Component
const EventTypeCard = ({ eventType, isSelected, onSelect, serialNumber }) => {
  const stripHtmlAndLimitText = (html, limit = 100) => {
    // Create a temporary div to parse HTML content
    const temp = document.createElement("div");
    temp.innerHTML = html;

    // Get text content without HTML tags
    const plainText = temp.textContent || temp.innerText || "";

    // Limit to specified character count
    if (plainText.length <= limit) {
      return plainText;
    }

    // Return truncated text with ellipsis
    return plainText.substring(0, limit) + "...";
  };

  return (
    <Paper>
      <Card>
        <div style={{ position: "relative" }}>
          <div
            style={{
              backgroundColor: "#f0f0f0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "140px",
            }}
          >
            <Typography variant="h2" color="textSecondary">
              {eventType.title.charAt(0).toUpperCase()}
            </Typography>
          </div>
          <Checkbox
            checked={isSelected}
            onChange={(e) => onSelect(e, eventType.id)}
            style={{ position: "absolute", top: "8px", left: "8px" }}
          />
        </div>
        <CardContent>
          <Box>
            <Typography variant="h5" gutterBottom>
              {eventType.title}
            </Typography>
          </Box>
          <Typography mb={2} color="textSecondary" component="p">
            S.No: {serialNumber}
            <br />
            URL: {eventType.url}
            <br />
            Duration: {eventType.duration} Min's
            <br />
            {stripHtmlAndLimitText(eventType.description, 60)}
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
};

function EnhancedTableToolbar(props) {
  const { numSelected, onBulkDelete, onBulkEdit, viewStyle, setViewStyle } =
    props;

  const handleViewClick = () => {
    setViewStyle(viewStyle === "List" ? "Card" : "List");
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "70px",
        borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
      }}
    >
      <div>
        {numSelected > 0 && (
          <Typography
            color="inherit"
            variant="subtitle1"
            sx={{ fontWeight: 500 }}
          >
            {numSelected} selected
          </Typography>
        )}
      </div>

      <Box>
        {numSelected > 0 ? (
          <Box display="flex">
            {numSelected === 1 && (
              <Tooltip title="Edit">
                <IconButton
                  aria-label="Edit"
                  size="large"
                  onClick={onBulkEdit}
                  sx={{ mr: 1 }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                size="large"
                onClick={onBulkDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Tooltip
            title={
              viewStyle === "List"
                ? "Change to Card View"
                : "Change to List View"
            }
          >
            <IconButton
              color="primary"
              onClick={handleViewClick}
              size="medium"
              sx={{
                borderRadius: "8px",
                p: 1,
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.08),
                "&:hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.12),
                },
              }}
            >
              {viewStyle === "List" ? <ViewModuleIcon /> : <ViewListIcon />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Toolbar>
  );
}

function EventTypes() {
  const { user } = useAuth();
  const location = useLocation();
  const message = location.state?.message;

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [eventTypes, setEventTypes] = useState([]);
  const [teams, setTeams] = useState([]);
  const [anchorElM, setAnchorElM] = useState(null);
  const openM = Boolean(anchorElM);

  // States for data grid
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("title");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [viewStyle, setViewStyle] = useState("List");
  const [searchQuery, setSearchQuery] = useState("");
  const [bulkDeleteDialog, setBulkDeleteDialog] = useState(false);

  // Reset page when search query changes
  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter event types based on search query
  const filteredEventTypes = eventTypes.filter((eventType) => {
    if (!searchQuery) return true;

    const query = searchQuery.toLowerCase();
    return (
      eventType.title.toLowerCase().includes(query) ||
      eventType.url.toLowerCase().includes(query) ||
      eventType.id.toString().toLowerCase().includes(query) ||
      (eventType.description &&
        eventType.description.toLowerCase().includes(query))
    );
  });

  const handleClickM = (event) => {
    setAnchorElM(event.currentTarget);
  };

  const handleCloseM = () => {
    setAnchorElM(null);
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = eventTypes.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleBulkDelete = () => {
    setBulkDeleteDialog(true);
  };

  const handleBulkEdit = () => {
    if (selected.length !== 1) {
      setAlert({
        open: true,
        message: "Please select exactly one event type to edit",
        severity: "info",
      });
      return;
    }

    // Navigate to edit page
    window.location.href = `/calendar/edit-event-type/${selected[0]}`;
  };

  const handleBulkDeleteConfirm = async () => {
    try {
      const deletePromises = selected.map((id) =>
        axiosInstance.post("/graphql", {
          query: `
            mutation DeleteEventType($id: ID!) {
              deleteEventType(id: $id)
            }
          `,
          variables: { id },
        })
      );

      await Promise.all(deletePromises);
      setEventTypes((prev) =>
        prev.filter((eventType) => !selected.includes(eventType.id))
      );
      setSelected([]);
      setAlert({
        open: true,
        message: `Successfully deleted ${selected.length} event types`,
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: `Error deleting event types: ${error.message}`,
        severity: "error",
      });
    } finally {
      setBulkDeleteDialog(false);
    }
  };

  // Fetch event types initially
  useEffect(() => {
    const fetchEventTypes = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query {
              getEventTypes {
                id
                title
                url
                duration
                description
                availabilityId
              }
            }
          `,
        });

        if (response.errors) {
          console.error(response.errors);
        } else {
          // Just use the event types as returned
          setEventTypes(response.data.getEventTypes);
        }
      } catch (error) {
        console.error("Error fetching event types:", error);
        setAlert({
          open: true,
          message: "Error fetching event types",
          severity: "error",
        });
      }
    };

    fetchEventTypes();
  }, []);

  // Fetch teams for the dropdown
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query {
              getTeams {
                id
                name
                url
              }
            }
          `,
        });

        if (response.data.errors) {
          console.error(response.data.errors);
        } else {
          setTeams(response.data.getTeams || []);
        }
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    fetchTeams();
  }, []);

  // Helper to strip HTML and limit text for description
  const stripHtmlAndLimitText = (html, limit = 100) => {
    if (!html) return "";

    // Create a temporary div to parse HTML content
    const temp = document.createElement("div");
    temp.innerHTML = html;

    // Get text content without HTML tags
    const plainText = temp.textContent || temp.innerText || "";

    // Limit to specified character count
    if (plainText.length <= limit) {
      return plainText;
    }

    // Return truncated text with ellipsis
    return plainText.substring(0, limit) + "...";
  };

  // Generate preview URL for an event
  const getPreviewUrl = (eventId) => {
    return `/preview/preview-event-type?eventTypeId=${eventId}`;
  };

  // Render card view
  const renderCardView = () => {
    const paginatedEventTypes = stableSort(
      filteredEventTypes,
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
      <>
        <Box px={2} py={2} display="flex" justifyContent="flex-end">
          <Checkbox
            indeterminate={
              selected.length > 0 && selected.length < filteredEventTypes.length
            }
            checked={
              filteredEventTypes.length > 0 &&
              selected.length === filteredEventTypes.length
            }
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all event types" }}
          />
          <Typography
            variant="body2"
            style={{ marginLeft: "8px", alignSelf: "center" }}
          >
            Select All
          </Typography>
        </Box>
        <Box m={1} pt={1}>
          <Grid container spacing={4}>
            {paginatedEventTypes.map((eventType, index) => (
              <Grid item key={eventType.id} xs={12} sm={6} md={4} lg={3}>
                <EventTypeCard
                  eventType={eventType}
                  isSelected={isSelected(eventType.id)}
                  onSelect={handleClick}
                  serialNumber={page * rowsPerPage + index + 1}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px 0",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredEventTypes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  marginBottom: "0",
                  display: "flex",
                  alignItems: "center",
                },
              ".MuiTablePagination-select": {
                marginRight: "32px",
                marginLeft: "8px",
              },
            }}
          />
        </Box>
      </>
    );
  };

  // Render table view with DataGrid
  const renderTableView = () => {
    // Get paginated data with pre-calculated serial numbers
    const paginatedEventTypes = stableSort(
      filteredEventTypes,
      getComparator(order, orderBy)
    )
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((eventType, index) => ({
        ...eventType,
        serialNumber: page * rowsPerPage + index + 1,
        description: stripHtmlAndLimitText(eventType.description, 100),
      }));

    // Define columns for DataGrid
    const columns = [
      {
        field: "select",
        headerName: "",
        width: 50,
        sortable: false,
        filterable: false,
        headerClassName: "select-all-header",
        renderHeader: () => (
          <Checkbox
            indeterminate={
              selected.length > 0 && selected.length < filteredEventTypes.length
            }
            checked={
              filteredEventTypes.length > 0 &&
              selected.length === filteredEventTypes.length
            }
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all event types" }}
          />
        ),
        renderCell: (params) => (
          <Checkbox
            checked={isSelected(params.row.id)}
            onClick={(event) => handleClick(event, params.row.id)}
            inputProps={{ "aria-labelledby": `select-${params.row.id}` }}
          />
        ),
      },
      {
        field: "serialNumber",
        headerName: "S.No",
        width: 70,
        sortable: false,
        filterable: false,
      },
      {
        field: "title",
        headerName: "Title",
        flex: 1,
        renderCell: (params) => (
          <Box>
            <Typography variant="subtitle1" fontWeight="medium">
              {params.row.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {params.row.description}
            </Typography>
          </Box>
        ),
      },
      {
        field: "url",
        headerName: "URL",
        flex: 0.7,
      },
      {
        field: "duration",
        headerName: "Duration",
        width: 100,
        valueGetter: (params) => `${params.row.duration} Min's`,
      },
      {
        field: "preview",
        headerName: "Preview",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Tooltip title="Preview Event">
            <IconButton
              component={Link}
              to={getPreviewUrl(params.row.id)}
              target="_blank"
              color="primary"
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    ];

    return (
      <>
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={paginatedEventTypes}
            columns={columns}
            hideFooter={true}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            checkboxSelection={false}
            rowHeight={70}
            headerHeight={56}
            getRowId={(row) => row.id}
            components={{
              Toolbar: () => null,
            }}
            sortingMode="server"
            sortModel={[
              {
                field: orderBy,
                sort: order.toLowerCase(),
              },
            ]}
            onSortModelChange={(model) => {
              if (model.length > 0) {
                setOrderBy(model[0].field);
                setOrder(model[0].sort === "asc" ? "asc" : "desc");
              }
            }}
            sx={{
              boxShadow: 0,
              border: 0,
              "& .MuiDataGrid-cell": {
                padding: "8px 16px",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "8px 16px",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredEventTypes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-toolbar": {
                alignItems: "center",
                minHeight: "40px",
                padding: "0 8px",
              },
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                },
              ".MuiTablePagination-select": {
                marginRight: "8px",
                marginLeft: "8px",
              },
              ".MuiTablePagination-actions": {
                display: "flex",
                alignItems: "center",
              },
            }}
          />
        </Box>
      </>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Event Types" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Event Types
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/private">
              Spaces
            </Link>
            <Link component={NavLink} to="/calendar/dashboard">
              Calendar
            </Link>
            <Typography color="text.primary">Event Types</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              id="basic-button"
              aria-controls={openM ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openM ? "true" : undefined}
              onClick={handleClickM}
              startIcon={<AddIcon />}
            >
              New Event Type
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElM}
              open={openM}
              onClose={handleCloseM}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem disabled={true}>
                <ListItemText variant="h5">Create Event On</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/calendar/add-event-types">
                <ListItemText>{user?.name || "Personal"}</ListItemText>
              </MenuItem>

              <MenuItem disabled={true}>
                <ListItemText variant="h5">Teams</ListItemText>
              </MenuItem>

              {teams.map((team) => (
                <MenuItem
                  key={team.id}
                  component={Link}
                  to={`/calendar/add-event-types?teamId=${team.id}`}
                >
                  <ListItemText>{team.name}</ListItemText>
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      {message && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {message}
        </Alert>
      )}

      <SearchBar
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Search event types..."
        containerSx={{
          borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
        }}
      />

      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onBulkDelete={handleBulkDelete}
          onBulkEdit={handleBulkEdit}
          viewStyle={viewStyle}
          setViewStyle={setViewStyle}
        />

        {/* Toggle between view styles */}
        {viewStyle === "List" ? renderTableView() : renderCardView()}
      </Paper>

      {/* Bulk Delete Dialog */}
      <Dialog
        open={bulkDeleteDialog}
        onClose={() => setBulkDeleteDialog(false)}
      >
        <DialogTitle>Confirm Bulk Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {selected.length} selected event
            types? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBulkDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={handleBulkDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete {selected.length} Event Types
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alert Snackbar */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default EventTypes;
