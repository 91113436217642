import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { Sliders, Users, Grid, CheckSquare, Map } from "react-feather";

const useCalendarItems = () => {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    const savedPermissions =
      JSON.parse(localStorage.getItem("rbacPermissions")) || [];

    if (isInitialized) {
      if (isAuthenticated) {
        if (user?.subscriptionLevel === "1") {
          const allPagesNavItems = [
            {
              title: "Calendar",
              pages: [
                {
                  href: "dashboard",
                  icon: Sliders,
                  title: "Dashboard",
                },
                {
                  href: "/calendar/event-types",
                  icon: Users,
                  title: "Event Types",
                },
                {
                  href: "/calendar/bookings",
                  icon: Grid,
                  title: "Bookings",
                },
                {
                  href: "/calendar/availability",
                  icon: CheckSquare,
                  title: "Availability",
                },
                {
                  href: "/calendar/teams",
                  icon: Map,
                  title: "Teams",
                },
              ],
            },
          ];

          if (JSON.stringify(navItems) !== JSON.stringify(allPagesNavItems)) {
            setNavItems(allPagesNavItems);
          }
        } else {
          const pagesSection = [
            {
              href: "settings",
              icon: Sliders,
              title: "Dashboard",
            },
            {
              href: "/settings/users",
              icon: Users,
              title: "Event Types",
            },
            {
              href: "/settings/groups",
              icon: Grid,
              title: "Bookings",
            },
            {
              href: "/settings/roles-permissions",
              icon: CheckSquare,
              title: "Availability",
            },
            {
              href: "/space-management",
              icon: Map,
              title: "Teams",
            },
            {
              href: "/space-management",
              icon: Map,
              title: "Apps",
            },
            {
              href: "/space-management",
              icon: Map,
              title: "Routing Forms",
            },
            {
              href: "/space-management",
              icon: Map,
              title: "Workflows",
            },
            {
              href: "/space-management",
              icon: Map,
              title: "View Public Page",
            },
            {
              href: "/space-management",
              icon: Map,
              title: "Settings",
            },
            savedPermissions.includes("VIEW_USERS") && {
              href: "/settings/users",
              icon: Users,
              title: "User Management",
            },
            savedPermissions.includes("VIEW_GROUPS") && {
              href: "/settings/groups",
              icon: Grid,
              title: "Groups",
            },
            savedPermissions.includes("VIEW_ROLES_PERMISSIONS") && {
              href: "/settings/roles-permissions",
              icon: CheckSquare,
              title: "Roles & Permissions",
            },
            savedPermissions.includes("VIEW_SPACE_MANAGEMENT") && {
              href: "/space-management",
              icon: Map,
              title: "Space Management",
            },
          ].filter(Boolean);

          const gettingStartedNavItems = [
            {
              title: "Getting Started",
              pages: pagesSection,
            },
          ];

          if (
            JSON.stringify(navItems) !== JSON.stringify(gettingStartedNavItems)
          ) {
            setNavItems(gettingStartedNavItems);
          }
        }
      } else {
        if (navItems.length !== 0) {
          setNavItems([]);
        }
      }
    }
  }, [isInitialized, isAuthenticated, user]);

  return navItems;
};

export default useCalendarItems;
