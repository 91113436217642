import React, { useContext, useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import EventTypeContext from "../../../contexts/EventTypeContext.js";
import DOMPurify from "dompurify";

import {
  Button,
  Card as MuiCard,
  CardContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Visibility as VisibilityIcon,
  Code as CodeIcon,
  Link as LinkIcon,
  Delete as DeleteIcon,
  FilterAlt as FilterAltIcon,
  ContentCopy as ContentCopyIcon,
} from "@mui/icons-material";

// GraphQL query to get event type data
const GET_EVENT_TYPE = gql`
  query GetEventType($id: ID!) {
    getEventType(id: $id) {
      id
      title
      description
      url
    }
  }
`;

// GraphQL mutation to delete event type
const DELETE_EVENT_TYPE = gql`
  mutation DeleteEventType($id: ID!) {
    deleteEventType(id: $id)
  }
`;

// Styled component
const Card = styled(MuiCard)(({ theme }) => ({
  marginBottom: theme ? theme.spacing(6) : "48px",
}));

// Styled div for toolbar
const ToolbarTitle = styled.div`
  min-width: 620px;
`;

function EventHeader({ eventTypeId }) {
  const navigate = useNavigate();
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [openEmbedDialog, setOpenEmbedDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Create a ref for the embed code input
  const embedCodeRef = useRef(null);

  // Access the context
  const { hasUnsavedChanges, setHasUnsavedChanges, currentTab } =
    useContext(EventTypeContext);

  // Component state to ensure we're tracking the latest context values
  const [saveEnabled, setSaveEnabled] = useState(false);

  // Fetch event type data
  const { loading, error, data } = useQuery(GET_EVENT_TYPE, {
    variables: { id: eventTypeId },
    skip: !eventTypeId,
  });

  // Delete event type mutation
  const [deleteEventType, { loading: deleteLoading }] = useMutation(
    DELETE_EVENT_TYPE,
    {
      onCompleted: () => {
        setAlertState({
          open: true,
          message: "Event type deleted successfully!",
          severity: "success",
        });
        // Redirect to event types list after a short delay
        setTimeout(() => {
          navigate("/calendar/event-types");
        }, 1500);
      },
      onError: (error) => {
        setAlertState({
          open: true,
          message: `Error deleting event type: ${error.message}`,
          severity: "error",
        });
        setOpenDeleteDialog(false);
      },
    }
  );

  // Effect to update local state based on context
  useEffect(() => {
    const isAvailabilityTab = currentTab === "Availability";
    const hasChanges = hasUnsavedChanges === true;
    const shouldEnable = isAvailabilityTab && hasChanges;

    setSaveEnabled(shouldEnable);
  }, [hasUnsavedChanges, currentTab]);

  // Set preview URL when data is loaded
  useEffect(() => {
    if (data && data.getEventType) {
      setPreviewUrl(`/preview/preview-event-type?eventTypeId=${eventTypeId}`);
    }
  }, [data, eventTypeId]);

  // Focus and select text in the embed code field when dialog opens
  useEffect(() => {
    if (openEmbedDialog && embedCodeRef.current) {
      setTimeout(() => {
        embedCodeRef.current.focus();
        embedCodeRef.current.select();
      }, 100);
    }
  }, [openEmbedDialog]);

  const handleClickShowing = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleCloseShowing = () => {
    setAnchorEl1(null);
  };

  const [switchValue, setSwitchValue] = useState(false);

  const handleSwitchChange = (event) => {
    setSwitchValue(event.target.checked);
  };

  // Handle preview button click
  const handlePreview = () => {
    window.open(previewUrl, "_blank");
  };

  // Handle copy link button click
  const handleCopyLink = () => {
    try {
      const fullUrl = window.location.origin + previewUrl;
      const textArea = document.createElement("textarea");
      textArea.value = fullUrl;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      const successful = document.execCommand("copy");
      document.body.removeChild(textArea);

      if (successful) {
        setAlertState({
          open: true,
          message: "Link copied to clipboard!",
          severity: "success",
        });
      } else {
        throw new Error("Copy command was unsuccessful");
      }
    } catch (err) {
      console.error("Copy failed:", err);
      setAlertState({
        open: true,
        message: "Failed to copy link. Try selecting and copying manually.",
        severity: "error",
      });
    }
  };

  // Handle embed button click
  const handleEmbed = () => {
    setOpenEmbedDialog(true);
  };

  // Handle copy from embed dialog
  const handleCopyEmbedCode = () => {
    try {
      // This is the key to fixing the issue - use the DOM element directly
      if (embedCodeRef.current) {
        embedCodeRef.current.focus();
        embedCodeRef.current.select();
        const successful = document.execCommand("copy");

        if (successful) {
          setAlertState({
            open: true,
            message: "Embed code copied to clipboard!",
            severity: "success",
          });
        } else {
          throw new Error("Copy command was unsuccessful");
        }
      } else {
        throw new Error("Embed code field not found");
      }
    } catch (err) {
      console.error("Copy failed:", err);
      setAlertState({
        open: true,
        message:
          "Failed to copy embed code. Try selecting and copying manually.",
        severity: "error",
      });
    }
  };

  // Handle delete button click
  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  // Confirm delete
  const confirmDelete = () => {
    deleteEventType({
      variables: { id: eventTypeId },
    });
  };

  const handleCloseAlert = () => {
    setAlertState({
      ...alertState,
      open: false,
    });
  };

  // Generate iframe code for embedding
  const getEmbedCode = () => {
    const fullUrl = window.location.origin + previewUrl;
    return `<iframe src="${fullUrl}" width="100%" height="700px" frameborder="0"></iframe>`;
  };

  if (loading) {
    return (
      <Card>
        <CardContent>
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <CardContent>
          <Typography color="error">
            Error loading event type: {error.message}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Toolbar style={{ paddingLeft: "0" }}>
          <ToolbarTitle>
            <Typography
              variant="subtitle1"
              style={{ paddingLeft: "12px", fontWeight: "bold" }}
            >
              {data?.getEventType?.title || "Loading..."}
              <Typography
                color="success"
                style={{ paddingLeft: "12px !important" }}
              >
                <div
                  style={{
                    position: "relative",
                    bottom: "4px",
                    paddingLeft: "2px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      data?.getEventType?.description ||
                        "Loading description..."
                    ),
                  }}
                />
              </Typography>
            </Typography>
          </ToolbarTitle>
          <Grid container display="block" textAlign="right">
            <Grid container>
              <Grid md={12} className="filter">
                <Tooltip title="Hide from profile">
                  <Switch
                    checked={switchValue}
                    onChange={handleSwitchChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Tooltip>
                <Tooltip title="Preview">
                  <IconButton
                    aria-label="Preview"
                    size="large"
                    onClick={handlePreview}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy link to event">
                  <IconButton
                    aria-label="Copy link"
                    size="large"
                    onClick={handleCopyLink}
                  >
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Embed">
                  <IconButton
                    aria-label="Embed"
                    size="large"
                    onClick={handleEmbed}
                  >
                    <CodeIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="Delete"
                    size="large"
                    onClick={handleDelete}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>

                <IconButton
                  aria-label="Filter"
                  size="large"
                  aria-owns={anchorEl1 ? "simple-menu-showing" : undefined}
                  aria-haspopup="true"
                  onClick={handleClickShowing}
                  variant="contained"
                  color="secondary"
                  sx={{ mr: 2, display: { xs: "block", md: "none" } }}
                >
                  <FilterAltIcon />
                </IconButton>
                <IconButton
                  aria-label="View"
                  size="large"
                  aria-owns={anchorEl1 ? "simple-menu-showing" : undefined}
                  aria-haspopup="true"
                  onClick={handleClickShowing}
                  variant="contained"
                  color="secondary"
                  sx={{ mr: 2, display: { xs: "block", md: "none" } }}
                >
                  <VisibilityIcon />
                </IconButton>
                <Menu
                  id="simple-menu-showing"
                  anchorEl={anchorEl1}
                  open={Boolean(anchorEl1)}
                  onClose={handleCloseShowing}
                >
                  <MenuItem>All</MenuItem>
                  <MenuItem>Upcoming</MenuItem>
                  <MenuItem>Ongoing</MenuItem>
                  <MenuItem>Paused</MenuItem>
                  <MenuItem>Completed</MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </CardContent>

      {/* Embed Dialog */}
      <Dialog
        open={openEmbedDialog}
        onClose={() => setOpenEmbedDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Embed Event Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Copy and paste this code to embed this event type on your website:
          </DialogContentText>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            value={getEmbedCode()}
            inputRef={embedCodeRef}
            InputProps={{
              readOnly: true,
            }}
          />
          <Button
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={handleCopyEmbedCode}
            sx={{ mt: 2 }}
          >
            Copy Code
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEmbedDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this event type? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            color="error"
            variant="contained"
            disabled={deleteLoading}
          >
            {deleteLoading ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alert Snackbar */}
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertState.severity}
          sx={{ width: "100%" }}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </Card>
  );
}

export default EventHeader;
