import React, { createContext, useState, useEffect, useContext } from "react";
import useAuth from "../../../hooks/useAuth";
import FullScreenLoader from "./FullScreenLoader";

// Create context
const AppContext = createContext();

// Provider component
export const AppProvider = ({ children }) => {
  const { user } = useAuth();
  const [isAppReady, setIsAppReady] = useState(false);

  useEffect(() => {
    // This effect runs when the authentication state changes
    const timeoutId = setTimeout(() => {
      // Fallback timeout to ensure the app loads even if auth state is unclear
      setIsAppReady(true);
    }, 1500);

    // If auth state is definitively known (user is either logged in or definitely not)
    if (user !== undefined) {
      // Still add a small timeout to prevent flickering
      const minLoadTime = setTimeout(() => {
        setIsAppReady(true);
        clearTimeout(timeoutId);
      }, 500);

      return () => clearTimeout(minLoadTime);
    }

    return () => clearTimeout(timeoutId);
  }, [user]);

  return (
    <AppContext.Provider value={{ isAppReady, user }}>
      {!isAppReady && <FullScreenLoader />}
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use the app context
export const useApp = () => useContext(AppContext);

export default AppContext;
