// src/components/auth/ChangePassword.js
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Button,
  CircularProgress,
  TextField as MuiTextField,
  Box,
  Typography,
  Paper,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
  max-width: 500px;
  margin: 0 auto;
`;

function ChangePassword({
  initialEmail = "",
  initialPasswordResetRequired = false,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateInitialPassword } = useAuth();

  // Initialize state for email and passwordResetRequired
  const [email, setEmail] = useState("");
  const [passwordResetRequired, setPasswordResetRequired] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // Effect to get email and passwordResetRequired from multiple sources
  useEffect(() => {
    // Priority order for email:
    // 1. localStorage
    // 2. location.state
    // 3. props
    const storedEmail = localStorage.getItem("resetEmail");
    const stateEmail = location.state?.email;

    // Set email based on priority
    if (storedEmail) {
      setEmail(storedEmail);
      console.log("Using email from localStorage:", storedEmail);
    } else if (stateEmail) {
      setEmail(stateEmail);
      console.log("Using email from location state:", stateEmail);
    } else if (initialEmail) {
      setEmail(initialEmail);
      console.log("Using email from props:", initialEmail);
    }

    // Priority order for passwordResetRequired:
    // 1. localStorage
    // 2. location.state
    // 3. props
    const storedResetRequired =
      localStorage.getItem("resetRequired") === "true";
    const stateResetRequired = location.state?.passwordResetRequired;

    if (storedResetRequired) {
      setPasswordResetRequired(true);
    } else if (stateResetRequired) {
      setPasswordResetRequired(stateResetRequired);
    } else {
      setPasswordResetRequired(initialPasswordResetRequired);
    }
  }, [location.state, initialEmail, initialPasswordResetRequired]);

  // If no email is provided and this is a required reset, redirect to login
  useEffect(() => {
    if (!email && passwordResetRequired) {
      navigate("/auth/sign-in");
    }
  }, [email, passwordResetRequired, navigate]);

  // Effect for handling successful password change and redirection
  useEffect(() => {
    if (isSuccess) {
      console.log("Password changed successfully, redirecting...");

      // Show success message for 2 seconds before redirecting
      const timer = setTimeout(() => {
        // Clear any remaining localStorage items related to the password reset
        localStorage.removeItem("temporaryToken");
        localStorage.removeItem("resetEmail");
        localStorage.removeItem("resetRequired");

        // Redirect to the appropriate page
        navigate("/private");
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isSuccess, navigate]);

  console.log("ChangePassword component - Email:", email);
  console.log(
    "ChangePassword component - Reset Required:",
    passwordResetRequired
  );

  return (
    <Wrapper>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Change Password
      </Typography>

      <Typography component="h2" variant="body1" align="center" mb={4}>
        {passwordResetRequired
          ? "You need to change your password before continuing."
          : "Enter your current password and new password."}
      </Typography>

      <Formik
        enableReinitialize // Important to update form when email state changes
        initialValues={{
          email: email,
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          currentPassword: Yup.string().required(
            "Current password is required"
          ),
          newPassword: Yup.string()
            .min(12, "Password must be at least 12 characters")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
              "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
            )
            .max(255)
            .required("New password is required"),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
            .required("Confirm password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          console.log("Submitting password change with email:", values.email);
          try {
            const result = await updateInitialPassword(
              values.email,
              values.currentPassword,
              values.newPassword
            );

            console.log("Password update result:", result);

            // Set success status and trigger the redirect
            setStatus({ success: true });
            setIsSuccess(true);
            setSubmitting(false);
          } catch (error) {
            console.error("Change password error:", error);
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {(status && status.success) || isSuccess ? (
              <Alert mt={2} mb={3} severity="success">
                Password changed successfully. Redirecting...
              </Alert>
            ) : null}

            {errors.submit && (
              <Alert mt={2} mb={3} severity="error">
                {errors.submit}
              </Alert>
            )}

            {passwordResetRequired && (
              <Alert mt={2} mb={3} severity="warning">
                Your account has been created with a temporary password. You
                must change your password before continuing.
              </Alert>
            )}

            <TextField
              type="email"
              name="email"
              label="Email"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
              disabled={passwordResetRequired} // Disable if coming from required reset
            />

            <TextField
              type="password"
              name="currentPassword"
              label={
                passwordResetRequired
                  ? "Temporary Password"
                  : "Current Password"
              }
              value={values.currentPassword}
              error={Boolean(touched.currentPassword && errors.currentPassword)}
              fullWidth
              helperText={touched.currentPassword && errors.currentPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />

            <TextField
              type="password"
              name="newPassword"
              label="New Password"
              value={values.newPassword}
              error={Boolean(touched.newPassword && errors.newPassword)}
              fullWidth
              helperText={touched.newPassword && errors.newPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />

            <TextField
              type="password"
              name="confirmPassword"
              label="Confirm New Password"
              value={values.confirmPassword}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              fullWidth
              helperText={touched.confirmPassword && errors.confirmPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting || isSuccess}
              sx={{ mt: 3 }}
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Change Password"
              )}
            </Button>

            {!passwordResetRequired && (
              <Button
                fullWidth
                color="secondary"
                onClick={() => navigate("/dashboard")}
                sx={{ mt: 2 }}
              >
                Cancel
              </Button>
            )}
          </form>
        )}
      </Formik>
    </Wrapper>
  );
}

export default ChangePassword;
