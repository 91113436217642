import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import EventTypeContext from "../../../contexts/EventTypeContext.js";
import ListItem from "@mui/material/ListItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import "../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  TextField,
  Typography,
  FormControlLabel,
  Snackbar,
  Alert,
} from "@mui/material";

// Styled components
const Card = styled(MuiPaper)(({ theme }) => ({
  padding: theme ? theme.spacing(3) : "24px",
}));

const Divider = styled(MuiDivider)(({ theme }) => ({
  margin: theme ? theme.spacing(3, 0) : "24px 0",
}));

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme ? theme.spacing(3) : "24px",
}));

// GraphQL queries and mutations
const GET_EVENT_TYPE = gql`
  query GetEventType($id: ID!) {
    getEventType(id: $id) {
      id
      title
      url
      description
      duration
      assignment
      availabilityId
      limits {
        beforeEvent
        afterEvent
        minimumNotice {
          value
          unit
        }
        timeSlotIntervals
        limitBookingFrequency {
          enabled
          perDay {
            enabled
            value
          }
          perWeek {
            enabled
            value
          }
          perMonth {
            enabled
            value
          }
          perYear {
            enabled
            value
          }
        }
        limitFirstSlotOnly
        limitTotalBookingDuration {
          enabled
          perDay {
            enabled
            value
          }
          perWeek {
            enabled
            value
          }
          perMonth {
            enabled
            value
          }
          perYear {
            enabled
            value
          }
        }
        limitFutureBookings {
          enabled
          limitByDays
          days {
            value
            unit
          }
          dateRange {
            fromDate
            toDate
          }
        }
        offsetStartTimes {
          enabled
          value
        }
      }
    }
  }
`;

const UPDATE_EVENT_TYPE = gql`
  mutation UpdateEventType($input: UpdateEventTypeInput!) {
    updateEventType(input: $input) {
      eventType {
        id
        limits {
          beforeEvent
          afterEvent
          minimumNotice {
            value
            unit
          }
          timeSlotIntervals
          limitBookingFrequency {
            enabled
          }
          limitFirstSlotOnly
          limitTotalBookingDuration {
            enabled
          }
          limitFutureBookings {
            enabled
          }
          offsetStartTimes {
            enabled
          }
        }
      }
    }
  }
`;

// Default limits state
const defaultLimits = {
  beforeEvent: "false",
  afterEvent: "false",
  minimumNotice: {
    value: 0,
    unit: "Minutes",
  },
  timeSlotIntervals: "false",
  limitBookingFrequency: {
    enabled: false,
    perDay: {
      enabled: false,
      value: 1,
    },
    perWeek: {
      enabled: false,
      value: 1,
    },
    perMonth: {
      enabled: false,
      value: 1,
    },
    perYear: {
      enabled: false,
      value: 1,
    },
  },
  limitFirstSlotOnly: false,
  limitTotalBookingDuration: {
    enabled: false,
    perDay: {
      enabled: false,
      value: 60,
    },
    perWeek: {
      enabled: false,
      value: 60,
    },
    perMonth: {
      enabled: false,
      value: 60,
    },
    perYear: {
      enabled: false,
      value: 60,
    },
  },
  limitFutureBookings: {
    enabled: false,
    limitByDays: true,
    days: {
      value: 30,
      unit: "Calendar Days",
    },
    dateRange: {
      fromDate: null,
      toDate: null,
    },
  },
  offsetStartTimes: {
    enabled: false,
    value: 0,
  },
};

// Helper function to remove __typename fields from objects
const removeTypenames = (obj) => {
  if (obj === null || obj === undefined) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => removeTypenames(item));
  }

  if (typeof obj === "object") {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      if (key !== "__typename") {
        newObj[key] = removeTypenames(obj[key]);
      }
    });
    return newObj;
  }

  return obj;
};

function EventEntryTab() {
  const { id } = useParams();
  const [limits, setLimits] = useState(defaultLimits);
  const [initialLimits, setInitialLimits] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Context for tracking changes
  const { setHasUnsavedChanges } = useContext(EventTypeContext);

  // Fetch event type data
  const { loading, error, data } = useQuery(GET_EVENT_TYPE, {
    variables: { id },
    fetchPolicy: "network-only",
    skip: !id,
    onCompleted: (data) => {
      if (data && data.getEventType) {
        // Use server data if available, otherwise use defaults
        const eventLimits = data.getEventType.limits || defaultLimits;
        // Clean up the data to remove any __typename fields
        const cleanLimits = removeTypenames(eventLimits);
        setLimits(cleanLimits);
        setInitialLimits(JSON.parse(JSON.stringify(cleanLimits))); // Deep clone
      }
    },
  });

  // Update event type mutation
  const [updateEventType, { loading: updateLoading }] = useMutation(
    UPDATE_EVENT_TYPE,
    {
      onCompleted: () => {
        setAlert({
          open: true,
          message: "Limits saved successfully!",
          severity: "success",
        });
        setHasChanged(false);
        if (setHasUnsavedChanges) {
          setHasUnsavedChanges(false);
        }
        setInitialLimits(JSON.parse(JSON.stringify(limits))); // Update initial state
      },
      onError: (error) => {
        setAlert({
          open: true,
          message: `Error saving limits: ${error.message}`,
          severity: "error",
        });
      },
    }
  );

  // Check if data has changed from initial state
  useEffect(() => {
    if (initialLimits) {
      const changed = JSON.stringify(limits) !== JSON.stringify(initialLimits);
      setHasChanged(changed);

      // Update context if needed
      if (setHasUnsavedChanges) {
        setHasUnsavedChanges(changed);
      }
    }
  }, [limits, initialLimits, setHasUnsavedChanges]);

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  // Handle save
  const handleSave = async () => {
    try {
      // Remove any __typename fields before sending to the server
      const cleanLimits = removeTypenames(limits);

      await updateEventType({
        variables: {
          input: {
            id,
            limits: cleanLimits,
          },
        },
      });
    } catch (error) {
      console.error("Error saving limits:", error);
    }
  };

  // Form field handlers
  const handleBeforeEventChange = (event) => {
    setLimits({
      ...limits,
      beforeEvent: event.target.value,
    });
  };

  const handleAfterEventChange = (event) => {
    setLimits({
      ...limits,
      afterEvent: event.target.value,
    });
  };

  const handleMinimumNoticeValueChange = (event) => {
    setLimits({
      ...limits,
      minimumNotice: {
        ...limits.minimumNotice,
        value: parseInt(event.target.value) || 0,
      },
    });
  };

  const handleMinimumNoticeUnitChange = (event) => {
    setLimits({
      ...limits,
      minimumNotice: {
        ...limits.minimumNotice,
        unit: event.target.value,
      },
    });
  };

  const handleTimeSlotIntervalsChange = (event) => {
    setLimits({
      ...limits,
      timeSlotIntervals: event.target.value,
    });
  };

  const handleLimitBookingFrequencyChange = (event) => {
    setLimits({
      ...limits,
      limitBookingFrequency: {
        ...limits.limitBookingFrequency,
        enabled: event.target.checked,
      },
    });
  };

  const handleFrequencyCheckboxChange = (period) => (event) => {
    setLimits({
      ...limits,
      limitBookingFrequency: {
        ...limits.limitBookingFrequency,
        [period]: {
          ...limits.limitBookingFrequency[period],
          enabled: event.target.checked,
        },
      },
    });
  };

  const handleFrequencyValueChange = (period) => (event) => {
    setLimits({
      ...limits,
      limitBookingFrequency: {
        ...limits.limitBookingFrequency,
        [period]: {
          ...limits.limitBookingFrequency[period],
          value: parseInt(event.target.value) || 1,
        },
      },
    });
  };

  const handleLimitFirstSlotOnlyChange = (event) => {
    setLimits({
      ...limits,
      limitFirstSlotOnly: event.target.checked,
    });
  };

  const handleLimitTotalBookingDurationChange = (event) => {
    setLimits({
      ...limits,
      limitTotalBookingDuration: {
        ...limits.limitTotalBookingDuration,
        enabled: event.target.checked,
      },
    });
  };

  const handleDurationCheckboxChange = (period) => (event) => {
    setLimits({
      ...limits,
      limitTotalBookingDuration: {
        ...limits.limitTotalBookingDuration,
        [period]: {
          ...limits.limitTotalBookingDuration[period],
          enabled: event.target.checked,
        },
      },
    });
  };

  const handleDurationValueChange = (period) => (event) => {
    setLimits({
      ...limits,
      limitTotalBookingDuration: {
        ...limits.limitTotalBookingDuration,
        [period]: {
          ...limits.limitTotalBookingDuration[period],
          value: parseInt(event.target.value) || 60,
        },
      },
    });
  };

  const handleLimitFutureBookingsChange = (event) => {
    setLimits({
      ...limits,
      limitFutureBookings: {
        ...limits.limitFutureBookings,
        enabled: event.target.checked,
      },
    });
  };

  const handleLimitByDaysChange = (event) => {
    setLimits({
      ...limits,
      limitFutureBookings: {
        ...limits.limitFutureBookings,
        limitByDays: event.target.value === "true",
      },
    });
  };

  const handleDaysValueChange = (event) => {
    setLimits({
      ...limits,
      limitFutureBookings: {
        ...limits.limitFutureBookings,
        days: {
          ...limits.limitFutureBookings.days,
          value: parseInt(event.target.value) || 30,
        },
      },
    });
  };

  const handleDaysUnitChange = (event) => {
    setLimits({
      ...limits,
      limitFutureBookings: {
        ...limits.limitFutureBookings,
        days: {
          ...limits.limitFutureBookings.days,
          unit: event.target.value,
        },
      },
    });
  };

  const handleFromDateChange = (newDate) => {
    setLimits({
      ...limits,
      limitFutureBookings: {
        ...limits.limitFutureBookings,
        dateRange: {
          ...limits.limitFutureBookings.dateRange,
          fromDate: newDate ? newDate.toISOString() : null,
        },
      },
    });
  };

  const handleToDateChange = (newDate) => {
    setLimits({
      ...limits,
      limitFutureBookings: {
        ...limits.limitFutureBookings,
        dateRange: {
          ...limits.limitFutureBookings.dateRange,
          toDate: newDate ? newDate.toISOString() : null,
        },
      },
    });
  };

  const handleOffsetStartTimesChange = (event) => {
    setLimits({
      ...limits,
      offsetStartTimes: {
        ...limits.offsetStartTimes,
        enabled: event.target.checked,
      },
    });
  };

  const handleOffsetValueChange = (event) => {
    setLimits({
      ...limits,
      offsetStartTimes: {
        ...limits.offsetStartTimes,
        value: parseInt(event.target.value) || 0,
      },
    });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ my: 2 }}>
        Error loading event type data: {error.message}
      </Alert>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item md={12}>
          <Typography variant="h3" gutterBottom display="inline">
            Limits
          </Typography>
          <Typography>
            Configure booking limits and constraints for this event type
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Box style={{ marginBottom: "15px" }}>
            <Grid container spacing={6}>
              <Grid item md={6} style={{ marginBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel htmlFor="before-event">Before Event</InputLabel>
                  <Select
                    id="before-event"
                    value={limits.beforeEvent}
                    onChange={handleBeforeEventChange}
                    label="Before Event"
                  >
                    <MenuItem value="false">No Buffer Time</MenuItem>
                    <MenuItem value="xs">5 Minutes</MenuItem>
                    <MenuItem value="sm">10 Minutes</MenuItem>
                    <MenuItem value="md">15 Minutes</MenuItem>
                    <MenuItem value="lg">20 Minutes</MenuItem>
                    <MenuItem value="xl">30 Minutes</MenuItem>
                    <MenuItem value="xl-45">45 Minutes</MenuItem>
                    <MenuItem value="xl-60">60 Minutes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} style={{ marginBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel htmlFor="after-event">After Event</InputLabel>
                  <Select
                    id="after-event"
                    value={limits.afterEvent}
                    onChange={handleAfterEventChange}
                    label="After Event"
                  >
                    <MenuItem value="false">No Buffer Time</MenuItem>
                    <MenuItem value="xs">5 Minutes</MenuItem>
                    <MenuItem value="sm">10 Minutes</MenuItem>
                    <MenuItem value="md">15 Minutes</MenuItem>
                    <MenuItem value="lg">20 Minutes</MenuItem>
                    <MenuItem value="xl">30 Minutes</MenuItem>
                    <MenuItem value="xl-45">45 Minutes</MenuItem>
                    <MenuItem value="xl-60">60 Minutes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <Grid container spacing={6}>
                  <Grid item md={6} style={{ marginBottom: "15px" }}>
                    <TextField
                      label="Minimum Notice"
                      value={limits.minimumNotice.value}
                      onChange={handleMinimumNoticeValueChange}
                      variant="outlined"
                      fullWidth
                      type="number"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="minimum-notice-unit">
                        Unit
                      </InputLabel>
                      <Select
                        id="minimum-notice-unit"
                        value={limits.minimumNotice.unit}
                        onChange={handleMinimumNoticeUnitChange}
                        label="Unit"
                      >
                        <MenuItem value="Minutes">Minutes</MenuItem>
                        <MenuItem value="Hours">Hours</MenuItem>
                        <MenuItem value="Days">Days</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} style={{ marginBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel htmlFor="time-slot-intervals">
                    Time Slot Intervals
                  </InputLabel>
                  <Select
                    id="time-slot-intervals"
                    value={limits.timeSlotIntervals}
                    onChange={handleTimeSlotIntervalsChange}
                    label="Time Slot Intervals"
                  >
                    <MenuItem value="false">
                      Use Event Length (Default)
                    </MenuItem>
                    <MenuItem value="xs">5 Minutes</MenuItem>
                    <MenuItem value="sm">10 Minutes</MenuItem>
                    <MenuItem value="md">15 Minutes</MenuItem>
                    <MenuItem value="lg">20 Minutes</MenuItem>
                    <MenuItem value="xl">30 Minutes</MenuItem>
                    <MenuItem value="xl-45">45 Minutes</MenuItem>
                    <MenuItem value="xl-60">60 Minutes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Divider />

          {/* Limit booking frequency */}
          <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
            <Grid item md={12}>
              <ListItem alignItems="flex-start" disablePadding>
                <Grid container>
                  <Grid md={6}>
                    <Typography variant="subtitle1">
                      Limit booking frequency
                      <Typography color="textSecondary" variant="body2">
                        Limit how many times this event can be booked
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid md={6}>
                    <Switch
                      checked={limits.limitBookingFrequency.enabled}
                      onChange={handleLimitBookingFrequencyChange}
                      inputProps={{ "aria-label": "controlled" }}
                      style={{ float: "right" }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </Grid>
            {limits.limitBookingFrequency.enabled && (
              <div>
                <Grid container spacing={6}>
                  <Grid
                    item
                    md={1}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <Checkbox
                      checked={limits.limitBookingFrequency.perDay.enabled}
                      onChange={handleFrequencyCheckboxChange("perDay")}
                      style={{ position: "relative", top: "5px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <TextField
                      value={limits.limitBookingFrequency.perDay.value}
                      onChange={handleFrequencyValueChange("perDay")}
                      variant="outlined"
                      fullWidth
                      type="number"
                      disabled={!limits.limitBookingFrequency.perDay.enabled}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Select disabled value="perDay">
                        <MenuItem value="perDay">Per Day</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={6}>
                  <Grid
                    item
                    md={1}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <Checkbox
                      checked={limits.limitBookingFrequency.perWeek.enabled}
                      onChange={handleFrequencyCheckboxChange("perWeek")}
                      style={{ position: "relative", top: "5px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <TextField
                      value={limits.limitBookingFrequency.perWeek.value}
                      onChange={handleFrequencyValueChange("perWeek")}
                      variant="outlined"
                      fullWidth
                      type="number"
                      disabled={!limits.limitBookingFrequency.perWeek.enabled}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Select disabled value="perWeek">
                        <MenuItem value="perWeek">Per Week</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={6}>
                  <Grid
                    item
                    md={1}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <Checkbox
                      checked={limits.limitBookingFrequency.perMonth.enabled}
                      onChange={handleFrequencyCheckboxChange("perMonth")}
                      style={{ position: "relative", top: "5px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <TextField
                      value={limits.limitBookingFrequency.perMonth.value}
                      onChange={handleFrequencyValueChange("perMonth")}
                      variant="outlined"
                      fullWidth
                      type="number"
                      disabled={!limits.limitBookingFrequency.perMonth.enabled}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Select disabled value="perMonth">
                        <MenuItem value="perMonth">Per Month</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={6}>
                  <Grid
                    item
                    md={1}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <Checkbox
                      checked={limits.limitBookingFrequency.perYear.enabled}
                      onChange={handleFrequencyCheckboxChange("perYear")}
                      style={{ position: "relative", top: "5px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <TextField
                      value={limits.limitBookingFrequency.perYear.value}
                      onChange={handleFrequencyValueChange("perYear")}
                      variant="outlined"
                      fullWidth
                      type="number"
                      disabled={!limits.limitBookingFrequency.perYear.enabled}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Select disabled value="perYear">
                        <MenuItem value="perYear">Per Year</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}
          </Box>
          <Divider />

          {/* Limit booking first slot only */}
          <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
            <Grid item md={12}>
              <ListItem alignItems="flex-start" disablePadding>
                <Grid container>
                  <Grid md={6}>
                    <Typography variant="subtitle1">
                      Limit booking only first slot
                      <Typography color="textSecondary" variant="body2">
                        Allow only the first slot of every day to be booked
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid md={6}>
                    <Switch
                      checked={limits.limitFirstSlotOnly}
                      onChange={handleLimitFirstSlotOnlyChange}
                      inputProps={{ "aria-label": "controlled" }}
                      style={{ float: "right" }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </Grid>
          </Box>
          <Divider />

          {/* Limit total booking duration */}
          <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
            <Grid item md={12}>
              <ListItem alignItems="flex-start" disablePadding>
                <Grid container>
                  <Grid md={6}>
                    <Typography variant="subtitle1">
                      Limit total booking duration
                      <Typography color="textSecondary" variant="body2">
                        Limit total amount of time that this event can be booked
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid md={6}>
                    <Switch
                      checked={limits.limitTotalBookingDuration.enabled}
                      onChange={handleLimitTotalBookingDurationChange}
                      inputProps={{ "aria-label": "controlled" }}
                      style={{ float: "right" }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </Grid>
            {limits.limitTotalBookingDuration.enabled && (
              <div>
                <Grid container spacing={6}>
                  <Grid
                    item
                    md={1}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <Checkbox
                      checked={limits.limitTotalBookingDuration.perDay.enabled}
                      onChange={handleDurationCheckboxChange("perDay")}
                      style={{ position: "relative", top: "5px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <TextField
                      value={limits.limitTotalBookingDuration.perDay.value}
                      onChange={handleDurationValueChange("perDay")}
                      variant="outlined"
                      fullWidth
                      type="number"
                      disabled={
                        !limits.limitTotalBookingDuration.perDay.enabled
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button disabled>Minutes</Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Select disabled value="perDay">
                        <MenuItem value="perDay">Per Day</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={6}>
                  <Grid
                    item
                    md={1}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <Checkbox
                      checked={limits.limitTotalBookingDuration.perWeek.enabled}
                      onChange={handleDurationCheckboxChange("perWeek")}
                      style={{ position: "relative", top: "5px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <TextField
                      value={limits.limitTotalBookingDuration.perWeek.value}
                      onChange={handleDurationValueChange("perWeek")}
                      variant="outlined"
                      fullWidth
                      type="number"
                      disabled={
                        !limits.limitTotalBookingDuration.perWeek.enabled
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button disabled>Minutes</Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Select disabled value="perWeek">
                        <MenuItem value="perWeek">Per Week</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={6}>
                  <Grid
                    item
                    md={1}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <Checkbox
                      checked={
                        limits.limitTotalBookingDuration.perMonth.enabled
                      }
                      onChange={handleDurationCheckboxChange("perMonth")}
                      style={{ position: "relative", top: "5px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <TextField
                      value={limits.limitTotalBookingDuration.perMonth.value}
                      onChange={handleDurationValueChange("perMonth")}
                      variant="outlined"
                      fullWidth
                      type="number"
                      disabled={
                        !limits.limitTotalBookingDuration.perMonth.enabled
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button disabled>Minutes</Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Select disabled value="perMonth">
                        <MenuItem value="perMonth">Per Month</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={6}>
                  <Grid
                    item
                    md={1}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <Checkbox
                      checked={limits.limitTotalBookingDuration.perYear.enabled}
                      onChange={handleDurationCheckboxChange("perYear")}
                      style={{ position: "relative", top: "5px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <TextField
                      value={limits.limitTotalBookingDuration.perYear.value}
                      onChange={handleDurationValueChange("perYear")}
                      variant="outlined"
                      fullWidth
                      type="number"
                      disabled={
                        !limits.limitTotalBookingDuration.perYear.enabled
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button disabled>Minutes</Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Select disabled value="perYear">
                        <MenuItem value="perYear">Per Year</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}
          </Box>
          <Divider />

          {/* Limit future bookings */}
          <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
            <Grid item md={12}>
              <ListItem alignItems="flex-start" disablePadding>
                <Grid container>
                  <Grid md={6}>
                    <Typography variant="subtitle1">
                      Limit future bookings
                      <Typography color="textSecondary" variant="body2">
                        Limit how far in the future this event can be booked
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid md={6}>
                    <Switch
                      checked={limits.limitFutureBookings.enabled}
                      onChange={handleLimitFutureBookingsChange}
                      inputProps={{ "aria-label": "controlled" }}
                      style={{ float: "right" }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </Grid>
            {limits.limitFutureBookings.enabled && (
              <Grid container spacing={6}>
                <Grid item md={6} style={{ marginTop: "15px" }}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={limits.limitFutureBookings.limitByDays.toString()}
                      onChange={handleLimitByDaysChange}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Limit By Days"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Limit By Date Range"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  {limits.limitFutureBookings.limitByDays ? (
                    <Grid container spacing={6}>
                      <Grid item md={3}>
                        <TextField
                          label="Number of days"
                          value={limits.limitFutureBookings.days.value}
                          onChange={handleDaysValueChange}
                          variant="outlined"
                          fullWidth
                          type="number"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <FormControl fullWidth>
                          <InputLabel>Unit</InputLabel>
                          <Select
                            value={limits.limitFutureBookings.days.unit}
                            onChange={handleDaysUnitChange}
                            label="Unit"
                          >
                            <MenuItem value="Business Days">
                              Business Days
                            </MenuItem>
                            <MenuItem value="Calendar Days">
                              Calendar Days
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <Typography
                          style={{ position: "relative", top: "15px" }}
                        >
                          into the future
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={6}>
                      <Grid item md={3}>
                        <DatePicker
                          label="From Date"
                          value={
                            limits.limitFutureBookings.dateRange.fromDate
                              ? dayjs(
                                  limits.limitFutureBookings.dateRange.fromDate
                                )
                              : null
                          }
                          onChange={handleFromDateChange}
                          slotProps={{ textField: { fullWidth: true } }}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <DatePicker
                          label="To Date"
                          value={
                            limits.limitFutureBookings.dateRange.toDate
                              ? dayjs(
                                  limits.limitFutureBookings.dateRange.toDate
                                )
                              : null
                          }
                          onChange={handleToDateChange}
                          slotProps={{ textField: { fullWidth: true } }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
          <Divider />

          {/* Offset start times */}
          <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
            <Grid item md={12}>
              <ListItem alignItems="flex-start" disablePadding>
                <Grid container>
                  <Grid md={6}>
                    <Typography variant="subtitle1">
                      Offset start times
                      <Typography color="textSecondary" variant="body2">
                        Offset timeslots shown to bookers by a specified number
                        of minutes
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid md={6}>
                    <Switch
                      checked={limits.offsetStartTimes.enabled}
                      onChange={handleOffsetStartTimesChange}
                      inputProps={{ "aria-label": "controlled" }}
                      style={{ float: "right" }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </Grid>
            {limits.offsetStartTimes.enabled && (
              <Grid container spacing={6}>
                <Grid
                  item
                  md={6}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <TextField
                    label="Offset By"
                    value={limits.offsetStartTimes.value}
                    onChange={handleOffsetValueChange}
                    variant="outlined"
                    fullWidth
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button disabled>Minutes</Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Typography variant="caption" color="textSecondary">
                    e.g. this will show time slots to your bookers at different
                    times than they actually are
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>

          {/* Save Button */}
          <Box mt={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={!hasChanged || updateLoading}
            >
              {updateLoading ? <CircularProgress size={24} /> : "Save"}
            </Button>
          </Box>
        </Grid>

        {/* Alert for success/error messages */}
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={alert.severity}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Grid>
    </LocalizationProvider>
  );
}

function Limits() {
  return (
    <React.Fragment>
      <EventEntryTab />
    </React.Fragment>
  );
}

export default Limits;
