import React from "react";
import styled from "@emotion/styled";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";
import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { useSavedPermissions } from "../../utils/permissionUtils";
import { PERMISSIONS } from "../../utils/permissionConstants";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const { signOut, user } = useAuth();
  const savedPermissions = useSavedPermissions();
  const isPremium = user?.subscriptionLevel === "1";

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const handleSettings = async () => {
    closeMenu();

    // 1. Check for business settings permission first
    const canAccessBusinessSettings =
      isPremium ||
      savedPermissions.includes(PERMISSIONS.SETTINGS.name) ||
      savedPermissions.some((perm) =>
        ["ACTIVITY", "SUBSCRIPTION", "USAGE", "BILLING"].includes(perm)
      );
    if (canAccessBusinessSettings) {
      navigate("/settings/business-settings");
      return;
    }

    // 2. Check profile permission
    if (isPremium || savedPermissions.includes("PROFILE")) {
      navigate("/settings/profile");
      return;
    }

    // 3. Check change password permission
    if (isPremium || savedPermissions.includes("CHANGE_PASSWORD")) {
      navigate("/settings/change-password");
      return;
    }

    // 4. Check integrations permission
    if (isPremium || savedPermissions.includes("INTEGRATIONS")) {
      navigate("/settings/integrations");
      return;
    }

    // 5. Check for user management permission
    if (
      isPremium ||
      savedPermissions.includes(PERMISSIONS.USERS.name) ||
      savedPermissions.some((perm) =>
        ["VIEW_USERS", "ADD_USERS", "UPDATE_USERS", "DELETE_USERS"].includes(
          perm
        )
      )
    ) {
      navigate("/settings/users");
      return;
    }

    // 6. Check for groups management permission
    if (
      isPremium ||
      savedPermissions.includes(PERMISSIONS.GROUPS.name) ||
      savedPermissions.some((perm) =>
        [
          "VIEW_GROUPS",
          "ADD_GROUPS",
          "UPDATE_GROUPS",
          "DELETE_GROUPS",
        ].includes(perm)
      )
    ) {
      navigate("/settings/groups");
      return;
    }

    // 7. Check for roles & permissions management
    if (
      isPremium ||
      savedPermissions.includes(PERMISSIONS.ROLES.name) ||
      savedPermissions.some((perm) =>
        [
          "VIEW_ROLES_PERMISSIONS",
          "ADD_ROLES_PERMISSIONS",
          "UPDATE_ROLES_PERMISSIONS",
          "DELETE_ROLES_PERMISSIONS",
        ].includes(perm)
      )
    ) {
      navigate("/settings/roles-permissions");
      return;
    }

    // 8. Check for spaces management
    if (
      isPremium ||
      savedPermissions.includes(PERMISSIONS.SPACES.name) ||
      savedPermissions.some((perm) =>
        [
          "VIEW_SPACE_MANAGEMENT",
          "ADD_SPACE_MANAGEMENT",
          "UPDATE_SPACE_MANAGEMENT",
          "DELETE_SPACE_MANAGEMENT",
        ].includes(perm)
      )
    ) {
      navigate("/settings/space-management");
      return;
    }

    // Fallback to settings page if no specific permission is found
    // This assumes there's a base settings page that's accessible to all authenticated users
    navigate("/settings");
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleSettings}>Settings</MenuItem>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
