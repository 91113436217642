import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Button as MuiButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import { green, red } from "@mui/material/colors";

import Actions from "./Actions";
// import BarChart from "./BarChart";
// import LineChart from "./LineChart";
// import DoughnutChart from "./DoughnutChart";
import SpaceAppCards from "./SpaceAppCards";
// import Table from "./Table";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

function SpaceApps() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title="Workspaces" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Workspaces
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/spaces/space-apps">
              Spaces
            </Link>
            <Typography>Space A</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl>
          <SpaceAppCards
            title="Active"
            amount="Widget"
            chip="Active"
            percentagetext="+26%"
            percentagecolor={green[500]}
            to="/widget/dashboard"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl>
          <SpaceAppCards
            title="Calendar"
            amount="Calendar"
            chip="Active"
            percentagetext="-14%"
            percentagecolor={red[500]}
            to="/calendar/dashboard"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl>
          <SpaceAppCards
            title="Events"
            amount="Events"
            chip="Active"
            percentagetext="+18%"
            percentagecolor={green[500]}
            to="/events"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SpaceApps;
