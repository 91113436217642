import React from "react";
import { Box, TextField, InputAdornment } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { alpha } from "@mui/material/styles";

/**
 * A reusable search bar component that can be used throughout the application
 *
 * @param {Object} props - Component props
 * @param {string} props.value - Current search query value
 * @param {function} props.onChange - Handler function for when search query changes
 * @param {string} [props.placeholder="Search..."] - Placeholder text for the search field
 * @param {Object} [props.containerSx={}] - Additional sx props for the container Box
 * @param {Object} [props.inputSx={}] - Additional sx props for the TextField
 * @param {boolean} [props.fullWidth=true] - Whether the search bar should take up full width
 * @param {string} [props.size="small"] - Size of the TextField (small, medium, etc.)
 * @param {Object} [props.InputProps={}] - Additional props for the Input component
 * @param {string} [props.variant="outlined"] - Variant of the TextField
 * @returns {JSX.Element} The SearchBar component
 */
const SearchBar = ({
  value,
  onChange,
  placeholder = "Search...",
  containerSx = {},
  inputSx = {},
  fullWidth = true,
  size = "small",
  InputProps = {},
  variant = "outlined",
  ...otherProps
}) => {
  // Handle change events
  const handleSearchChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  // Default styling for the search field
  const defaultInputSx = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
      transition: "background-color 0.2s",
      border: "none",
      "&:hover": {
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
      },
      "&.Mui-focused": {
        backgroundColor: "#fff",
        boxShadow: "0 0 0 2px rgba(25, 118, 210, 0.2)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important",
    },
  };

  // Default styling for the container
  const defaultContainerSx = {
    py: 2,
    px: 3,
  };

  // Combine default props with custom props
  const combinedInputSx = { ...defaultInputSx, ...inputSx };
  const combinedContainerSx = { ...defaultContainerSx, ...containerSx };

  // Default input props with search icon
  const defaultInputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon color="action" />
      </InputAdornment>
    ),
  };

  // Combine default InputProps with custom InputProps
  const combinedInputProps = {
    ...defaultInputProps,
    ...InputProps,
  };

  return (
    <Box sx={combinedContainerSx}>
      <TextField
        variant={variant}
        size={size}
        placeholder={placeholder}
        value={value}
        onChange={handleSearchChange}
        sx={combinedInputSx}
        InputProps={combinedInputProps}
        {...otherProps}
      />
    </Box>
  );
};

export default SearchBar;
