// src/contexts/JWTContext.js
import { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";

import { axiosInstance } from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";

import { useNavigate, useLocation } from "react-router-dom";

console.log("Log hh jwt");

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  rbacPermissions: [],
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        rbacPermissions: action.payload.permissions,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        rbacPermissions: action.payload.permissions,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        rbacPermissions: [],
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        rbacPermissions: [],
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function getUniquePermissions(data) {
  const permissions = new Set();

  if (!data.hasOwnProperty("getUser")) {
    // Collect permissions from roles
    data.roles.forEach((role) => {
      role.permissions.forEach((permission) => {
        permissions.add(permission);
      });
    });
  } else {
    // Collect permissions from roles
    data.getUser.roles.forEach((role) => {
      role.permissions.forEach((permission) => {
        permissions.add(permission);
      });
    });
  }

  if (!data.hasOwnProperty("getUser")) {
    // Collect permissions from group roles
    data.groups.forEach((group) => {
      group.roles.forEach((role) => {
        role.permissions.forEach((permission) => {
          permissions.add(permission);
        });
      });
    });
  } else {
    data.getUser.groups.forEach((group) => {
      group.roles.forEach((role) => {
        role.permissions.forEach((permission) => {
          permissions.add(permission);
        });
      });
    });
  }

  // Convert Set to Array
  return Array.from(permissions);
}

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          const decoded = jwtDecode(accessToken);
          setSession(accessToken);

          // Replace this with an API call that checks if the token is still valid
          let query = "";
          if (decoded.clientId) {
            query = `
              query GetClient($id: ID!) {
              getClient(id: $id) {
                id
                name
                companyName
                email
                subscriptionLevel
                avatar
              }
            }
            `;
            const response = await axiosInstance.post("/graphql", {
              query,
              variables: {
                id: decoded.clientId,
              },
            });

            // Handle errors returned from the backend
            if (response.errors || !response.data.getClient) {
              throw new Error("Invalid token");
            }

            const user = { ...response.data.getClient };

            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: true,
                user,
              },
            });
          }
          if (decoded.userId) {
            query = `
          query GetUser($id: ID!) {
                    getUser(id: $id) {
                      name
                      email
                      avatar
                      roles {
                        name
                        permissions
                      }
                      groups {
                        roles {
                          permissions
                        }
                      }
                    }
                  }
            `;
            const response = await axiosInstance.post("/graphql", {
              query,
              variables: {
                id: decoded.userId,
              },
            });

            // Handle errors returned from the backend
            if (response.errors || !response.data.getUser) {
              throw new Error("Invalid token");
            }

            const user = { ...response.data.getUser };
            const uniquePermissions = getUniquePermissions(response.data);

            dispatch({
              type: INITIALIZE,
              payload: {
                rbacPermissions: ["ADD_USER"],
                isAuthenticated: true,
                user,
              },
            });
          }
          if (
            location.pathname === "/auth/sign-in" ||
            location.pathname === "/auth/sign-up"
          ) {
            navigate("/private");
          }
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        const accessToken = window.localStorage.getItem("accessToken");
        console.error(err);
        setSession(null); // Clear session if an error occurs
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, [navigate]);

  const signIn = async (email, password) => {
    try {
      // Execute the GraphQL mutation for login
      const response = await axiosInstance.post("/graphql", {
        query: `
        mutation Login($input: LoginInput!) {
          login(input: $input) {
            __typename
            ... on AuthPayload {
              token
              client {
                id
                name
                companyName
                email
                subscriptionLevel
              }
            }
            ... on UserAuthPayload {
              token
              user {
                id
                name
                email
                passwordResetRequired
              }
            }
            ... on AuthError {
              message
            }
          }
        }
      `,
        variables: {
          input: {
            email,
            password,
          },
        },
      });

      // Check if the response contains errors
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      // Destructure login from response data
      const { login } = response.data;

      // Handle the response based on the returned type
      if (!login) {
        throw new Error("Login failed: No data returned from the server.");
      }

      if (login.__typename === "AuthError") {
        throw new Error(login.message);
      }

      if (login.__typename === "AuthPayload") {
        const { token, client } = login;

        // Set the JWT token and dispatch SIGN_IN
        setSession(token);
        dispatch({
          type: SIGN_IN,
          payload: {
            user: client,
          },
        });
      }

      console.log("Login response:", login);

      if (login.__typename === "UserAuthPayload") {
        const { token, user } = login;

        console.log("User from login payload:", user);
        console.log("Password reset required?", user.passwordResetRequired);

        // Check if password reset is required directly from the login response
        if (user.passwordResetRequired) {
          console.log("Redirecting to change password page");
          console.log(
            "Password reset required. Redirecting to change password page."
          );

          // Store the temporary token and email for the password reset page
          localStorage.setItem("temporaryToken", token);
          localStorage.setItem("resetEmail", email); // Store the email from the login form
          localStorage.setItem("resetRequired", "true");

          // Redirect to password change page
          navigate("/auth/change-password", {
            state: {
              email: email, // Pass the email from the login form
              passwordResetRequired: true,
            },
          });

          // Return early without setting the session token
          return;
        }

        // If no password reset required, proceed with fetching permissions
        const permissionsQuery = `
        query GetUser($id: ID!) {
          getUser(id: $id) {
            name
            email
            avatar
            passwordResetRequired
            lastLogin
            roles {
              name
              permissions
            }
            groups {
              roles {
                permissions
              }
            }
          }
        }
      `;

        const userResponse = await axiosInstance.post("/graphql", {
          query: permissionsQuery,
          variables: {
            id: user.id,
          },
        });

        // Handle possible errors in the user query response
        if (userResponse.errors) {
          throw new Error(userResponse.errors[0].message);
        }

        // Extract the user and calculate unique permissions
        const userData = userResponse.data.getUser;

        // Double-check passwordResetRequired from the detailed user data
        if (userData.passwordResetRequired) {
          console.log("Password reset required. Using direct navigation.");
          // Store the temporary token and email for the password reset page
          localStorage.setItem("temporaryToken", token);
          localStorage.setItem("resetEmail", email); // Store the email from the login form
          localStorage.setItem("resetRequired", "true");

          // Force direct navigation instead of using React Router
          window.location.href = "/auth/change-password";
          // Return early without setting the session token
          return;
        }

        const uniquePermissions = getUniquePermissions(userData);

        // Store RBAC permissions locally
        localStorage.setItem(
          "rbacPermissions",
          JSON.stringify(uniquePermissions)
        );

        // Update last login time
        try {
          await axiosInstance.post("/graphql", {
            query: `
            mutation UpdateUserLastLogin($id: ID!) {
              updateUserLastLogin(id: $id) {
                success
              }
            }
          `,
            variables: {
              id: user.id,
            },
          });
        } catch (err) {
          console.error("Failed to update last login time:", err);
          // Continue anyway
        }

        // Set the JWT token and dispatch SIGN_IN
        setSession(token);
        dispatch({
          type: SIGN_IN,
          payload: {
            user: userData,
            permissions: uniquePermissions,
          },
        });
      }
    } catch (error) {
      let errorMessage = "An unexpected error occurred";

      if (error.response) {
        // Server responded with a status other than 2xx
        errorMessage = error.response.data.message || errorMessage;
      } else if (error.request) {
        // Request was made but no response was received
        errorMessage = "No response from server";
      } else {
        // Something happened in setting up the request
        errorMessage = error.message || errorMessage;
      }

      // Handle the error accordingly (e.g., show an alert)
      throw new Error(errorMessage);
    }
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  // Step 1: Initiate signup and send verification email
  // Update your AuthProvider.jsx file:

  // Update the initiateSignUp function:
  const initiateSignUp = async (name, email, password, companyName) => {
    const subscriptionLevel = "1"; // Default subscription level

    try {
      const response = await axiosInstance.post("/graphql", {
        query: `
        mutation InitiateClientRegistration($input: ClientInput!) {
          initiateClientRegistration(input: $input) {
            success
            message
            registrationToken
          }
        }
      `,
        variables: {
          input: {
            name,
            email,
            password,
            companyName,
            subscriptionLevel,
          },
        },
      });

      // Check for GraphQL errors
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      const { success, message, registrationToken } =
        response.data.initiateClientRegistration;

      if (!success) {
        throw new Error(message || "Failed to initiate registration");
      }

      // Store the registration token in localStorage to use later
      localStorage.setItem("registrationToken", registrationToken);

      return { success, message };
    } catch (error) {
      let errorMessage = "An unexpected error occurred";

      if (error.response) {
        errorMessage = error.response.data.message || errorMessage;
      } else if (error.request) {
        errorMessage = "No response from server";
      } else {
        errorMessage = error.message || errorMessage;
      }

      throw new Error(errorMessage);
    }
  };

  // Update the verifyEmail function:
  const verifyEmail = async (email, otp, registrationData) => {
    try {
      const registrationToken = localStorage.getItem("registrationToken");

      if (!registrationToken) {
        throw new Error("Registration session expired. Please start over.");
      }

      const response = await axiosInstance.post("/graphql", {
        query: `
        mutation VerifyClientEmail($email: String!, $otp: String!, $registrationToken: String!) {
          verifyClientEmail(email: $email, otp: $otp, registrationToken: $registrationToken) {
            token
            client {
              id
              name
              email
              companyName
              subscriptionLevel
            }
          }
        }
      `,
        variables: {
          email,
          otp,
          registrationToken,
        },
      });

      // Check for GraphQL errors
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      const { token, client } = response.data.verifyClientEmail;

      // Clean up the registration token
      localStorage.removeItem("registrationToken");

      // Set the session and complete registration
      setSession(token);
      dispatch({
        type: SIGN_UP,
        payload: {
          user: client,
        },
      });

      return true;
    } catch (error) {
      let errorMessage = "An unexpected error occurred";

      if (error.response) {
        errorMessage = error.response.data.message || errorMessage;
      } else if (error.request) {
        errorMessage = "No response from server";
      } else {
        errorMessage = error.message || errorMessage;
      }

      throw new Error(errorMessage);
    }
  };

  // Resend verification OTP
  const resendVerificationOtp = async (email) => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: `
          mutation ResendVerificationOtp($email: String!) {
            resendVerificationOtp(email: $email)
          }
        `,
        variables: {
          email,
        },
      });

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      return response.data.resendVerificationOtp;
    } catch (error) {
      let errorMessage = "An unexpected error occurred";

      if (error.response) {
        errorMessage = error.response.data.message || errorMessage;
      } else if (error.request) {
        errorMessage = "No response from server";
      } else {
        errorMessage = error.message || errorMessage;
      }

      throw new Error(errorMessage);
    }
  };

  const impersonateUser = async (userId) => {
    try {
      // First verify the user exists
      const userResponse = await axiosInstance.post("/graphql", {
        query: `
        query GetUser($id: ID!) {
          getUser(id: $id) {
            name
            email
            avatar
            roles {
              name
              permissions
            }
            groups {
              roles {
                permissions
              }
            }
          }
        }
      `,
        variables: {
          id: userId,
        },
      });

      if (userResponse.errors || !userResponse.getUser) {
        throw new Error("User not found");
      }

      // Now perform the impersonation
      const impersonateResponse = await axiosInstance.post("/graphql", {
        query: `
        mutation ImpersonateUser($userId: ID!) {
          impersonateUser(userId: $userId) {
            token
            user {
              name
              email
              roles {
                name
                permissions
              }
              groups {
                roles {
                  permissions
                }
              }
            }
          }
        }
      `,
        variables: { userId },
      });

      if (impersonateResponse.errors) {
        throw new Error(impersonateResponse.errors[0].message);
      }

      const { token, user } = impersonateResponse.data.impersonateUser;
      setSession(token);

      const uniquePermissions = getUniquePermissions(user);

      const originalToken = localStorage.getItem("accessToken");
      const originalPermissions = localStorage.getItem("rbacPermissions");
      localStorage.setItem("adminToken", originalToken);
      localStorage.setItem("adminPermissions", originalPermissions);

      localStorage.setItem(
        "rbacPermissions",
        JSON.stringify(uniquePermissions)
      );

      dispatch({
        type: SIGN_IN,
        payload: {
          user,
          permissions: uniquePermissions,
          isImpersonating: true,
        },
      });

      return true;
    } catch (error) {
      console.error("Impersonation failed:", error);
      throw error;
    }
  };

  const resetPassword = async (email) => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: `
        mutation ResetClientPassword($input: ResetPasswordInput!) {
          resetClientPassword(input: $input)
        }
      `,
        variables: {
          input: {
            email,
          },
        },
      });

      // Check for errors
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      return response.data.resetClientPassword;
    } catch (error) {
      console.error("Password reset error:", error);
      throw new Error(error.message || "Failed to reset password");
    }
  };

  const updatePassword = async (token, newPassword) => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: `
        mutation UpdateClientPassword($input: UpdatePasswordInput!) {
          updateClientPassword(input: $input)
        }
      `,
        variables: {
          input: {
            token,
            newPassword,
          },
        },
      });

      // Check for errors
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      return response.data.updateClientPassword;
    } catch (error) {
      console.error("Update password error:", error);
      throw new Error(error.message || "Failed to update password");
    }
  };

  // Add function to handle initial password changes for new users
  const updateInitialPassword = async (email, currentPassword, newPassword) => {
    console.log("Log hhh 002: ");

    try {
      // Get temporary token and email from localStorage
      const temporaryToken = localStorage.getItem("temporaryToken");
      // If email is not provided, try to get it from localStorage
      const storedEmail = localStorage.getItem("resetEmail");
      const actualEmail = email || storedEmail;

      console.log("Log hhh 002: ", temporaryToken);
      console.log("Using email for password reset:", actualEmail);

      if (!temporaryToken) {
        throw new Error("Authentication error. Please try logging in again.");
      }

      if (!actualEmail) {
        throw new Error("Email not found. Please try logging in again.");
      }

      console.log("Log hhh 002: ", temporaryToken);

      // Include the token directly in the request headers
      const response = await axiosInstance.post(
        "/graphql",
        {
          query: `
          mutation UpdateInitialPassword($email: String!, $currentPassword: String!, $newPassword: String!) {
            updateInitialPassword(email: $email, currentPassword: $currentPassword, newPassword: $newPassword) {
              success
              message
            }
          }
        `,
          variables: {
            email: actualEmail,
            currentPassword,
            newPassword,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${temporaryToken}`,
          },
        }
      );

      // Check for GraphQL errors
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      const result = response.data.updateInitialPassword;

      if (!result.success) {
        throw new Error(result.message || "Failed to update password");
      }

      // Now sign in again with the new password
      await signIn(actualEmail, newPassword);

      // Clean up localStorage
      localStorage.removeItem("temporaryToken");
      localStorage.removeItem("resetEmail");
      localStorage.removeItem("resetRequired");

      return { success: true, message: result.message };
    } catch (error) {
      console.error("Update password error:", error);
      throw new Error(error.message || "Failed to update password");
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        initiateSignUp,
        verifyEmail,
        resendVerificationOtp,
        resetPassword,
        updatePassword,
        updateInitialPassword,
        impersonateUser,
        isImpersonating: state.isImpersonating,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
