import React, { useState, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import CalendarsComponent from "../../components/settings/Integrations/Calendars";
import ConferencesComponent from "../../components/settings/Integrations/Conferences";
import AutomationsComponent from "../../components/settings/Integrations/Automations";
import PaymentsComponent from "../../components/settings/Integrations/Payments";
import AnalyticsComponent from "../../components/settings/Integrations/Analytics";

import useAuth from "../../hooks/useAuth";
import { useSavedPermissions } from "../../utils/permissionUtils";
import { PERMISSIONS } from "../../utils/permissionConstants";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  Typography,
  Tabs,
  Tab,
  Alert,
  Link as MuiLink,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Integrations() {
  const [value, setValue] = useState(0);
  const { user } = useAuth();
  const savedPermissions = useSavedPermissions();
  const isPremium = user?.subscriptionLevel === "1";

  // Permission checks for each tab using the SETTINGS structure
  const canViewCalendars = isPremium || savedPermissions.includes("CALENDARS");
  const canViewConferences =
    isPremium || savedPermissions.includes("CONFERENCES");
  const canViewAutomation =
    isPremium || savedPermissions.includes("AUTOMATION");
  const canViewPayments = isPremium || savedPermissions.includes("PAYMENTS");
  const canViewAnalytics = isPremium || savedPermissions.includes("ANALYTICS");

  // Check if user has access to any of the integration tabs
  const hasIntegrationsAccess =
    isPremium ||
    savedPermissions.includes(PERMISSIONS.SETTINGS.name) ||
    savedPermissions.includes("INTEGRATIONS") ||
    canViewCalendars ||
    canViewConferences ||
    canViewAutomation ||
    canViewPayments ||
    canViewAnalytics;

  // Get all accessible tabs using useMemo to avoid recreating the array on every render
  const accessibleTabs = useMemo(
    () => [
      canViewCalendars,
      canViewConferences,
      canViewAutomation,
      canViewPayments,
      canViewAnalytics,
    ],
    [
      canViewCalendars,
      canViewConferences,
      canViewAutomation,
      canViewPayments,
      canViewAnalytics,
    ]
  );

  // Handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Adjust selected tab if current tab is not accessible
  useEffect(() => {
    // Get the visible tab index that corresponds to the internal value
    const visibleTabIndex = getVisibleTabIndex(value, accessibleTabs);

    if (!accessibleTabs[visibleTabIndex]) {
      // Find first accessible tab
      const firstAccessibleTabIndex = accessibleTabs.findIndex(
        (access) => access
      );
      if (firstAccessibleTabIndex !== -1) {
        setValue(firstAccessibleTabIndex);
      }
    }
  }, [value, accessibleTabs]);

  if (!hasIntegrationsAccess) {
    return (
      <React.Fragment>
        <Helmet title="Integrations" />
        <Typography variant="h3" gutterBottom>
          Integrations
        </Typography>
        <Alert severity="warning">
          You don't have permission to view Integration settings.
        </Alert>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Helmet title="Integrations" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Integrations
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <NavLink to="/private">Spaces</NavLink>
            <MuiLink component="span" color="text.secondary">
              Settings
            </MuiLink>
            <Typography>Integrations</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              component={NavLink}
              to="/private"
              sx={{ mx: "0.5rem" }}
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="integrations tabs"
          >
            {canViewCalendars && <Tab label="Calendars" {...a11yProps(0)} />}
            {canViewConferences && (
              <Tab label="Conferences" {...a11yProps(1)} />
            )}
            {canViewAutomation && <Tab label="Automations" {...a11yProps(2)} />}
            {canViewPayments && <Tab label="Payments" {...a11yProps(3)} />}
            {canViewAnalytics && <Tab label="Analytics" {...a11yProps(4)} />}
          </Tabs>
        </Box>

        {canViewCalendars && (
          <CustomTabPanel value={value} index={getTabIndex(0, true)}>
            <CalendarsComponent />
          </CustomTabPanel>
        )}

        {canViewConferences && (
          <CustomTabPanel
            value={value}
            index={getTabIndex(1, canViewCalendars)}
          >
            <ConferencesComponent />
          </CustomTabPanel>
        )}

        {canViewAutomation && (
          <CustomTabPanel
            value={value}
            index={getTabIndex(2, canViewCalendars, canViewConferences)}
          >
            <AutomationsComponent />
          </CustomTabPanel>
        )}

        {canViewPayments && (
          <CustomTabPanel
            value={value}
            index={getTabIndex(
              3,
              canViewCalendars,
              canViewConferences,
              canViewAutomation
            )}
          >
            <PaymentsComponent />
          </CustomTabPanel>
        )}

        {canViewAnalytics && (
          <CustomTabPanel
            value={value}
            index={getTabIndex(
              4,
              canViewCalendars,
              canViewConferences,
              canViewAutomation,
              canViewPayments
            )}
          >
            <AnalyticsComponent />
          </CustomTabPanel>
        )}
      </Box>
    </React.Fragment>
  );
}

// Helper function to calculate the correct tab index based on which tabs are visible
function getTabIndex(baseIndex, ...precedingTabsVisible) {
  // Count how many preceding tabs are actually visible
  const visiblePrecedingTabs = precedingTabsVisible.filter(Boolean).length;
  // Calculate the actual index based on visible tabs
  return visiblePrecedingTabs > 0
    ? baseIndex
    : baseIndex - (precedingTabsVisible.length - visiblePrecedingTabs);
}

// Helper function to get the visible tab index from the internal value
function getVisibleTabIndex(value, accessibleTabs) {
  let visibleIndex = 0;
  let internalIndex = 0;

  while (internalIndex < accessibleTabs.length) {
    if (accessibleTabs[internalIndex]) {
      if (visibleIndex === value) {
        return internalIndex;
      }
      visibleIndex++;
    }
    internalIndex++;
  }

  return -1;
}

export default Integrations;
