import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import "../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormGroup from "@mui/material/FormGroup";
import { MessageCircle } from "react-feather";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { faker } from "@faker-js/faker";
import Drawer from "@mui/material/Drawer";
import ListItemText from "@mui/material/ListItemText";
import FormLabel from "@mui/material/FormLabel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { v4 as uuidv4 } from "uuid";
import { axiosInstance } from "../../../utils/axios";

import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  FormControlLabel,
} from "@mui/material";
import { green, orange, blue } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";

// GraphQL queries and mutations
const GET_EVENT_TYPE_QUERY = `
  query GetEventType($id: ID!) {
    getEventType(id: $id) {
      id
      title
      advanced {
        eventNameInCalendar
        layout {
          month
          weekly
          column
          defaultView
        }
        bookingQuestions {
          id
          title
          type
          identifier
          label
          placeholder
          options
          required
        }
        requiresConfirmation {
          enabled
          type
          noticeTime {
            value
            unit
          }
        }
        requiresEmailVerification
        hideNotesInCalendar
        redirectOnBooking {
          enabled
          url
        }
        enablePrivateUrl {
          enabled
          url
        }
        offerSeats {
          enabled
          seats
          shareAttendeeInfo
          showAvailableSeats
        }
        lockTimezone
      }
    }
  }
`;

const UPDATE_EVENT_TYPE_ADVANCED_MUTATION = `
  mutation UpdateEventTypeAdvanced($input: UpdateEventTypeAdvancedInput!) {
    updateEventTypeAdvanced(input: $input) {
      eventType {
        id
        advanced {
          eventNameInCalendar
          layout {
            month
            weekly
            column
            defaultView
          }
          bookingQuestions {
            id
            title
            type
            identifier
            label
            placeholder
            options
            required
          }
          requiresConfirmation {
            enabled
            type
            noticeTime {
              value
              unit
            }
          }
          requiresEmailVerification
          hideNotesInCalendar
          redirectOnBooking {
            enabled
            url
          }
          enablePrivateUrl {
            enabled
            url
          }
          offerSeats {
            enabled
            seats
            shareAttendeeInfo
            showAvailableSeats
          }
          lockTimezone
        }
      }
    }
  }
`;

// Styled components
const AvatarGroup = styled(MuiAvatarGroup)`
  display: inline-flex;
`;

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  background: "#eee";
`;

const CustomTableRow = styled(TableRow)`
  // &:nth-of-type(odd) {
  //   background-color: rgba(0, 0, 0, 0.025);
  // }
`;

const TaskWrapper = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  cursor: grab;

  &:hover {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin-right: ${(props) => props.theme.spacing(10)};
`;

function TableRows({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    var opt = "Option " + (index + 1);
    const { name } = rowsData;
    return (
      <tr key={index}>
        <td>
          <TextField
            id="username"
            label={opt}
            value={name}
            variant="outlined"
            fullWidth
            my={2}
            rows={4}
            maxRows={6}
            name="name"
            onChange={(event) => onValUpdate(index, event)}
          />
        </td>
        <td>
          <DeleteIcon
            onClick={() => tableRowRemove(index)}
            style={{ cursor: "pointer" }}
          />
        </td>
      </tr>
    );
  });
}

function Table1({ options = [], onChange }) {
  const [rows, initRow] = useState(
    options.map((option) => ({ name: option })) || []
  );

  useEffect(() => {
    if (onChange) {
      onChange(rows.map((row) => row.name));
    }
  }, [rows, onChange]);

  const addRowTable = () => {
    const data = {
      name: "",
    };
    initRow([...rows, data]);
  };

  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };

  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  return (
    <>
      <Typography variant="body2" gutterBottom>
        Options
        <button
          onClick={addRowTable}
          style={{
            float: "right",
            border: "0",
            backgroundColor: "transparent",
            fontWeight: "bold",
            color: "#376fd1",
            cursor: "pointer",
          }}
          color="primary"
        >
          Add
          <AddIcon style={{ position: "relative", top: "7px" }} />
        </button>
      </Typography>
      <table>
        <tbody>
          <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
          />
        </tbody>
      </table>
    </>
  );
}

const Lane = ({ column, children, onAddQuestion }) => {
  const [state, setState] = React.useState({
    right: false,
  });
  const [newQuestion, setNewQuestion] = React.useState({
    type: "ShortText",
    identifier: "",
    label: "",
    placeholder: "",
    options: [],
    required: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleQuestionChange = (field, value) => {
    setNewQuestion({
      ...newQuestion,
      [field]: value,
    });
  };

  const handleOptionChange = (options) => {
    setNewQuestion({
      ...newQuestion,
      options,
    });
  };

  const handleAddQuestion = () => {
    if (onAddQuestion) {
      onAddQuestion({
        id: uuidv4(),
        title: newQuestion.label,
        type: newQuestion.type,
        identifier: newQuestion.identifier,
        label: newQuestion.label,
        placeholder: newQuestion.placeholder,
        options: newQuestion.options,
        required: newQuestion.required,
      });
    }

    // Reset form and close drawer
    setNewQuestion({
      type: "ShortText",
      identifier: "",
      label: "",
      placeholder: "",
      options: [],
      required: false,
    });
    setState({ ...state, right: false });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "350px" }}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Add Question
          </Typography>
          <Typography>
            Customize the questions asked on the booking page
          </Typography>
        </Grid>
      </Grid>
      <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
        <Grid container spacing={6}>
          <Grid item md={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="question-type">Input Type</InputLabel>
              <Select
                autoFocus
                value={newQuestion.type}
                label="Input Type"
                inputProps={{
                  name: "question-type",
                  id: "question-type",
                }}
                onChange={(e) => handleQuestionChange("type", e.target.value)}
              >
                <MenuItem value="Email">Email</MenuItem>
                <MenuItem value="Phone">Phone</MenuItem>
                <MenuItem value="Address">Address</MenuItem>
                <MenuItem value="ShortText">Short Text</MenuItem>
                <MenuItem value="Number">Number</MenuItem>
                <MenuItem value="LongText">Long Text</MenuItem>
                <MenuItem value="Select">Select</MenuItem>
                <MenuItem value="MultiSelect">Multi Select</MenuItem>
                <MenuItem value="MultipleEmails">Multiple Emails</MenuItem>
                <MenuItem value="CheckboxGroup">Checkbox Group</MenuItem>
                <MenuItem value="RadioGroup">Radio Group</MenuItem>
                <MenuItem value="Checkbox">Checkbox</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <TextField
              label="Identifier"
              value={newQuestion.identifier}
              variant="outlined"
              fullWidth
              my={2}
              onChange={(e) =>
                handleQuestionChange("identifier", e.target.value)
              }
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              label="Label"
              value={newQuestion.label}
              variant="outlined"
              fullWidth
              my={2}
              onChange={(e) => handleQuestionChange("label", e.target.value)}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              label="Placeholder"
              value={newQuestion.placeholder}
              variant="outlined"
              fullWidth
              my={2}
              onChange={(e) =>
                handleQuestionChange("placeholder", e.target.value)
              }
            />
          </Grid>
          <Grid item md={12}>
            {["Select", "MultiSelect", "CheckboxGroup", "RadioGroup"].includes(
              newQuestion.type
            ) && (
              <Table1
                options={newQuestion.options}
                onChange={handleOptionChange}
              />
            )}
          </Grid>
          <Grid item md={12}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Required
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={newQuestion.required.toString()}
                onChange={(e) =>
                  handleQuestionChange("required", e.target.value === "true")
                }
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddQuestion} color="primary">
          Done
        </Button>
      </DialogActions>
    </Box>
  );

  return (
    <Grid item xs={12} lg={12} xl={12}>
      <Card
        mb={6}
        style={{ backgroundColor: "transparent", padding: "0px !important" }}
      >
        <CardContent pb={0} style={{ paddingLeft: "0px" }}>
          <Typography variant="h6" gutterBottom>
            {column.title}
          </Typography>
          <Typography variant="body2" mb={4}>
            {column.description}
          </Typography>
          {children}
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={toggleDrawer("right", true)}
          >
            <AddIcon />
            Add new question
          </Button>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            {list("right")}
          </Drawer>
        </CardContent>
      </Card>
    </Grid>
  );
};

const Task = ({ item, onEditQuestion, onUpdateQuestion }) => {
  const [state, setState] = React.useState({
    right: false,
  });
  const [editedQuestion, setEditedQuestion] = React.useState(null);

  useEffect(() => {
    if (state.right && item) {
      setEditedQuestion({
        id: item.id,
        type: item.type || "ShortText",
        identifier: item.identifier || "",
        label: item.label || "",
        placeholder: item.placeholder || "",
        options: item.options || [],
        required: item.required || false,
        title: item.title || "",
      });
    }
  }, [state.right, item]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleQuestionChange = (field, value) => {
    setEditedQuestion({
      ...editedQuestion,
      [field]: value,
    });
  };

  const handleOptionChange = (options) => {
    setEditedQuestion({
      ...editedQuestion,
      options,
    });
  };

  const handleUpdateQuestion = () => {
    if (onUpdateQuestion && editedQuestion) {
      const updatedQuestion = {
        ...editedQuestion,
        title: editedQuestion.label, // Sync title with label
      };
      onUpdateQuestion(updatedQuestion);
    }

    // Close drawer
    setState({ ...state, right: false });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "350px" }}
    >
      {editedQuestion && (
        <>
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
              <Typography variant="h3" gutterBottom display="inline">
                Edit Question
              </Typography>
              <Typography>
                Customize the questions asked on the booking page
              </Typography>
            </Grid>
          </Grid>
          <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
            <Grid container spacing={6}>
              <Grid item md={12}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel htmlFor="question-type">Input Type</InputLabel>
                  <Select
                    autoFocus
                    value={editedQuestion.type}
                    label="Input Type"
                    inputProps={{
                      name: "question-type",
                      id: "question-type",
                    }}
                    onChange={(e) =>
                      handleQuestionChange("type", e.target.value)
                    }
                  >
                    <MenuItem value="Email">Email</MenuItem>
                    <MenuItem value="Phone">Phone</MenuItem>
                    <MenuItem value="Address">Address</MenuItem>
                    <MenuItem value="ShortText">Short Text</MenuItem>
                    <MenuItem value="Number">Number</MenuItem>
                    <MenuItem value="LongText">Long Text</MenuItem>
                    <MenuItem value="Select">Select</MenuItem>
                    <MenuItem value="MultiSelect">Multi Select</MenuItem>
                    <MenuItem value="MultipleEmails">Multiple Emails</MenuItem>
                    <MenuItem value="CheckboxGroup">Checkbox Group</MenuItem>
                    <MenuItem value="RadioGroup">Radio Group</MenuItem>
                    <MenuItem value="Checkbox">Checkbox</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <TextField
                  label="Identifier"
                  value={editedQuestion.identifier}
                  variant="outlined"
                  fullWidth
                  my={2}
                  onChange={(e) =>
                    handleQuestionChange("identifier", e.target.value)
                  }
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  label="Label"
                  value={editedQuestion.label}
                  variant="outlined"
                  fullWidth
                  my={2}
                  onChange={(e) =>
                    handleQuestionChange("label", e.target.value)
                  }
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  label="Placeholder"
                  value={editedQuestion.placeholder}
                  variant="outlined"
                  fullWidth
                  my={2}
                  onChange={(e) =>
                    handleQuestionChange("placeholder", e.target.value)
                  }
                />
              </Grid>
              <Grid item md={12}>
                {[
                  "Select",
                  "MultiSelect",
                  "CheckboxGroup",
                  "RadioGroup",
                ].includes(editedQuestion.type) && (
                  <Table1
                    options={editedQuestion.options}
                    onChange={handleOptionChange}
                  />
                )}
              </Grid>
              <Grid item md={12}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Required
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={editedQuestion.required.toString()}
                    onChange={(e) =>
                      handleQuestionChange(
                        "required",
                        e.target.value === "true"
                      )
                    }
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={toggleDrawer("right", false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdateQuestion} color="primary">
              Done
            </Button>
          </DialogActions>
        </>
      )}
    </Box>
  );

  // Get readable type name from the type code
  const getReadableTypeName = (type) => {
    const typeMap = {
      Email: "Email",
      Phone: "Phone",
      Address: "Address",
      ShortText: "Short Text",
      Number: "Number",
      LongText: "Long Text",
      Select: "Select",
      MultiSelect: "Multi Select",
      MultipleEmails: "Multiple Emails",
      CheckboxGroup: "Checkbox Group",
      RadioGroup: "Radio Group",
      Checkbox: "Checkbox",
    };
    return typeMap[type] || type;
  };

  return (
    <TaskWrapper mb={4}>
      <TaskWrapperContent>
        <TaskTitle variant="body1" gutterBottom>
          {item.label || item.title}
          {item.required && (
            <Chip label="Required" style={{ marginLeft: "5px" }} />
          )}
        </TaskTitle>
        <h4 style={{ margin: "0", color: "#908e8e" }}>
          {getReadableTypeName(item.type)}
        </h4>

        <TaskNotifications style={{ top: "16px" }}>
          <TaskNotificationsAmount
            style={{ position: "relative", bottom: "8px" }}
          >
            <Switch
              checked={item.required}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                if (onUpdateQuestion) {
                  onUpdateQuestion({
                    ...item,
                    required: e.target.checked,
                  });
                }
              }}
            />
          </TaskNotificationsAmount>
          <EditIcon
            style={{ cursor: "pointer" }}
            onClick={toggleDrawer("right", true)}
          />
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            {list("right")}
          </Drawer>
        </TaskNotifications>
      </TaskWrapperContent>
    </TaskWrapper>
  );
};

const TaskNotifications = styled.div`
  display: flex;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(4)};
  right: ${(props) => props.theme.spacing(4)};
`;

const TaskNotificationsAmount = styled.div`
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 600;
  margin-right: ${(props) => props.theme.spacing(1)};
  line-height: 1.75;
`;

function Advanced({ eventTypeId }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [advancedSettings, setAdvancedSettings] = useState({
    eventNameInCalendar: "",
    layout: {
      month: true,
      weekly: true,
      column: true,
      defaultView: "Month",
    },
    bookingQuestions: [],
    requiresConfirmation: {
      enabled: false,
      type: "Always",
      noticeTime: {
        value: 30,
        unit: "minutes",
      },
    },
    requiresEmailVerification: false,
    hideNotesInCalendar: false,
    redirectOnBooking: {
      enabled: false,
      url: "",
    },
    enablePrivateUrl: {
      enabled: false,
      url: "",
    },
    offerSeats: {
      enabled: false,
      seats: 2,
      shareAttendeeInfo: false,
      showAvailableSeats: false,
    },
    lockTimezone: false,
  });

  const [columns, setColumns] = useState({});
  const [documentReady, setDocumentReady] = useState(false);
  const [saving, setSaving] = useState(false);

  // Tab control for layout selection
  const [tabValue, setTabValue] = useState(0);

  // Get event type data on component mount
  useEffect(() => {
    const fetchEventType = async () => {
      console.log("Fetching event type with ID:", eventTypeId);
      try {
        setLoading(true);
        setDocumentReady(false); // Ensure we reset this when fetching

        // Use axiosInstance to fetch data
        const response = await axiosInstance.post("/graphql", {
          query: GET_EVENT_TYPE_QUERY,
          variables: { id: eventTypeId },
        });

        // Log the response to debug
        console.log("API Response:", response);

        // Check if response.data is an object with data property (nested structure)
        if (response.data && typeof response.data === "object") {
          let eventTypeData;

          // Handle two possible response structures
          if (response.data.data && response.data.data.getEventType) {
            // Case 1: When response is { data: { data: { getEventType: {...} } } }
            eventTypeData = response.data.data.getEventType;
            console.log(
              "Found event type data in nested structure:",
              eventTypeData
            );
          } else if (response.data.getEventType) {
            // Case 2: When response is { data: { getEventType: {...} } }
            eventTypeData = response.data.getEventType;
            console.log(
              "Found event type data in direct structure:",
              eventTypeData
            );
          }

          if (eventTypeData) {
            // Create a default structure for advanced settings if it doesn't exist
            const advanced = eventTypeData.advanced || {
              eventNameInCalendar: "",
              layout: {
                month: true,
                weekly: true,
                column: true,
                defaultView: "Month",
              },
              bookingQuestions: [],
              requiresConfirmation: {
                enabled: false,
                type: "Always",
                noticeTime: {
                  value: 30,
                  unit: "minutes",
                },
              },
              requiresEmailVerification: false,
              hideNotesInCalendar: false,
              redirectOnBooking: {
                enabled: false,
                url: "",
              },
              enablePrivateUrl: {
                enabled: false,
                url: "",
              },
              offerSeats: {
                enabled: false,
                seats: 2,
                shareAttendeeInfo: false,
                showAvailableSeats: false,
              },
              lockTimezone: false,
            };

            // Ensure booking questions are properly initialized
            const bookingQuestions = advanced.bookingQuestions || [];

            // Make sure each question has an ID and title
            const questionItems = bookingQuestions.map((q) => ({
              id: q.id || uuidv4(),
              title: q.title || q.label || "Untitled Question",
              type: q.type || "ShortText",
              identifier: q.identifier || "",
              label: q.label || q.title || "Untitled Question",
              placeholder: q.placeholder || "",
              options: q.options || [],
              required: q.required || false,
            }));

            // Set advanced settings with processed question data
            setAdvancedSettings({
              ...advanced,
              bookingQuestions: questionItems,
            });

            // Set tab value based on default view
            if (advanced?.layout?.defaultView) {
              const viewToTabIndex = {
                Month: 0,
                Weekly: 1,
                Column: 2,
              };
              setTabValue(viewToTabIndex[advanced.layout.defaultView] || 0);
            }

            // Set up column format for drag and drop - create a unique ID for the column
            const columnId = uuidv4();
            setColumns({
              [columnId]: {
                title: "Booking questions",
                description:
                  "Customize the questions asked on the booking page",
                items: questionItems,
              },
            });

            // Set loading and document ready state after all data is properly set
            setLoading(false);
            // Use setTimeout to ensure state updates complete before setting documentReady
            setTimeout(() => {
              setDocumentReady(true);
            }, 100);
          } else {
            console.error("Event type data is missing in the response");
            setLoading(false);
            setError(
              "Failed to load event type data: Cannot find event type information in the response."
            );
          }
        } else {
          console.error("Invalid response format", response);
          setLoading(false);
          setError("Failed to load event type data: Invalid response format");
        }
      } catch (err) {
        console.error("Error fetching event type:", err);
        setError(
          `Failed to load event type data: ${err.message || "Unknown error"}`
        );
        setLoading(false);
      }
    };

    if (eventTypeId) {
      fetchEventType();
    }
  }, [eventTypeId]);

  // Function to handle adding a new question
  const handleAddQuestion = (newQuestion) => {
    // Extract the first column key (we only have one)
    const columnKey = Object.keys(columns)[0];

    if (!columnKey) return; // Safety check

    const updatedColumns = {
      ...columns,
      [columnKey]: {
        ...columns[columnKey],
        items: [...columns[columnKey].items, newQuestion],
      },
    };

    setColumns(updatedColumns);

    // Also update the advancedSettings state
    setAdvancedSettings({
      ...advancedSettings,
      bookingQuestions: [
        ...(advancedSettings.bookingQuestions || []),
        newQuestion,
      ],
    });
  };

  // Function to handle updating a question
  const handleUpdateQuestion = (updatedQuestion) => {
    // Extract the first column key (we only have one)
    const columnKey = Object.keys(columns)[0];

    if (!columnKey) return; // Safety check

    const updatedItems = columns[columnKey].items.map((item) =>
      item.id === updatedQuestion.id ? updatedQuestion : item
    );

    const updatedColumns = {
      ...columns,
      [columnKey]: {
        ...columns[columnKey],
        items: updatedItems,
      },
    };

    setColumns(updatedColumns);

    // Also update the advancedSettings state
    setAdvancedSettings({
      ...advancedSettings,
      bookingQuestions: updatedItems,
    });
  };

  // Handle drag and drop reordering
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Safety check
    if (!columns[source.droppableId]) return;

    if (source.droppableId !== destination.droppableId) {
      // This would be for moving between columns, but we only have one column
      return;
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });

      // Also update the advancedSettings state
      setAdvancedSettings({
        ...advancedSettings,
        bookingQuestions: copiedItems,
      });
    }
  };

  // Handle form field changes
  const handleAdvancedSettingChange = (section, field, value) => {
    if (section) {
      setAdvancedSettings({
        ...advancedSettings,
        [section]: {
          ...advancedSettings[section],
          [field]: value,
        },
      });
    } else {
      setAdvancedSettings({
        ...advancedSettings,
        [field]: value,
      });
    }
  };

  // Handle nested field changes
  const handleNestedSettingChange = (section, nestedSection, field, value) => {
    if (section && nestedSection) {
      // Handle deeply nested changes (like requiresConfirmation.noticeTime.value)
      setAdvancedSettings({
        ...advancedSettings,
        [section]: {
          ...advancedSettings[section],
          [nestedSection]: {
            ...advancedSettings[section]?.[nestedSection],
            [field]: value,
          },
        },
      });
    } else if (section) {
      // Handle changes to direct properties of a section (like requiresConfirmation.enabled)
      setAdvancedSettings({
        ...advancedSettings,
        [section]: {
          ...advancedSettings[section],
          [field]: value,
        },
      });
    }
  };

  // Handle tab change for layout selection
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    // Update the default view based on the selected tab
    const tabToView = {
      0: "Month",
      1: "Weekly",
      2: "Column",
    };

    handleNestedSettingChange(
      "layout",
      null,
      "defaultView",
      tabToView[newValue]
    );
  };

  // Save the settings
  const handleSave = async () => {
    try {
      setSaving(true);

      // Extract the first column key (we only have one)
      const columnKey = Object.keys(columns)[0];
      const bookingQuestions = columnKey ? columns[columnKey]?.items || [] : [];

      // Ensure all required nested structures exist
      const advanced = {
        ...advancedSettings,
        bookingQuestions,
        layout: advancedSettings.layout || {
          month: true,
          weekly: true,
          column: true,
          defaultView: "Month",
        },
        requiresConfirmation: advancedSettings.requiresConfirmation || {
          enabled: false,
          type: "Always",
          noticeTime: {
            value: 30,
            unit: "minutes",
          },
        },
        redirectOnBooking: advancedSettings.redirectOnBooking || {
          enabled: false,
          url: "",
        },
        enablePrivateUrl: advancedSettings.enablePrivateUrl || {
          enabled: false,
          url: "",
        },
        offerSeats: advancedSettings.offerSeats || {
          enabled: false,
          seats: 2,
          shareAttendeeInfo: false,
          showAvailableSeats: false,
        },
      };

      // Prepare the input for the mutation
      const input = {
        id: eventTypeId,
        advanced,
      };

      // Execute the mutation using axiosInstance
      const response = await axiosInstance.post("/graphql", {
        query: UPDATE_EVENT_TYPE_ADVANCED_MUTATION,
        variables: { input },
      });

      const { data, errors } = response.data;

      if (errors) {
        console.error("GraphQL errors:", errors);
        setAlert({
          open: true,
          message: "Failed to save advanced settings: " + errors[0].message,
          severity: "error",
        });
      } else if (data && data.updateEventTypeAdvanced) {
        setAlert({
          open: true,
          message: "Advanced settings saved successfully!",
          severity: "success",
        });
      }

      setSaving(false);
    } catch (err) {
      console.error("Error saving advanced settings:", err);
      setAlert({
        open: true,
        message: "Failed to save advanced settings. Please try again.",
        severity: "error",
      });
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={4}>
        <Typography>Loading advanced settings...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" p={4}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  // Check for empty columns - important for rendering drag and drop
  const hasColumns = Object.keys(columns).length > 0;

  return (
    <Grid justifyContent="space-between" container spacing={10}>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom display="inline">
          Advanced
        </Typography>
        <Typography>
          Configure what information should be captured during registration
        </Typography>

        {alert.open && (
          <Box
            mt={2}
            p={2}
            bgcolor={
              alert.severity === "success" ? "success.light" : "error.light"
            }
            borderRadius={1}
          >
            <Typography color="white">{alert.message}</Typography>
          </Box>
        )}
      </Grid>

      {/* Form fields section */}
      <Grid item md={12}>
        <Box style={{ marginBottom: "15px" }}>
          <Grid container spacing={6}>
            <Grid item md={12}>
              <TextField
                id="eventNameInCalendar"
                label="Event name in calendar"
                value={advancedSettings.eventNameInCalendar || ""}
                variant="outlined"
                fullWidth
                my={2}
                onChange={(e) =>
                  handleAdvancedSettingChange(
                    null,
                    "eventNameInCalendar",
                    e.target.value
                  )
                }
              />
            </Grid>
          </Grid>
        </Box>

        {/* Layout Options Section */}
        <Box>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={12} xl={12}>
              <Card
                mb={6}
                style={{
                  backgroundColor: "transparent",
                  padding: "0px !important",
                }}
              >
                <CardContent pb={0} style={{ paddingLeft: "0px" }}>
                  <Typography variant="h6" gutterBottom>
                    Layout
                  </Typography>
                  <Typography variant="body2" mb={4}>
                    You can select multiple and your bookers can switch views.
                  </Typography>
                  <Grid container>
                    <Grid item md={4}>
                      <img
                        src="/static/img/others/bookerlayout_month_view.svg"
                        alt="Month View"
                      />
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={advancedSettings.layout?.month || false}
                              onChange={(e) =>
                                handleNestedSettingChange(
                                  "layout",
                                  null,
                                  "month",
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="Month"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item md={4}>
                      <img
                        src="/static/img/others/bookerlayout_week_view.svg"
                        alt="Weekly View"
                      />
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={advancedSettings.layout?.weekly || false}
                              onChange={(e) =>
                                handleNestedSettingChange(
                                  "layout",
                                  null,
                                  "weekly",
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="Weekly"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item md={4}>
                      <img
                        src="/static/img/others/bookerlayout_column_view.svg"
                        alt="Column View"
                      />
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={advancedSettings.layout?.column || false}
                              onChange={(e) =>
                                handleNestedSettingChange(
                                  "layout",
                                  null,
                                  "column",
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="Column"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Box style={{ marginTop: "30px" }}>
                    <Typography variant="h6" gutterBottom>
                      Default View
                    </Typography>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                      <Tab
                        label="Month"
                        disabled={!advancedSettings.layout?.month}
                      />
                      <Tab
                        label="Weekly"
                        disabled={!advancedSettings.layout?.weekly}
                      />
                      <Tab
                        label="Column"
                        disabled={!advancedSettings.layout?.column}
                      />
                    </Tabs>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Booking Questions Section */}
        <Box style={{ marginBottom: "15px" }}>
          <Grid container spacing={6}>
            {documentReady && hasColumns && (
              <DragDropContext
                onDragEnd={onDragEnd}
                style={{ maxWidth: "100% !important" }}
              >
                {Object.entries(columns).map(([columnId, column]) => (
                  <Lane
                    key={columnId}
                    column={column}
                    onAddQuestion={handleAddQuestion}
                  >
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              minHeight: 50,
                            }}
                          >
                            {column.items &&
                              column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <Task
                                            item={item}
                                            onUpdateQuestion={
                                              handleUpdateQuestion
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </Lane>
                ))}
              </DragDropContext>
            )}
          </Grid>
        </Box>

        <Divider />

        {/* Requires confirmation section */}
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={10}>
                  <Typography variant="subtitle1">
                    Requires confirmation
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        The booking needs to be manually confirmed before it is
                        pushed to the integrations and a confirmation mail is
                        sent.
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={2}>
                  <Switch
                    checked={
                      advancedSettings.requiresConfirmation?.enabled || false
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                    onChange={(e) =>
                      handleNestedSettingChange(
                        "requiresConfirmation",
                        null,
                        "enabled",
                        e.target.checked
                      )
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Grid>

          {/* Conditional form fields for requires confirmation */}
          {advancedSettings.requiresConfirmation?.enabled && (
            <Grid container spacing={6}>
              <Grid item md={6} style={{ marginTop: "15px" }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={
                      advancedSettings.requiresConfirmation?.type || "Always"
                    }
                    onChange={(e) =>
                      handleNestedSettingChange(
                        "requiresConfirmation",
                        null,
                        "type",
                        e.target.value
                      )
                    }
                  >
                    <FormControlLabel
                      value="Always"
                      control={<Radio />}
                      label="Always"
                    />
                    <FormControlLabel
                      value="LessThanNotice"
                      control={<Radio />}
                      label="When booked with less than"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* Additional fields for LessThanNotice option */}
              <Grid item md={12}>
                {advancedSettings.requiresConfirmation?.type ===
                  "LessThanNotice" && (
                  <Grid container spacing={6}>
                    <Grid item md={3}>
                      <TextField
                        value={
                          advancedSettings.requiresConfirmation?.noticeTime
                            ?.value || 30
                        }
                        variant="outlined"
                        fullWidth
                        type="number"
                        onChange={(e) =>
                          handleNestedSettingChange(
                            "requiresConfirmation",
                            "noticeTime",
                            "value",
                            parseInt(e.target.value)
                          )
                        }
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          value={
                            advancedSettings.requiresConfirmation?.noticeTime
                              ?.unit || "minutes"
                          }
                          onChange={(e) =>
                            handleNestedSettingChange(
                              "requiresConfirmation",
                              "noticeTime",
                              "unit",
                              e.target.value
                            )
                          }
                          inputProps={{
                            name: "notice-unit",
                            id: "notice-unit",
                          }}
                        >
                          <MenuItem value="minutes">minutes</MenuItem>
                          <MenuItem value="hours">hours</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <Typography style={{ position: "relative", top: "15px" }}>
                        notice
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Box>

        <Divider />

        {/* Email verification section */}
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={10}>
                  <Typography variant="subtitle1">
                    Requires booker email verification
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        To ensure booker's email verification before scheduling
                        events
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={2}>
                  <Switch
                    checked={
                      advancedSettings.requiresEmailVerification || false
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                    onChange={(e) =>
                      handleAdvancedSettingChange(
                        null,
                        "requiresEmailVerification",
                        e.target.checked
                      )
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Grid>
        </Box>

        <Divider />

        {/* Hide notes in calendar section */}
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={10}>
                  <Typography variant="subtitle1">
                    Hide notes in calendar
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        Don't expose your notes to the people you've booked
                        events with
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={2}>
                  <Switch
                    checked={advancedSettings.hideNotesInCalendar || false}
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                    onChange={(e) =>
                      handleAdvancedSettingChange(
                        null,
                        "hideNotesInCalendar",
                        e.target.checked
                      )
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Grid>
        </Box>

        <Divider />

        {/* Redirect on booking section */}
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={10}>
                  <Typography variant="subtitle1">
                    Redirect after booking
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        Redirect to a custom URL after the booking has been
                        confirmed
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={2}>
                  <Switch
                    checked={
                      advancedSettings.redirectOnBooking?.enabled || false
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                    onChange={(e) =>
                      handleNestedSettingChange(
                        "redirectOnBooking",
                        null,
                        "enabled",
                        e.target.checked
                      )
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Grid>

          {/* Conditional URL field */}
          {advancedSettings.redirectOnBooking?.enabled && (
            <Grid container spacing={6}>
              <Grid item md={12} style={{ marginTop: "15px" }}>
                <TextField
                  label="Redirect URL"
                  value={advancedSettings.redirectOnBooking?.url || ""}
                  variant="outlined"
                  fullWidth
                  placeholder="https://example.com/thank-you"
                  onChange={(e) =>
                    handleNestedSettingChange(
                      "redirectOnBooking",
                      null,
                      "url",
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          )}
        </Box>

        <Divider />

        {/* Private URL section */}
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={10}>
                  <Typography variant="subtitle1">
                    Enable private link
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        Create a link that's not listed on your main dashboard
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={2}>
                  <Switch
                    checked={
                      advancedSettings.enablePrivateUrl?.enabled || false
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                    onChange={(e) =>
                      handleNestedSettingChange(
                        "enablePrivateUrl",
                        null,
                        "enabled",
                        e.target.checked
                      )
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Grid>

          {/* Conditional URL field */}
          {advancedSettings.enablePrivateUrl?.enabled && (
            <Grid container spacing={6}>
              <Grid item md={12} style={{ marginTop: "15px" }}>
                <TextField
                  label="URL Path"
                  value={advancedSettings.enablePrivateUrl?.url || ""}
                  variant="outlined"
                  fullWidth
                  placeholder="your-private-url"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">/</InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    handleNestedSettingChange(
                      "enablePrivateUrl",
                      null,
                      "url",
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          )}
        </Box>

        <Divider />

        {/* Offer seats section */}
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={10}>
                  <Typography variant="subtitle1">
                    Offer multiple seats
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        Allow multiple people to attend the same booking slot
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={2}>
                  <Switch
                    checked={advancedSettings.offerSeats?.enabled || false}
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                    onChange={(e) =>
                      handleNestedSettingChange(
                        "offerSeats",
                        null,
                        "enabled",
                        e.target.checked
                      )
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Grid>

          {/* Conditional seats options */}
          {advancedSettings.offerSeats?.enabled && (
            <Grid container spacing={6}>
              <Grid item md={6} style={{ marginTop: "15px" }}>
                <TextField
                  label="Number of seats"
                  value={advancedSettings.offerSeats?.seats || 2}
                  variant="outlined"
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 2 } }}
                  onChange={(e) =>
                    handleNestedSettingChange(
                      "offerSeats",
                      null,
                      "seats",
                      parseInt(e.target.value)
                    )
                  }
                />
              </Grid>
              <Grid item md={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          advancedSettings.offerSeats?.showAvailableSeats ||
                          false
                        }
                        onChange={(e) =>
                          handleNestedSettingChange(
                            "offerSeats",
                            null,
                            "showAvailableSeats",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="Show how many seats are available"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          advancedSettings.offerSeats?.shareAttendeeInfo ||
                          false
                        }
                        onChange={(e) =>
                          handleNestedSettingChange(
                            "offerSeats",
                            null,
                            "shareAttendeeInfo",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="Share attendee information with all participants"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          )}
        </Box>

        <Divider />

        {/* Lock timezone section */}
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={10}>
                  <Typography variant="subtitle1">
                    Lock timezone
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        Lock times to your timezone, no matter where the booker
                        is located
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={2}>
                  <Switch
                    checked={advancedSettings.lockTimezone || false}
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                    onChange={(e) =>
                      handleAdvancedSettingChange(
                        null,
                        "lockTimezone",
                        e.target.checked
                      )
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Grid>
        </Box>

        {/* Save button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={saving}
        >
          {saving ? "Saving..." : "Save"}
        </Button>
      </Grid>
    </Grid>
  );
}
// Main export
function Main() {
  // Get the eventTypeId from URL params or props
  const [eventTypeId, setEventTypeId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Extract the ID from the URL path (assuming the pattern is /calendar/edit-event-type/:id/advanced)
    const pathParts = location.pathname.split("/");
    const id = pathParts[pathParts.indexOf("edit-event-type") + 1];

    if (id) {
      setEventTypeId(id);
    } else {
      console.error("Event type ID not found in URL");
    }
  }, [location]);

  if (!eventTypeId) {
    return (
      <Box display="flex" justifyContent="center" p={4}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return <Advanced eventTypeId={eventTypeId} />;
}

export default Main;
